<template>
  <b-row>
    <b-col cols="12" md="12" lg="12">
      <b-row class="clearfix" v-if="!data.refund_status">
        <b-col cols="6" class="less">
          <button @click="refundAction" class="btn y-btn-outline-dark text-danger font-weight-bold"> Issue Refund</button>
        </b-col>
        <b-col cols="6" class="less">
          <a @click="print" class="btn y-btn-outline-dark text-danger font-weight-bold" style="float: right;"> Print Receipt</a>
        </b-col>
      </b-row>

      <b-row class="clearfix details-body">
        <b-col cols="12" class="details-card">
          <b-row>
            <b-col>
                <span class="font-weight-bold h3" v-if="data.payment_type == 'redeem'">
                  {{Number(data.total_cash).toLocaleString()}} Points Payment
                </span>
              <span class="font-weight-bold h3 redeem-text" v-else-if="data.refund_status">
                  UGX {{Number(data.total_cash).toLocaleString()}} Refund
                </span>
              <span class="font-weight-bold h3" v-else>
                  UGX {{Number(data.total_cash).toLocaleString()}} Payment
                </span>
            </b-col>
          </b-row>

          <b-row>
            <b-col v-if="data!=null">
              <small style="color: #959595;">{{getDateString(data.created_at)}}</small>
            </b-col>
          </b-row>

          <b-row style="margin-top: 10px">
            <b-col v-if="data!=null">
              <small style="color: #959595;"><b>Collected at:</b> {{getDateString(data.created_at)}}</small>
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="data!=null">
              <small style="color: #959595;"><b>Paid by:</b> {{data.customer.name}}</small>
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="data!=null">
              <small style="color: #959595;"><b>Number:</b> {{data.customer.phone_number}}</small>
            </b-col>
          </b-row>

          <div class="bar"></div>

          <b-row class="y-list-item less" v-for="(product, idx) in data.details" :key="idx">
            <b-col cols="12">
              <b-row class="align-items-center item-line">
                <b-col cols="12">
                  <b-row>
                    <b-col cols="7">
                      <b-row>
                        <span class="text-details font-weight-bold">{{product.item_name}}</span>
                        <small class="qty">x{{product.item_quantity}}</small>
                      </b-row>
                    </b-col>
                    <b-col cols="5" class="less">
                      <p class="text-details font-weight-bold" style="float: right;">
                        UGX {{Number(product.item_subtotal).toLocaleString()}}
                      </p>
                    </b-col>
                  </b-row>
                  <b-row  v-if="product.discount != null">
                    <b-col cols="8">
                      <b-row>
                        <small class="mb-0" >{{product.discount.discount_name}}</small>
                      </b-row>
                    </b-col>
                    <b-col cols="4" class="less">
                      <small v-if="product.discount" style="float: right;">
                        - UGX. {{calculateDiscount(product)}}
                      </small>
                    </b-col>
                  </b-row>
                  <b-row v-for="(member,idx) in product.team" :key="idx">
                    <small class="mb-0" >{{member.name}}</small>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <div class="bar2"></div>
          <b-row>
            <b-col cols="6">
              <p class="font-weight-bold redeem-text" v-if="data.refund_status">Total</p>
              <p class="font-weight-bold" v-else>Total</p>
            </b-col>
            <b-col cols="6">
              <p class="font-weight-bold redeem-text" v-if="data.refund_status" style="float:right;">
                UGX {{Number(data.total_cash).toLocaleString()}}
              </p>
              <p class="font-weight-bold" v-else style="float:right;">
                UGX {{Number(data.total_cash).toLocaleString()}}
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6" v-if="data!=null">
              <small style="color: #959595;">{{cleanText(data.payment_type)}}</small>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" v-if="data!=null">
              <small class="font-weight-bold" style="color: #959595;">Receipt #{{data.receipt_no}}</small>
            </b-col>
          </b-row>
          <div v-if="data.refund_status" class="bar"></div>
          <b-row v-if="data.refund_status">
            <b-col cols="12">
              <b-row style="margin-top: 10px">
                <b-col v-if="data!=null">
                  <small style="color: #959595;"><b>Refund by: </b>Team Member's Name</small>
                </b-col>
              </b-row>
              <b-row style="margin-top: 10px">
                <b-col v-if="data!=null">
                  <small style="color: #959595;"><b>Date & Time: </b> {{getDateString(data.updated_at)}}</small>
                </b-col>
              </b-row>
              <b-row style="margin-top: 10px">
                <b-col v-if="data!=null">
                  <small style="color: #959595;"><b>Reason</b></small><br/>
                  <small style="color: #959595;">{{data.refund_reason}}</small>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="clearfix details-footer">
        <b-col cols="12" class="less">
          <button @click="closeSideMenu" class="btn y-btn-footer" style="float: right;">Done</button>
        </b-col>
      </b-row>

    </b-col>
  </b-row>
</template>

<script>
import router from "@/app/router/router";

export default {
  name: "TransactionDetails",
  props:{data:Object},
  data(){
    return{
      months:["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    }
  },
  methods:{
    closeSideMenu(){
      this.$root.$emit('close-details')
    },
    cleanText(text){
      if(text.includes("_")){
        return this.capitalizeFirstLetter(text.replaceAll("_", " "))
      }
      return this.capitalizeFirstLetter(text);
    },
    refundAction(){
      let data = {
        id:this.data.id
      }
      localStorage.setItem("refund", JSON.stringify(data))
      router.push("/refund-reason")
    },
    getDateString(date){
      var sections = date.split("-")
      var d = sections[2].split("T")
      var month_idx = parseInt(sections[1]) - 1
      return d[0]+" "+this.months[month_idx]+", "+sections[0];
    },
    getTime(date){
      var sections = date.split("T")
      var sections2 = sections[1].split(".")
      var sections3 = sections[1].split(":")
      var total = parseInt(sections2[0].replaceAll(":", ""))
      if(total > 120000){
        return sections3[0]+":"+sections3[1] + " pm"
      }else{
        return sections3[0]+":"+sections3[1] + " am"
      }
    },
    getShortDateString(date){
      var sections = date.split("-")
      var month_idx = parseInt(sections[1]) - 1

      return sections[2]+" "+this.months[month_idx]+", "+sections[0];
    },
    print(){
      localStorage.setItem("transaction", JSON.stringify(this.transaction))
      router.push("/print-receipt")
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    calculateDiscount(product){
      var amount = product.discount.amount
      var type = product.discount.discount_type
      var price = parseInt(product.item_subtotal)/parseInt(product.item_quantity)

      if(type == "Amount"){
        return amount;
      }

      if(type == "Percentage"){
        return  parseInt(price) * amount/100
      }
    },
  },
  computed:{
    getData(){
      return this.data
    }
  },
  watch:{
    getData(data){
      console.log(JSON.stringify(data))
    }
  }
}
</script>

<style scoped>

@import "../../../../commons/css/main.css";

.text-details{
  margin-top: 5px;
  font-size: 14px;
}

.qty{
  margin-top: 5px;
  margin-left: 5px;
  font-size: 14px;
}

.details-body{
  margin-top: 20px;
}

.details-footer{
  margin-top: 10px;
}

.redeem-text{
  color: #f37e31;
}

.y-btn-outline-dark {
  width: 95%;
  color: #343a40;
  font-size: 12px;
  padding: 10px 25px;
  border-radius: 6px;
  background-color: transparent;
  background-image: none;
  border: solid 2px #d0d0d0;
}

.y-btn-footer {
  color: #343a40;
  font-size: 12px;
  padding: 10px 25px;
  border-radius: 6px;
  background-color: transparent;
  background-image: none;
  border: solid 2px #d0d0d0;
}

.y-btn-outline-dark:hover {
  color: #fff;
  background-color: #c6c6c6;
  border-color: #c6c6c6;
}

.details-card{
  border: solid 2px #d0d0d0;
  border-radius: 6px;
  padding: 30px;
  width: 100%;
}

.bar{
  width: 100%;
  background: #a0a0a0;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.bar2{
  width: 100%;
  background: #a0a0a0;
  height: 1px;
  margin-top: 60px;
  margin-bottom: 10px;
}

.less{
  margin:0;
  padding: 0;
}

.text-danger{
  color: #bd2130;
}

</style>