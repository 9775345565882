<template>
  <div class="expense">

    <b-row class="header">
      <a class="navbar-brand ml-3">
        <img @click="back()" src="../../../../assets/ic_cancel.png" class="y-icon-header">
      </a>
      <div class="flex-grow-1 d-md-flex align-items-center justify-content-center d-none">

      </div>
    </b-row>

    <b-row class="content">
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <b-row>
          <b-col cols="10">
            <span class="font-weight-bold h4 my-3">Items Per Category</span>
          </b-col>
          <b-col cols="2">
            <button class="button" @click="exportData">Export all</button>
          </b-col>
        </b-row>

        <b-row class="separator-header summary-header" style="font-weight: bold;">
          <b-col cols="3">
              <span class="list-header-label">
                Item Name
              </span>
          </b-col>
          <b-col cols="3" class="text-center">
              <span class="list-header-label">
                Quantity
              </span>
          </b-col>
          <b-col cols="3">
              <span class="list-header-label">
                Location
              </span>
          </b-col>
          <b-col cols="3">
              <span class="list-header-label">
                 Amount
              </span>
          </b-col>
        </b-row>
        <b-overlay rounded="sm" :show="loading">
          <b-row>
            <b-col cols="12">
              <div class="flex-grow-1">
                <div class="list-group" v-for="(item, idx) in getSalesReportDetails.data" :key="idx">
                  <b-row class="separator">
                    <b-col cols="3">
                      <span class="list-header-label ml-2">
                         {{ item.item_name }}
                      </span>
                    </b-col>
                    <b-col cols="3" class="text-center">
                      <span class="list-header-label">
                         {{ item.item_quantity }}
                      </span>
                    </b-col>
                    <b-col cols="3">
                       <span class="list-header-label">
                         All Locations
                      </span>
                    </b-col>
                    <b-col cols="3">
                       <span class="list-header-label">
                            UGX. {{ Number(item.item_subtotal).toLocaleString() }}
                       </span>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-overlay>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from "moment";

export default {
  name: "SalesReportDetails",
  data(){
    return{
      loading:true
    }
  },
  mounted() {
    let range = JSON.parse(localStorage.getItem("date-range"))
    let data ={
      id:this.$route.query.id,
      to: this.dateFormat(range.endDate),
      from: this.dateFormat(range.startDate)
    }
    this.fetchSalesReportDetails(data)
  },
  methods: {
    ...mapActions(["fetchSalesReportDetails"]),
    exportData(){
      window.open(this.getSalesReportDetails.download)
    },
    back() {
      window.history.back();
    },
    dateFormat(date){
      return new moment(date).format("YYYY-MM-DD")
    }
  },
  computed: {
    ...mapGetters(["getSalesReportDetails"])
  },
  watch:{
    getSalesReportDetails(){
      this.loading = false
    }
  }
}
</script>

<style scoped>
@import "../../../../commons/css/main.css";

.expense {

}

.header {
  /*margin: 0;*/
  /*width: 100%;*/
  /*box-shadow: 0px 5px 3px #dedede;*/
  padding: 0;
  margin: 0;
  width: 100%;
  height: 85px;
  box-shadow: 0px 5px 3px #dedede;
}

.content {
  margin-top: 80px;
}

.y-icon-header {
  padding: 5px;
  width: 38px;
  height: 38px;
  margin: 16px 10px 10px 10px
}

.y-icon-header:hover {
  padding: 6px;
  background: #f3f3f3;
  border-radius: 6px;
}

.title {
  font-size: 22px;
  margin-top: 15px;
  font-weight: bold;
  margin-left: 10px;
}

.summary-header {
  width: 100%;
  background: #eaeaea;
  padding-bottom: 15px;
  padding-top: 15px;
  margin: 0;
}

.summary-item-border {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: solid 1px #1b1e21;
}

.summary-item-header {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: solid 1px #1d2124;
}

.summary-amount {
  float: right;
}

.item-label {
  color: #959595;
}

.collection-indicator {
  width: 14px;
  height: 14px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 5px;
  content: url("../../../../assets/side.svg");
}

.collection-indicator-down {
  width: 14px;
  height: 14px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 5px;
  content: url("../../../../assets/down.svg");
}

.separator-header{
  background: #ededed;
  padding: 15px 10px;
  border-bottom: solid #dedede;
  margin: 0;
  width: 100%;
}

.separator{
  background: white;
  padding: 15px 10px;
  border-bottom: solid #dedede;
  margin: 0;
  width: 100%;
}

.button{
  width: 100%;
  background: #1d2124;
  font-size: 12px;
  padding: 8px;
  color: #ffffff;
  font-weight: bold;
  border: 0;
  border-radius: 6px;
  margin: 10px 0px;
  border: solid 2px #1d2124;
}

.button:hover{
  background: white;
  color: #1b1e21;
}

</style>