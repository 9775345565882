 <template>
    <div class="reports">

      <b-row>
        <b-col cols="5" >
          <b-row><span class="font-weight-bold h3">Subscription Report</span><br/></b-row>
          <b-row><span class="desc">Here is a quick report view of how your subscription program performed</span></b-row>
        </b-col>
        <b-col cols="7">
          <b-row class="less">
            <b-col cols="5">
              <b-row style="width: 100%;">
                <date-range-picker
                    class="select"
                    ref="picker"
                    :locale-data="{format: 'dd-mm-yyyy' }"
                    :minDate="null" :maxDate="null"
                    :singleDatePicker="false"
                    :timePicker="false"
                    :timePicker24Hour="false"
                    :showWeekNumbers="true"
                    :showDropdowns="true"
                    :autoApply="true"
                    v-model="date_range"
                    :linkedCalendars="true">
                  <template style="width: 100%;">
                     <span style="font-size: 11px;">
                        {{ date_range.startDate }} - {{ date_range.endDate}}
                     </span>
                  </template>
                </date-range-picker>
              </b-row>
            </b-col>
            <b-col cols="4">
              <b-form>
                <b-form-select v-model="filter_location" class="select">
                  <b-select-option value="null">All Locations</b-select-option>
                  <b-select-option v-for="(location, idx) in getLocations" :key="idx" :value="location.id">
                    {{location.location_name}} ({{location.address}})
                  </b-select-option>
                </b-form-select>
              </b-form>
            </b-col>
            <b-col cols="3">
              <button @click="exportData" class="button">Export</button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-overlay :show="loading">
        <b-row style="margin-bottom: 20px;" v-if="getSubscriptionReports != null">
        <b-col cols="12">

          <b-row class="line"></b-row>
          <b-row>
            <span class="font-weight-bold h5">Customers</span>
          </b-row>
          <b-row>
            <b-col cols="8">
              <b-row class="details-card">
                <b-col cols="6">
                  <b-row>
                    <span class="font-weight-bold detail-label">WALK-INS</span>
                  </b-row>
                  <b-row>
                    <p class="font-weight-bold detail-currency mtop-10">UGX</p>
                    <p class="font-weight-bold detail-amount mtop-10" v-if="getSubscriptionReports != null">{{Number(getSubscriptionReports.walk_ins.total_amount).toLocaleString()}}</p>
                    <p class="font-weight-bold detail-amount mtop-10" v-else>0</p>
                  </b-row>

                  <b-row class="bar"></b-row>

                  <b-row>
                    <span class="detail-label">NO. OF CUSTOMER WALK-INS</span>
                  </b-row>
                  <b-row>
                    <p class="font-weight-bold detail-amount mtop-10" v-if="getSubscriptionReports != null">{{Number(getSubscriptionReports.walk_ins.walk_in_count).toLocaleString()}}</p>
                  </b-row>
                </b-col>

                <b-col cols="6">
                  <b-row>
                    <span class="font-weight-bold detail-label">SUBSCRIPTIONS</span>
                  </b-row>
                  <b-row>
                    <p class="font-weight-bold detail-currency mtop-10">UGX</p>
                    <p class="font-weight-bold detail-amount mtop-10" v-if="getSubscriptionReports != null">{{Number(getSubscriptionReports.subscriptions.total_amount).toLocaleString()}}</p>
                  </b-row>

                  <b-row class="bar"></b-row>

                  <b-row>
                    <span class="detail-label">NO. OF SUBSCRIPTIONS</span>
                  </b-row>
                  <b-row>
                    <p class="font-weight-bold detail-amount mtop-10" v-if="getSubscriptionReports != null">{{Number(getSubscriptionReports.subscriptions.subscription_count).toLocaleString()}}</p>
                  </b-row>
                </b-col>

              </b-row>
            </b-col>
            <b-col cols="4">
              <b-row class="details-card">
                <b-col>
                  <b-row>
                    <span class="font-weight-bold detail-label">NO. OF MEMBER CHECKINS</span>
                  </b-row>
                  <b-row>
                    <p class="font-weight-bold detail-amount mtop-10" v-if="getSubscriptionReports != null">{{Number(getSubscriptionReports.check_ins.check_in_count).toLocaleString()}}</p>
                  </b-row>

                  <b-row class="bar"></b-row>

                  <b-row>
                    <span class="font-weight-bold detail-label">NO. OF FREE PASS CHECKINS</span>
                  </b-row>
                  <b-row>
                    <p class="font-weight-bold detail-amount mtop-10">N/A</p>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row class="line"></b-row>
          <b-row>
            <b-col cols="12">
              <b-row class="details-card">
                <b-col cols="6" style="margin-top: auto; margin-bottom: auto;">
                  <b-row>
                    <span class="font-weight-bold">TOTAL CASH</span>
                  </b-row>
                  <b-row>
                    <span class="desc-2">(WALK-INS + SUBSCRIPTIONS)</span>
                  </b-row>
                </b-col>
                <b-col cols="6">
                  <b-row>
                    <span class="font-weight-bold">UGX</span>
                    <span class="font-weight-bold detail-amount-large" v-if="getSubscriptionReports != null">{{Number( getSubscriptionReports.walk_ins.total_amount + getSubscriptionReports.subscriptions.total_amount ).toLocaleString()}}</span>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row class="line"></b-row>
          <b-row>
            <span class="font-weight-bold h5">Gender</span>
          </b-row>
          <b-row>
            <p class="desc">This will help you view how many customers do visit your business.</p>
          </b-row>
          <b-row>
            <b-col cols="3">
              <b-row class="details-card">
                <b-col>
                  <b-row>
                    <span class="font-weight-bold detail-label">FEMALE</span>
                  </b-row>
                  <b-row>
                    <p class="font-weight-bold detail-amount" v-if="getSubscriptionReports != null">{{Number(getSubscriptionReports.gender.female).toLocaleString()}}</p>
                  </b-row>
                  <b-row>
                    <img src="../../../../assets/ic_female.svg" class="glyph">
                    <img src="../../../../assets/ic_female.svg" class="glyph">
                    <img src="../../../../assets/ic_female.svg" class="glyph">
                    <img src="../../../../assets/ic_female.svg" class="glyph">
                    <img src="../../../../assets/ic_female.svg" class="glyph">
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="3">
              <b-row class="details-card">
                <b-col>
                  <b-row>
                    <span class="font-weight-bold detail-label">MALE</span>
                  </b-row>
                  <b-row>
                    <p class="font-weight-bold detail-amount" v-if="getSubscriptionReports != null">{{Number(getSubscriptionReports.gender.male).toLocaleString()}}</p>
                  </b-row>
                  <b-row>
                    <img src="../../../../assets/ic_male.svg" class="glyph">
                    <img src="../../../../assets/ic_male.svg" class="glyph">
                    <img src="../../../../assets/ic_male.svg" class="glyph">
                    <img src="../../../../assets/ic_male.svg" class="glyph">
                    <img src="../../../../assets/ic_male.svg" class="glyph">
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="6"></b-col>
          </b-row>
        </b-col>
      </b-row>
      </b-overlay>

    </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import {mapActions, mapGetters} from "vuex";
import moment from "moment";
import router from "@/app/router/router";

export default {
  name: "SubscriptionReport",
  components:{DateRangePicker},
  data(){
    return{
      loading: false,
      filter_location:null,
      date_range:{
        startDate:null,
        endDate:null
      },
    }
  },
  mounted() {
    this.fetchLocations()
    let data = {
      location: null,
      date: {
         from:moment().format('YYYY-MM-DD'),
         to:moment().format('YYYY-MM-DD')
      }
    }
    this.loading = true
    this.fetchSubscriptionReports(data)
  },
  methods:{
    ...mapActions(["fetchLocations", "fetchSubscriptionReports"]),
    formatDate(date){
      return moment(date).format("YYYY-MM-DD")
    },
    detailedReport(){
      router.push("/detailed-report")
    },
    exportData(){

    },
    filter(){

      let data = {
        date:{
          from:this.formatDate(this.date_range.startDate),
          to:this.formatDate(this.date_range.endDate)
        },
        location:this.filter_location
      }

      if( this.filter_location != null && this.date_range.startDate != null){
        this.loading = true
        this.fetchSubscriptionReports(data)
      }

    },
  },
  computed:{
    ...mapGetters([
        "getLocations", "getSubscriptionReports"
    ]),
    getDateRange(){
      return this.date_range
    },
    getFilterLocation(){
      return this.filter_location
    },
  },
  watch:{
    getSubscriptionReports(){
      this.loading = false
    },
    getDateRange(){
      this.filter()
    },
    getFilterLocation(){
      this.filter()
    },
  }
}
</script>

<style scoped>
@import "../../../../commons/css/main.css";

.reports{
  z-index: -5;
}

.select{
  width: 100%;
}

.desc{
  font-size: 12px;
}

.desc-2{
  font-size: 12px;
}

.button{
  width: 100%;
  background: #fff;
  font-size: 12px;
  padding: 8px;
  color: #1d2124;
  font-weight: bold;
  border: solid 2px #1d2124;
  border-radius: 6px;
}

.less{
  margin:0;
  padding: 0;
}

.details-card{
  border: solid 2px #d0d0d0;
  border-radius: 6px;
  padding: 20px;
  width: 100%;
}

.line{
  margin-top: 20px;
  margin-bottom: 20px;
}

.detail-label{
  font-size: 12px;
}

.detail-amount{
  font-size: 24px;
}

.detail-currency{
  font-size: 12px;
}

.mtop-10{
  margin-top: 10px;
}

.detail-amount-large{
  font-size: 52px;
}

.glyph{
  padding: 3px;
}

.bar{
  width: 100%;
  background: #a0a0a0;
  height: 2px;
  margin-bottom: 10px;
}

</style>