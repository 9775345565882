import axios from "axios";
import configs from "../../../../../app/configurations/configs.json";


const IP = configs.SERVER_IP;

const state = {
  response:{},
  error:{},
};

const getters = {
    getSettingResponse: (state) => state.response,
    getSettingErrorResponse: (state) => state.error,
};

const actions = {

  async updateSettings({ commit}, data) {
      const params = new URLSearchParams();
      params.append('settings', data.settings);
    await axios
       .put(IP + "/business/"+localStorage.getItem("business_id"), params)
       .then((response) => {
           commit("setMember", response.data);
       })
       .catch((error) => {
           commit("setErrorResponse", error.response);
       });
  },
    async enablePermissions({ commit}, data) {
        await axios
            .post(IP + "/teams", data)
            .then((response) => {
                commit("setResponse", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },
  async updatePassword({ commit}, data) {
    await axios
      .put(IP + "/teams/"+data.id, data)
      .then((response) => {
          commit("setMember", response.data);
      })
      .catch((error) => {
          commit("setErrorResponse", error.response);
       });
  },
  async updateLogo({ commit}, data) {
      await axios
          .post(IP + "/teams", data)
          .then((response) => {
              commit("setMember", response.data);
          })
          .catch((error) => {
              commit("setErrorResponse", error.response);
          });
 },
 async updatePasscode({ commit}, data) {
     await axios
         .put(IP + "/teams/"+data.id, data)
         .then((response) => {
             commit("setMember", response.data);
          })
          .catch((error) => {
             commit("setErrorResponse", error.response);
          });
    },

};

const mutations = {
  setTeams: (state, response) => {
    state.teams = response.data;
  },
  setMember: (state, response) => {
    state.response = response.data;
  },
  setResponse: (state, response) => {
    state.response = response.data;
  },
  setErrorResponse: (state, response) => {
    state.error = response.data;
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
