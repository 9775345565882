<template>
    <div class="discounts">
      <b-row>
        <b-col cols="2" sm="4" md="4" lg="6" xl="6" class="less">
          <span class="font-weight-bold h3">Disabled Discounts</span>
        </b-col>
        <b-col cols="10" sm="8" md="8" lg="6" xl="6"></b-col>
      </b-row>
      <b-row class="line">
        <b-col cols="7" class="mx-auto">
          <span class="title">Categories help you arrange and organize your items, report on item sales and route items to specific printers</span>
        </b-col>
        <b-col cols="5"></b-col>
      </b-row>
    </div>
</template>

<script>
export default {
  name: "DisabledDiscounts",
  methods:{
    create(){

    }
  }
}
</script>

<style scoped>
@import "../../../../commons/css/main.css";

.discounts{
  margin-top: 10px;
}

.button{
  width: 100%;
  background: #c82333;
  font-size: 12px;
  padding: 8px;
  color: white;
  border: solid 1px #c82333;
  border-radius: 3px;
}

.line{
  margin-top: 20px;
  margin-bottom: 20px;
}

.title{
  font-size: 14px;
}

</style>