<template>
    <div class="expense">

      <b-row class="header">
        <b-col cols="1">
          <a class="navbar-brand ml-3">
            <img @click="back()" src="../../../../../assets/ic_cancel.png" class="y-icon-header">
          </a>
        </b-col>

        <b-col cols="9" style="margin: auto;">
          <div class="flex-grow-1 d-md-flex align-items-center justify-content-center d-none">
            <h1 class="h5 text-center title">Create Discount</h1>
          </div>
        </b-col>

        <b-col cols="2" style="margin: auto;">
          <button @click="save()" class="button">Save</button>
        </b-col>
      </b-row>
        <section class="content h-100 w-100 d-flex justify-content-center align-items-center flex-column mb-5">
            <b-col cols="7" class="mx-auto">
                <div class="mt-5  d-none d-md-block">
                    <b-overlay :show="loading" rounded="sm">
                      <span class="font-weight-bold h5">Details</span>
                      <table class="table w-100 font-weight-bold table-bordered">
                            <tbody>
                            <tr>
                                <th class="bg-light">Discount name</th>
                                <td class="font-weight-normal">
                                    <b-form-input
                                            class="input"
                                            v-model="form.discount_name"
                                            type="text"
                                            placeholder="Name"/>
                                </td>
                            </tr>
                            <tr>
                              <th class="bg-light">Amount</th>
                              <td class="font-weight-normal" style="border: 0">
                                <table style="border: 0; width: 100%; margin: 0; padding: 0;">
                                  <tbody>
                                    <tr>
                                      <td style="border: 0; margin: 0; padding: 0;">
                                        <b-form-input
                                            class="input"
                                            v-model="raw_amount"
                                            type="text"
                                            placeholder="0"/>
                                      </td>
                                      <td style="border: 0; margin: 0; padding: 0;">
                                        <div class="toggle-btn text-center">
                                          <span :class="(form.discount_type == 'Percentage') ? 'toggle-piece-active' : 'toggle-piece'" @click="percentageType">%</span>
                                          <span :class="(form.discount_type == 'Amount') ? 'toggle-piece-active' : 'toggle-piece'" @click="amountType">UGX</span>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                                <th class="bg-light">Locations</th>
                                <td class="font-weight-normal">
                                    <b-form-input
                                            class="input"
                                            disabled
                                            type="text"
                                            :value="getLocations.length+' Locations'"/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </b-overlay>
                </div>
            </b-col>
        </section>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    export default {
        name: "NewDiscount",
        data(){
            return{
                months_short:["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                form:{
                  discount_name:null,
                  amount:null,
                  discount_type: "Amount",
                  business_id: localStorage.getItem("business_id")
                },
                loading:false,
                raw_amount:null
            }
        },
        mounted() {
          this.fetchLocations()
        },
        methods:{
          ...mapActions(["addDiscount", "fetchLocations"]),
          onSearch(){

          },
          back(){
             window.history.back();
          },
          save(){
            this.loading = true
            this.addDiscount(this.form)

            for(let i=0; i<this.getLocations.length; i++){
              let data = {
                discount_name:this.form.discount_name,
                amount:this.form.amount,
                discount_type: this.form.discount_type,
                location_id: this.getLocations[i].id,
                business_id: localStorage.getItem("business_id")
              }
              this.addDiscount(data)
            }
          },
          percentageType(){
            this.form.discount_type = "Percentage"
          },
          amountType(){
            this.form.discount_type = "Amount"
          },
        },
        computed:{
            ...mapGetters(["getActionResponse", "getLocations"]),
          getRawAmount(){
            return this.raw_amount;
          }
        },
        watch:{
          getActionResponse(){
            window.history.back()
          },
          getRawAmount(amt){
            this.form.amount = amt.replaceAll(",", "")
            if(this.form.discount_type == "Amount"){
              var format = amt.replaceAll(",", "")
              if(isNaN(format)){
                alert("Enter Numbers only")
                this.raw_amount = null
                this.form.amount = null
              }else{
                this.raw_amount = Number(format).toLocaleString()
              }
            }
          },
        }
    }
</script>

<style scoped>
    @import "../../../../../commons/css/main.css";

    .expense{

    }

    .header{
        /*margin: 0;*/
        /*width: 100%;*/
        /*box-shadow: 0px 5px 3px #dedede;*/
        padding: 0;
        margin: 0;
        width: 100%;
        height: 85px;
        box-shadow: 0px 5px 3px #dedede;
    }

    .content{
        margin-top: 20px;
    }

    .y-icon-header{
        padding: 5px;
        width: 38px;
        height: 38px;
        margin: 16px 10px 10px 10px
    }

    .y-icon-header:hover{
        padding: 6px;
        background: #f3f3f3;
        border-radius: 6px;
    }

    .title{
        font-size: 22px;
        margin-top: 15px;
        font-weight: bold;
        margin-left: 10px;
    }

    .input{
        width: 100%;
        font-size: 16px;
        justify-content: left;
        text-align: left;
    }

    .button{
      width: 60%;
      background: #c82333;
      font-size: 14px;
      padding: 10px;
      color: white;
      border: solid 1px #c82333;
      border-radius: 3px;
    }

    .button:hover{
      background: #721c24;
    }

    .toggle-btn{
      font-size: 11px;
    }

    .toggle-piece{
      padding-top: 5px;
      padding-bottom: 5px;
      width: 100px;
      border: solid 1px #dedede;
    }

    .toggle-piece-active{
      color: #fff;
      padding-top: 5px;
      padding-bottom: 5px;
      width: 100px;
      background: #c82333;
      border: solid 1px #c82333;
    }
</style>