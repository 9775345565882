<template>
  <div class="customers">
    <b-row class="header">
      <b-col cols="1">
        <a class="navbar-brand ml-3">
          <img @click="back()" src="../../../../../assets/ic_cancel.png" class="y-icon-header">
        </a>
      </b-col>

      <b-col cols="9" style="margin: auto;">
        <div class="flex-grow-1 d-md-flex align-items-center justify-content-center d-none">
          <h1 class="h5 text-center title">New Customers</h1>
        </div>
      </b-col>

      <b-col cols="2">
      </b-col>
    </b-row>
    <b-row class="clearfix content">
      <b-col cols="1" sm="1" md="1" lg="1"></b-col>
      <b-col cols="10" sm="10" md="10" lg="10">
        <b-row class="list-header">
          <b-col cols="2">
            <span class="font-weight-bold text">Name</span>
          </b-col>
          <b-col cols="2">
            <span class="font-weight-bold text">Phone No.</span>
          </b-col>
          <b-col cols="2">
            <span class="font-weight-bold text">Receipt No.</span>
          </b-col>
          <b-col cols="2">
            <span class="font-weight-bold text">Amount</span>
          </b-col>
          <b-col cols="1">
            <span class="font-weight-bold text">Points</span>
          </b-col>
          <b-col cols="1">
            <span class="font-weight-bold text">Location</span>
          </b-col>
          <b-col cols="2">
            <span class="font-weight-bold text">Time</span>
          </b-col>
        </b-row>
        <b-overlay :show ="loading" rounded="sm">
          <b-row v-for="(customer, idx) in getNewCustomers.data"
                 :key="idx" class="list-item">
            <b-col cols="2">
              <span class="text font-weight-bold">{{customer.name}}</span>
            </b-col>
            <b-col cols="2">
              <span class="text">{{customer.phone_number}}</span>
            </b-col>
            <b-col cols="2">
              <span class="text">{{ customer.receipt_no}}</span>
            </b-col>
            <b-col cols="2">
              <span class="text">{{Number(customer.amount).toLocaleString()}}</span>
            </b-col>
            <b-col cols="1">
              <span class="text">{{customer.points}}</span>
            </b-col>
            <b-col cols="1">
              <span class="text">{{customer.location_name}}</span>
            </b-col>
            <b-col cols="2">
              <span class="text">{{customer.created_at}}</span>
            </b-col>
          </b-row>
          <b-row style="margin-top: 20px;">
            <b-col cols="1">
              <button class="button" @click="lazyLoadUp">Prev</button>
            </b-col>
            <b-col cols="1" class="text-center">
              <p class="pages">{{getReportDetailsMeta.current_page}}/{{getReportDetailsMeta.last_page}}</p>
            </b-col>
            <b-col cols="1">
              <button class="button" @click="lazyLoadDown">Next</button>
            </b-col>
            <b-col cols="9"></b-col>
          </b-row>
        </b-overlay>
      </b-col>
      <b-col cols="1" sm="1" md="1" lg="1"></b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import router from "../../../../../app/router/router";
import moment from "moment";

export default {
  name: "Customers",
  data(){
    return{
      months:["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      months_short:["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      // loading: true,
      location_id: null,
      search:null,
      selectedData:null,
      selectedId:null,
      form:{
        customer_id: null,
        customer_card_no: null,
        business_id: null,
        current_points: null
      },
    }
  },
  mounted() {
    if(localStorage.getItem("token") == null){
      router.push("/")
    }
    this.location_id = window.location.href.split('/')[4]
    let data = {
      date:{
        from:moment(localStorage.getItem("d-from")).format('YYYY-MM-DD'),
        to:moment(localStorage.getItem("d-to")).format('YYYY-MM-DD')
      },
      location:this.location_id
    }
    this.fetchNewCustomers(data)
  },
  methods:{
    ...mapActions(["fetchNewCustomers", "fetchNewCustomersPaged"]),
    back(){
      window.close();
    },
    lazyLoadUp(){
      if(parseInt(this.getReportDetailsMeta.current_page) - 1 > 0){
        let data = {
          page:parseInt(this.getReportDetailsMeta.current_page) - 1,
          location:this.location_id,
          date:{
            from:moment(localStorage.getItem("d-from")).format('YYYY-MM-DD'),
            to:moment(localStorage.getItem("d-to")).format('YYYY-MM-DD')
          }
        }
        this.loading = true
        this.fetchNewCustomersPaged(data)
      }
    },
    lazyLoadDown(){
      if(parseInt(this.getReportDetailsMeta.current_page) + 1 <= parseInt(this.getReportDetailsMeta.last_page)){
        let data = {
          page:parseInt(this.getReportDetailsMeta.current_page) + 1,
          location:this.location_id,
          date:{
            from:moment(localStorage.getItem("d-from")).format('YYYY-MM-DD'),
            to:moment(localStorage.getItem("d-to")).format('YYYY-MM-DD')
          }
        }
        this.loading = true
        this.fetchNewCustomersPaged(data)
      }else{
        alert("No more pages of "+this.getReportDetailsMeta.current_page+"/"+this.getReportDetailsMeta.last_page)
      }
    },
  },
  computed:{
    ...mapGetters(["getNewCustomers", "getReportDetailsMeta"]),
  },
  watch:{
    getNewCustomers(){
      this.loading = false
    }
  }
}
</script>

<style scoped>
@import "../../../../../commons/css/main.css";

.content{
  margin-top: 50px;
}

.header{
  /*margin: 0;*/
  /*width: 100%;*/
  /*box-shadow: 0px 5px 3px #dedede;*/
  padding: 0;
  margin: 0;
  width: 100%;
  height: 85px;
  box-shadow: 0px 5px 3px #dedede;
}

.y-icon-header{
  padding: 5px;
  width: 38px;
  height: 38px;
  margin: 16px 10px 10px 10px
}

.y-icon-header:hover{
  padding: 6px;
  background: #f3f3f3;
  border-radius: 6px;
}

.title{
  font-size: 22px;
  margin-top: 15px;
  font-weight: bold;
  margin-left: 10px;
}

.list-header{
  width: 100%;
  background: #eaeaea;
  padding-bottom: 10px;
  padding-top: 10px;
  margin: 0;
}

.list-header span{
  font-weight: bold;
}

.list-item{
  width: 100%;
  background: white;
  padding-bottom: 15px;
  padding-top: 15px;
  margin: 0;
  border-bottom: solid 1px #979696;
}

.list-item:hover{
  background: #dddddd;
}

.text{
  font-size: 14px;
}

.pages{
  font-size: 16px;
  margin-top: 5px;
}

.desc{
  font-size: 14px;
}

.line{
  margin-top: 10px;
  margin-bottom: 20px;
}

.input{
  border: solid 2px #707070;
  border-radius: 6px;
}

th{
  font-size: 14px;
}

.m-input{
  font-size: 14px;
}

.button{
  width: 100%;
  background: #c82333;
  font-size: 12px;
  padding: 5px;
  color: white;
  border: solid 1px #c82333;
  border-radius: 3px;
}

.button:hover{
  background: #a80312;
}
</style>