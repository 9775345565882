import axios from "axios";
import configs from "../../../app/configurations/configs";

const IP = configs.SERVER_IP;

const state = {
  countries:[],
  error:{}
};

const getters = {
  getCountries: (state) => state.countries,
};

const actions = {
  async fetchCountries({ commit }) {
    await axios
        .get(IP + "/countries")
        .then((response) => {
          commit("setCountries", response.data);
        })
  },
};

const mutations = {
  setCountries: (state, response) => {
    state.countries = response.data;
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
