<template>
    <div class="items">

      <b-row>
        <b-col cols="2" sm="4" md="4" lg="6" xl="6" class="less">
          <span class="font-weight-bold h3">View Customer Journey</span>
        </b-col>
        <b-col cols="10" sm="8" md="8" lg="6" xl="6"></b-col>
      </b-row>
      <b-row class="line">
        <b-col cols="3">
          <b-form>
            <b-form-select v-model="filter_location" class="select">
              <b-select-option value="null">All Locations</b-select-option>
              <b-select-option v-for="(location, idx) in getLocations" :key="idx" :value="location.id">
                {{location.location_name}} ({{location.address}})
              </b-select-option>
            </b-form-select>
          </b-form>
        </b-col>
        <b-col cols="3">
          <b-form>
            <b-form-select v-model="filter_status" class="select">
              <b-select-option value="null">All Statuses</b-select-option>
              <b-select-option v-for="(status, idx) in statuses" :key="idx" :value="status">
                {{status}}
              </b-select-option>
            </b-form-select>
          </b-form>
        </b-col>
        <b-col cols="3">
          <div class="input-group">
            <b-form-input type="text" class="form-control input" placeholder="Search by receipt"  v-model="search" v-on:keyup="searchNow"/>
          </div>
        </b-col>
        <b-col cols="3">
        </b-col>
      </b-row>
      <b-row v-for="(journey, idx) in getJourneys" :key="idx">
        <b-col cols="12">
          <b-row class="list-header" >
            <b-col cols="2">
              <span class="font-weight-bold">{{journey.date}}</span>
            </b-col>
            <b-col v-if="idx == 0" cols="3">
              <span class="font-weight-bold">Name</span>
            </b-col>
            <b-col v-if="idx == 0" cols="3">
              <span class="font-weight-bold">Status</span>
            </b-col>
            <b-col v-if="idx == 0" cols="2">
              <span class="font-weight-bold">Amount</span>
            </b-col>
            <b-col v-if="idx == 0" cols="2">
              <span class="font-weight-bold text-center">Time</span>
            </b-col>
          </b-row>
          <b-overlay :show ="loading" rounded="sm">
            <b-row v-for="(item, jidx) in journey.journeys" :key="jidx"
                   @click="onSelect(item)"
                   class="list-item">
              <b-col cols="2">
                <span class="text receipt">{{item.receipt_no}}</span>
              </b-col>
              <b-col cols="3">
                <span class="text name">{{item.name}}</span>
              </b-col>
              <b-col cols="3">
                <span :class="[(item.journey_status == 'Approved') ? 'status-complete' : (item.journey_status == 'Cancelled') ? 'status-canceled' : '']">
                            {{item.journey_status}}
                </span>
              </b-col>
              <b-col cols="2">
                <span class="text">UGX. {{Number(item.amount).toLocaleString()}}</span>
              </b-col>
              <b-col cols="2" style="float: right;">
                <span class="text">{{getTime(item.created_at)}}</span>
              </b-col>
            </b-row>

          </b-overlay>
        </b-col>
      </b-row>
      <b-row style="margin-top: 20px; margin-bottom: 20px;">
        <b-col cols="1">
          <button class="button" @click="prev">Prev</button>
        </b-col>
        <b-col cols="1" class="text-center">
          <p class="pages">{{getJourneysMeta.current_page}}/{{getJourneysMeta.last_page}}</p>
        </b-col>
        <b-col cols="1">
          <button class="button" @click="next">Next</button>
        </b-col>
        <b-col cols="9"></b-col>
      </b-row>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from "moment";
import router from "@/app/router/router";

export default {
  name: "Rewards",
  data(){
    return{
      search:null,
      loading:true,
      filter_location:null,
      filter_status:null,
      form:{},
      statuses:["Pending", "Approved", "Canceled"],
      date_range:{
        startDate:null,
        endDate:null
      }
    }
  },
  mounted() {
    this.loading = true
    this.fetchJourneys()
    this.fetchLocations()
  },
  methods:{
    ...mapActions(["fetchJourneys", "searchJourneys", "fetchJourneysPaged","fetchJourneysPending", "fetchJourneysApproved", "fetchJourneysCanceled", "fetchLocations"]),
    getDateString(date){
      return moment(date).format("DD/MM/YYYY")
    },
    onSelect(data){
      localStorage.setItem("journey", JSON.stringify(data))
      router.push("/journey-details")
    },
    searchNow(){
      this.loading = true
      this.searchJourneys(this.search)
    },
    prev(){
      if(parseInt(this.getJourneysMeta.current_page) - 1 > 0){
        let data = {
          page:parseInt(this.getJourneysMeta.current_page) - 1
        }
        this.loading = true
        this.fetchExpensesPaged(data)
      }
    },
    next(){
      if(parseInt(this.getJourneysMeta.current_page) + 1 <= parseInt(this.getJourneysMeta.last_page)){
        let data = {
          page:parseInt(this.getJourneysMeta.current_page) + 1
        }
        this.loading = true
        this.fetchJourneysPaged(data)
      }
    },
    filterByLocation(location_id){
      console.log(location_id)
    },
    filterByStatus(status){
      if(status == "Pending"){
        this.loading = true
        this.fetchJourneysPending()
      }

      if(status == "Approved"){
        this.loading = true
        this.fetchJourneysApproved()
      }

      if(status == "Canceled"){
        this.loading = true
        this.fetchJourneysCanceled()
      }
    },
    getTime(date){
      var sections = date.split("T")
      var sections2 = sections[1].split(".")
      var sections3 = sections[1].split(":")
      var total = parseInt(sections2[0].replaceAll(":", ""))
      if(total > 120000){
        return sections3[0]+":"+sections3[1] + " pm"
      }else{
        return sections3[0]+":"+sections3[1] + " am"
      }
    }

  },
  computed:{
    ...mapGetters([
      "getJourneys", "getJourneysMeta","getLocations"
    ]),
    getFilterLocation(){
      return this.filter_location;
    },
    getFilterStatus(){
      return this.filter_status;
    }
  },
  watch:{
    getJourneys(){
      this.loading = false
    },
    getFilterStatus(data){
      this.filterByStatus(data)
    },
    getFilterLocation(data){
      this.filterByLocation(data)
    }
  }
}
</script>

<style scoped>
@import "../../../../commons/css/main.css";

.items{
  margin-top: 10px;
}

.button{
  width: 100%;
  background: #c82333;
  font-size: 12px;
  padding: 10px;
  color: white;
  border: solid 1px #c82333;
  border-radius: 3px;
}

.line{
  margin-top: 20px;
  margin-bottom: 20px;
}

.select{
  width: 100%;
}

.list-header{
  width: 100%;
  background: #eaeaea;
  padding-bottom: 10px;
  padding-top: 10px;
  border-radius: 6px;
  margin: 0;
}

.list-header span{
  font-weight: bold;
}

.list-item{
  width: 100%;
  background: white;
  padding-bottom: 15px;
  padding-top: 15px;
  margin: 0;
  border-bottom: solid 1px #979696;
}

.list-item:hover{
  background: #dddddd;
}

.text{
  font-size: 14px;
}

.input{
  border: solid 1px #dedede;
  border-radius: 8px;
}
.floater{
  float: right;
}

.name{
  color: #1b1e21;
}

.re

.input{
  border: solid 2px #707070;
  border-radius: 6px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #c82333;
  background-color: #c82333;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(255, 48, 3, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ff88a3;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #ffb4c8;
  border-color: #ff90a6;
}

.status-canceled{
  color: #bd2130;
  font-weight: bold;
}

.status-complete{
  color: #1e7e34;
  font-weight: bold;
}
</style>