<template>
  <div class="home">
    <b-row class="content">
      <b-col cols="1" sm="1" md="1" lg="1"></b-col>
      <b-col cols="7">
        <b-row>
          <b-col cols="3">
            <b-form>
              <b-form-select v-model="filter_location" class="select">
                <b-select-option value="null">All Locations</b-select-option>
                <b-select-option v-for="(location, idx) in getLocations" :key="idx" :value="location.id">
                  {{location.location_name}} ({{location.address}})
                </b-select-option>
              </b-form-select>
            </b-form>
          </b-col>
        </b-row>
        <b-row class="line">
          <b-col>
            <span class="title">Welcome back. Here's your business at a glance.</span>
          </b-col>
        </b-row>
        <b-row class="line2">
          <b-col>
            <span class="title">Today</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="5">
            <b-card style="border-radius: 6px;">
              <b-card-body>
                <b-row>
                  <span class="pill-title">Gross sales</span>
                </b-row>
                <b-row>
                  <span class="font-weight-bold h3" v-if="getLandingReport.gross_sale > 0">
                    UGX {{Number(getLandingReport.gross_sale).toLocaleString()}}
                  </span>
                  <span class="font-weight-bold h3" v-else>
                    UGX 0
                  </span>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col cols="1"></b-col>
          <b-col cols="5">
            <b-card style="border-radius: 6px;">
              <b-card-body>
                <b-row>
                  <span class="pill-title">Transactions</span>
                </b-row>
                <b-row>
                  <span class="font-weight-bold h3" v-if="getLandingReport.transaction_count > 0">
                    {{Number(getLandingReport.transaction_count).toLocaleString()}}
                  </span>
                  <span class="font-weight-bold h3" v-else>
                    0
                  </span>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col cols="1"></b-col>
        </b-row>
        <b-row class="line">
          <b-col cols="11">
            <b-card style="border-radius: 6px;">
              <b-card-body>
                <b-row>
                  <span class="title">Top expenses of the day</span>
                </b-row>
                <b-row v-for="(expense, idx) in getLandingReport.expenses" :key="idx">
                  <b-col class="right-list-item">
                    <b-row>
                      <b-col cols="8" class="less">
                        <span class="left-item-text">{{expense.name}}</span>
                      </b-col>
                      <b-col cols="4" class="less">
                        <span class="left-item-text expense-text" style="float: right;">
                          -UGX {{Number(expense.amount).toLocaleString()}}
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col cols="1"></b-col>
        </b-row>
        <b-row class="line2">
          <b-col>
            <span class="title">Subscription</span>
          </b-col>
        </b-row>
        <b-row class="line">
          <b-col cols="11">
            <b-card style="border-radius: 6px;">
              <b-card-body>
                <b-row>
                  <span class="title">All locations</span>
                </b-row>
                <b-row>
                  <b-col cols="6">
                    <b-row>
                      <b-col class="right-list-item">
                        <b-row>
                          <b-col cols="8" class="less">
                            <span class="left-item-text orange">Customer walk-ins</span>
                          </b-col>
                          <b-col cols="4" class="less">
                            <span class="left-item-text" style="float: right;">{{ Number(getLandingReport.walk_ins).toLocaleString() }}</span>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="right-list-item">
                        <b-row>
                          <b-col cols="8" class="less">
                            <span class="left-item-text grey">Subscriptions</span>
                          </b-col>
                          <b-col cols="4" class="less">
                            <span class="left-item-text" style="float: right;">{{ Number(getLandingReport.subscription).toLocaleString() }}</span>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="right-list-item">
                        <b-row>
                          <b-col cols="8" class="less">
                            <span class="left-item-text red">Check-ins</span>
                          </b-col>
                          <b-col cols="4" class="less">
                            <span class="left-item-text" style="float: right;">{{ Number(getLandingReport.check_ins).toLocaleString() }}</span>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="6">
                    <GChart
                        type="PieChart"
                        :data="chartData"
                        :options="chartOptions"
                        style="margin: 0; padding: 0;"/>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col cols="1"></b-col>
        </b-row>
      </b-col>
      <b-col cols="2">
        <b-row>
          <b-col class="right-header">
            <div class="right-header-label text-center">This month's summary</div>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="right-list-item">
            <b-row>
              <b-col cols="8" class="less">
                <span class="left-item-text">Customer retention rate</span>
              </b-col>
              <b-col cols="4" class="less">
                <span class="left-item-text" style="float: right;">{{getLandingReport.customer_retention_rate}}</span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="right-list-item">
            <b-row>
              <b-col cols="8" class="less">
                <span class="left-item-text">New customers</span>
              </b-col>
              <b-col cols="4" class="less">
                <span class="left-item-text" style="float: right;">{{Number(getLandingReport.new_customers).toLocaleString()}}</span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="right-list-item">
            <b-row>
              <b-col cols="8" class="less">
                <span class="left-item-text">Returning cutomers</span>
              </b-col>
              <b-col cols="4" class="less">
                <span class="left-item-text" style="float: right;">{{Number(getLandingReport.returning_customers).toLocaleString()}}</span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="right-list-item">
            <b-row>
              <b-col cols="8" class="less">
                <span class="left-item-text">Repeat purchase rate</span>
              </b-col>
              <b-col cols="4" class="less">
                <span class="left-item-text" style="float: right;">{{getLandingReport.repeat_purchase_rate}}</span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="right-list-item">
            <b-row>
              <b-col cols="8" class="less">
                <span class="left-item-text">Redemption rate</span>
              </b-col>
              <b-col cols="4" class="less">
                <span class="left-item-text" style="float: right;">{{getLandingReport.redemption_rate}}</span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="right-list-item">
            <b-row>
              <b-col cols="8" class="less">
                <span class="left-item-text">Positive Feedback</span>
              </b-col>
              <b-col cols="4" class="less">
                <span class="left-item-text" style="float: right;">{{Number(getLandingReport.positive_feedback).toLocaleString()}}</span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="right-list-item">
            <b-row>
              <b-col cols="8" class="less">
                <span class="left-item-text">Negative feedback</span>
              </b-col>
              <b-col cols="4" class="less">
                <span class="left-item-text" style="float: right;">{{Number(getLandingReport.negative_feedback).toLocaleString()}}</span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="1" sm="1" md="1" lg="1"></b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from "moment"
import { GChart } from 'vue-google-charts'

export default {
  name: "DashboardHome",
  components:{GChart},
  data(){
    return{
      filter_location:null,
      date:null,
      chartData: [],
      chartOptions: {
        chart: {
          title: 'Subscription Distribution',
          subtitle: 'Subscription Sales',
        },
        colors: ["orange", "dimgrey", "#bd2130"],
      }
    }
  },
  mounted() {
    this.date = moment().format('YYYY-MM-DD')
    this.fetchLandingReports(this.date)
    this.fetchLocations()
  },
  methods:{
    ...mapActions(["fetchLocations", "fetchLandingReports", "fetchLandingReportsFilter"]),
    filter(){
      let data = {
        date:this.date,
        location:this.filter_location
      }
      this.fetchLandingReportsFilter(data)
    },
    toDegrees(number, total){
      return (number/total) * 360
    }
  },
  computed:{
    ...mapGetters(["getLocations", "getLandingReport"]),
    getFilterLocation(){
      return this.filter_location
    }
  },
  watch:{
    getLandingReport(data){

      let walk_ins = parseInt(data.walk_ins)
      let subscription = parseInt(data.subscription)
      let check_ins = parseInt(data.check_ins)
      let total = walk_ins + subscription + check_ins
      var chart = [
        ['Allocation', 'Sales'],
        ['Walk-ins', this.toDegrees(walk_ins, total)],
        ['Subscriptions', this.toDegrees(subscription, total)],
        ['Check-ins', this.toDegrees(check_ins, total)],
      ]
      this.chartData = chart
    },
    getFilterLocation(data){
      if(data != null){
        this.filter()
      }else{
        this.fetchLandingReports(this.date)
      }
    }
  }
};
</script>

<style scoped>
@import "../../../../commons/css/main.css";

.home{

}

.content{
  margin-top: 50px;
  margin-bottom: 50px;
}

.title{
  font-weight: bold;
  font-size: 20px;
}

.right-header{
  background: #bd2130;
  border-radius: 6px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.right-header-label{
  color: white;
  font-weight: bold;
  font-size: 14px;
}

.right-list-item{
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: solid 1px #d0d0d0;
}

.left-item-text{
  color: #1d2124;
  font-size: 14px;
}

.less{
  margin:0;
  padding: 0;
}

.line{
  margin-top: 20px;
  margin-bottom: 20px;
}

.line2{
  margin-top: 40px;
  margin-bottom: 20px;
}

.pill-title{
  color: #707070;
  font-size: 12px;
  font-weight: bold;
}

.expense-text{
  color: #bd2130;
}

.orange{
  color: orange;
  font-weight: bold;
}

.red{
  color: #bd2130;
  font-weight: bold;
}

.grey{
  color: dimgrey;
  font-weight: bold;
}

</style>
