<template>
  <div>
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <b-row class="customer-header">
          <b-col cols="1" >
            <img src="../../../../assets/ic_cancel.png" class="cancel" @click="closeSideMenu">
          </b-col>
          <b-col cols="10" class="text-center" >
            <span class="font-weight-bold h5">{{data.name}}</span>
          </b-col>
          <b-col cols="1" ></b-col>
        </b-row>

        <b-row class="spacing">
          <b-col class="customer-card">
            <b-row>
              <b-col cols="10">
                <span class="font-weight-bold">Personal Information</span>
              </b-col>
              <b-col cols="2">
                <button class="edit-button" @click="openEditMember">Edit</button>
              </b-col>
            </b-row>

            <b-row style="margin-top: 20px">
              <b-col cols="6">
                <div class="customer-detail-title">FIRST NAME</div>
                <div class="customer-detail-value">{{data.name.split(" ")[0]}}</div>
              </b-col>
              <b-col cols="6">
                <div class="customer-detail-title">LAST NAME</div>
                <div class="customer-detail-value">{{data.name.split(" ")[data.name.split(" ").length - 1]}}</div>
              </b-col>
            </b-row>

            <b-row style="margin-top: 20px">
              <b-col>
                <div class="customer-detail-title">TEAM MEMBER ID</div>
                <div class="customer-detail-value">{{data.team_id}}</div>
              </b-col>
            </b-row>

            <b-row style="margin-top: 20px">
              <b-col>
                <div class="customer-detail-title">EMAIL</div>
                <div class="customer-detail-value">{{data.email}}</div>
              </b-col>
            </b-row>

            <b-row style="margin-top: 20px">
              <b-col>
                <div class="customer-detail-title">PHONE</div>
                <div class="customer-detail-value">{{data.phone_number}}</div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="spacing">
          <b-col class="customer-card">
            <b-row>
              <b-col cols="10">
                <span class="font-weight-bold">Add permissions to finish setup</span>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <span style="font-size: 14px;">
                  Give access to Yamzit apps, and assign or restrict permissions for those apps
                </span>
              </b-col>
            </b-row>

            <b-row class="spacing"></b-row>

            <b-row>
              <b-col cols="12">
                <button class="button" @click="goToPerms(data.id, data.permissions)">Finish Setup</button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="spacing">
          <b-col class="customer-card">
            <b-row>
              <b-col cols="10">
                <span class="font-weight-bold">Permissions</span>
              </b-col>
              <b-col cols="2">
                <button class="edit-button" v-b-modal.edit_perms>Edit</button>
              </b-col>
            </b-row>
            <b-row style="margin-top: 20px">
              <b-col>
                <div class="customer-detail-title">ACCESS</div>
                <div class="customer-detail-value">
                  <span>
                    Point of sale, Appointments, Dashboard, Loyalty Program, Subscription
                  </span>
                </div>
              </b-col>
            </b-row>
            <b-row style="margin-top: 20px">
              <b-col>
                <div class="customer-detail-title">PASSCODE</div>
                <div class="customer-detail-value">
                  <span>
                    {{data.passcode}}
                  </span>
                </div>
              </b-col>
            </b-row>
            <b-row style="margin-top: 20px">
              <b-col>
                <div class="customer-detail-title">PERMISSIONS</div>
                <div class="customer-detail-value">
                  <b-row v-for="(p, idx) in data.permissions" :key="idx" style="margin-bottom: 5px;">
                    <b-col cols="1" v-if="p.status" >
                      <img src="../../../../assets/apps/ic_loyalty.png" class="ic-permission"/>
                    </b-col>
                    <b-col cols="11"  style="margin-top: 8px;" v-if="p.status">
                      <span style="margin-left: 20px; ">{{capitalize(p.permission)}}</span>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="spacing">
          <b-col class="customer-card">
            <b-row>
              <b-col cols="10">
                <span class="font-weight-bold">Job and Compensation</span>
              </b-col>
              <b-col cols="2">
                <button class="edit-button" @click="openEditMember">Edit</button>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="6">
                <b-row style="margin-top: 20px">
                  <b-col>
                    <div class="customer-detail-title">PRIMARY JOB</div>
                    <div class="customer-detail-value">{{data.job_title}}</div>
                  </b-col>
                </b-row>
              </b-col>

              <b-col cols="6">
                <b-row style="margin-top: 20px">
                  <b-col>
                    <div class="customer-detail-title">INCOME</div>
                    <div class="customer-detail-value" v-if="data.pay_type == 'Percentage' ">{{data.amount}}%</div>
                    <div class="customer-detail-value" v-else>UGX. {{Number(data.amount).toLocaleString()}}</div>
                  </b-col>
                </b-row>
              </b-col>

            </b-row>

          </b-col>
        </b-row>

      </b-col>
    </b-row>
  </div>

</template>

<script>
import router from "@/app/router/router";
export default {
  name: "TeamMemberDetails",
  props:{data:Object, type:String},
  methods:{
    closeSideMenu(){
      this.$root.$emit('close-details')
    },
    openEditMember(){
      localStorage.setItem("member", JSON.stringify(this.data))
      router.push("/edit-member")
    },
    goToPerms(id, perms){
      localStorage.setItem("permissions", JSON.stringify(perms))
      router.push("/permissions/"+id)
    },
    capitalize(string) {
      if(string != null){
        var cleanedString = string.replaceAll("_", " ")
        return cleanedString.charAt(0).toUpperCase() + cleanedString.slice(1);
      }
      return ""
    }
  },
}
</script>

<style scoped>

@import "../../../../commons/css/main.css";

.customer-card{
  box-shadow: 1px 2px 3px 3px #e7e7e7;
  background: white;
  border-radius: 6px;
  padding: 30px;
  width: 100%;
}

.customer-header{
  background: white;
  padding-bottom: 20px;
  padding-top: 20px;
}

.spacing{
  padding:10px;
}

.cancel{
  border: solid 1px #707070;
  border-radius: 6px;
  padding: 5px;
  width: 30px;
  height: 30px;
}

.cancel:hover{
  padding: 8px;
}

.edit-button{
  border: 0;
  font-size: 12px;
  background: white;
}
.edit-button:hover{
  background: #f7f7f7;
  color: #721c24;
}

.customer-detail-title{
  color: #bab9b9;
  font-size: 12px;
}

.customer-detail-value{
  font-size: 14px;
 }
.button{
  width: 100%;
  background: #c82333;
  font-size: 12px;
  padding: 10px;
  color: white;
  border: solid 1px #c82333;
  border-radius: 3px;
}

.ic-permission{
  width: 40px;
}


</style>