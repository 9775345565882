<template>
    <div class="expense">

      <b-row class="header">
            <a class="navbar-brand ml-3">
                <img @click="back()" src="../../../../assets/ic_cancel.png" class="y-icon-header">
            </a>
            <div class="flex-grow-1 d-md-flex align-items-center justify-content-center d-none">
                <h1 class="h5 text-center title">View Detailed Report</h1>
            </div>
        </b-row>

      <b-row class="content">
        <b-col cols="2"></b-col>
        <b-col cols="8">
          <b-row>
            <b-col class="summary-header">
              <span class="font-weight-bold">Detailed Report</span>
            </b-col>
          </b-row>

          <!-- Sales-->
          <b-row>
            <b-col cols="12">
              <b-row class="summary-item-border">
                <b-col cols="8">
                  <span class="font-weight-bold">Sales</span>
                </b-col>
                <b-col cols="4"></b-col>
              </b-row>
            </b-col>
            <b-col cols="12">
              <b-row v-for="(sale, idx) in data.sales" :key="idx">
                <b-col cols="12">
                  <b-row class="summary-item-border" style="cursor:pointer;" @click="showDetailsSales(idx, sale.category_id)">
                    <b-col cols="8">
                      <b-row>
                        <img :class="(salesList[idx].show)? 'collection-indicator-down' : 'collection-indicator'">
                        <span>{{sale.category}}</span>
                      </b-row>
                    </b-col>
                    <b-col cols="4"></b-col>
                  </b-row>
                </b-col>
                <b-col cols="12" v-if="salesList[idx].show">
                  <b-row class="summary-item-border" v-for="(item, idx) in sale.item" :key="idx">
                    <b-col cols="1"></b-col>
                    <b-col cols="7">
                      <span class="item-label">{{item.item.name}}</span>
                    </b-col>
                    <b-col cols="4">
                      <span class="item-label" style="float: right;">- UGX {{Number(item.item.price).toLocaleString()}}</span>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row class="summary-item-header">
            <b-col cols="3">
              <span class="h5 font-weight-bold">Gross Sales</span>
            </b-col>
            <b-col cols="6"></b-col>
            <b-col cols="3">
              <span class="h5 font-weight-bold summary-amount">
                UGX. {{(Number(data.gross_sales).toLocaleString())}}
              </span>
            </b-col>
          </b-row>

          <!-- Discounts -->
          <b-row>
            <b-col cols="12">
              <b-row class="summary-item-border" @click="showDetails('discounts')">
                 <b-col cols="8">
                   <b-row>
                     <img :class="(discounts)? 'collection-indicator-down' : 'collection-indicator'">
                     <span>Discounts</span>
                   </b-row>
                 </b-col>
                <b-col cols="4">
                  <span style="float: right;">- UGX {{Number(data.discounts.total).toLocaleString()}}</span>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" v-if="discounts">
              <b-row class="summary-item-border" v-for="(item, idx) in data.discounts.items" :key="idx">
                <b-col cols="1"></b-col>
                <b-col cols="5">
                  <span class="item-label">{{item.discount.discount_name}}</span>
                </b-col>
                <b-col cols="2">
                  <span class="item-label">x{{item.item_quantity}}</span>
                </b-col>
                <b-col cols="4">
                  <span class="item-label" style="float: right;">- UGX {{Number(calculateDiscount(item)).toLocaleString()}}</span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row class="summary-item-header">
            <b-col cols="3">
              <span class="h5 font-weight-bold">Net Sales</span>
            </b-col>
            <b-col cols="6"></b-col>
            <b-col cols="3">
              <span class="h5 font-weight-bold summary-amount">
                UGX. {{(Number(data.net_sales).toLocaleString())}}
              </span>
            </b-col>
          </b-row>

          <!-- Expenses -->
          <b-row>
            <b-col cols="12">
              <b-row class="summary-item-border" @click="showDetails('expenses')">
                <b-col cols="8">
                  <b-row>
                    <img :class="(expenses)? 'collection-indicator-down' : 'collection-indicator'">
                    <span>Expenses</span>
                  </b-row>
                </b-col>
                <b-col cols="4">
                  <span style="float: right;">- UGX {{Number(data.expenses.total).toLocaleString()}}</span>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" v-if="expenses">
              <b-row class="summary-item-border" v-for="(item, idx) in data.expenses.items" :key="idx">
                <b-col cols="1"></b-col>
                <b-col cols="7">
                  <span class="item-label">{{item.name}}</span>
                </b-col>
                <b-col cols="4">
                  <span class="item-label" style="float: right;">- UGX {{Number(item.amount).toLocaleString()}}</span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <!-- Commissions -->
          <b-row>
            <b-col cols="12">
              <b-row class="summary-item-border" @click="showDetails('commissions')">
                <b-col cols="8">
                  <b-row>
                    <img :class="(commissions)? 'collection-indicator-down' : 'collection-indicator'">
                    <span>Commissions</span>
                  </b-row>
                </b-col>
                <b-col cols="4">
                  <span style="float: right;">- UGX {{Number(data.commissions.total).toLocaleString()}}</span>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" v-if="commissions">
              <b-row class="summary-item-border" v-for="(item, idx) in data.commissions.items" :key="idx">
                <b-col cols="1"></b-col>
                <b-col cols="7">
                  <span class="item-label">{{item.name}}</span>
                </b-col>
                <b-col cols="4">
                  <span class="item-label" style="float: right;">- UGX {{Number(item.amount).toLocaleString()}}</span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row class="summary-item-header">
            <b-col cols="3">
              <span class="h5 font-weight-bold">Net Profit</span>
            </b-col>
            <b-col cols="6"></b-col>
            <b-col cols="3">
              <span class="h5 font-weight-bold summary-amount">
                UGX. {{(Number(data.net_profit).toLocaleString())}}
              </span>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="2"></b-col>
      </b-row>

    </div>
</template>

<script>
    import router from "@/app/router/router";

    export default {
        name: "DetailedReport",
        data(){
            return{
              loading:false,
              data:null,
              discounts:false,
              commissions:false,
              expenses:false,
              salesList:[]
            }
        },
        created() {
          this.data = JSON.parse(localStorage.getItem("reports"))
          for(let i = 0;  i < this.data.sales.length; i++){
            let data = {
              show:false,
              category: this.data.sales[i].category
            }
            this.salesList.push(data)
          }
          console.log(localStorage.getItem("reports"))
        },
        methods:{
          back(){
            window.history.back();
          },
          showDetails(type){
            if(type == "discounts"){
              this.discounts = !this.discounts
            }
            if(type == "expenses"){
              this.expenses = !this.expenses
            }
            if(type == "commissions"){
              this.commissions= !this.commissions
            }
          },
          showDetailsSales(idx, id){
            this.salesList[idx].show = !this.salesList[idx].show
            router.push({name:"sales-report-details", query:{id:id}})
          },
          calculateDiscount(product){
            var qty = product.item_quantity
            var amount = product.discount.amount
            var type = product.discount.discount_type
            var price = parseInt(product.item_subtotal)/parseInt(product.item_quantity)

            if(type == "Amount"){
              return amount * qty;
            }

            if(type == "Percentage"){
              return  (parseInt(price) * amount/100) * qty
            }
          },
        },
    }
</script>

<style scoped>
    @import "../../../../commons/css/main.css";

    .expense{

    }

    .header{
        /*margin: 0;*/
        /*width: 100%;*/
        /*box-shadow: 0px 5px 3px #dedede;*/
        padding: 0;
        margin: 0;
        width: 100%;
        height: 85px;
        box-shadow: 0px 5px 3px #dedede;
    }

    .content{
        margin-top: 80px;
    }

    .y-icon-header{
        padding: 5px;
        width: 38px;
        height: 38px;
        margin: 16px 10px 10px 10px
    }

    .y-icon-header:hover{
        padding: 6px;
        background: #f3f3f3;
        border-radius: 6px;
    }

    .title{
        font-size: 22px;
        margin-top: 15px;
        font-weight: bold;
        margin-left: 10px;
    }

    .summary-header{
      width: 100%;
      background: #eaeaea;
      padding-bottom: 15px;
      padding-top: 15px;
      margin: 0;
    }

    .summary-item-border{
      padding-top:15px;
      padding-bottom: 15px;
      border-bottom: solid 1px #1b1e21;
    }

    .summary-item-header{
      padding-top:15px;
      padding-bottom: 15px;
      border-bottom: solid 1px #1d2124;
    }

    .summary-amount{
      float: right;
    }
    .item-label{
      color: #959595;
    }

    .collection-indicator{
      width: 14px;
      height: 14px;
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 5px;
      content: url("../../../../assets/side.svg");
    }

    .collection-indicator-down{
      width: 14px;
      height: 14px;
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 5px;
      content: url("../../../../assets/down.svg");
    }

</style>