<template>
    <div class="items">
      <b-row>
        <b-col cols="12">
          <router-view></router-view>
        </b-col>
      </b-row>
    </div>
</template>

<script>
export default {
  name: "BusinessSettings",
}
</script>

<style scoped>
@import "../../../../../commons/css/main.css";

.items{
  margin-top: 10px;
}

</style>