<template>
    <div class="settings">
      <b-row>
        <b-col>
          <span class="h5 font-weight-bold">Security Add-ons</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="7">
          <b-row class="setting">
            <b-col cols="11">
              <b-row>
                <span class="setting-title">Receipt tracking</span>
              </b-row>
              <b-row>
                <b-col cols="11">
                  <b-row>
                    <span class="text">You will be able to attach a receipt number to all points and redeems that are given out through the Loyalty program</span>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="1">
              <div class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="emls" :checked="tracking">
                <label class="custom-control-label" for="emls"></label>
              </div>
            </b-col>
          </b-row>
          <b-row class="setting">
            <b-col cols="11">
              <b-row>
                <span class="setting-title">Loyalty 2Step verification</span>
              </b-row>
              <b-row>
                <b-col cols="11">
                  <b-row>
                    <span class="text">All points and redeems will have to be approved on the loyalty program. NB - This doesn’t apply to the redeem process done though the P.O.S.</span>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="1">
              <div class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="dob" :checked="verification">
                <label class="custom-control-label" for="dob"></label>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
export default {
  name: "GeneralSettings",
  data(){
    return{
      tracking:false,
      verification:false
    }
  },
  methods:{
    ...mapActions(["updateSettings"]),
  },
  computed:{
    ...mapGetters([
      "getSettingResponse",
      "getSettingErrorResponse"
    ]),
  }
}
</script>

<style scoped>
@import "../../../../../commons/css/main.css";

.settings{
  margin-top: 10px;
}

.setting{
  padding: 20px;
  margin-left: 10px;
}

.setting-title{
  font-weight: bold;
  font-size: 14px;
}

.text{
  font-size: 14px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #c82333;
  background-color: #c82333;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(255, 48, 3, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ff88a3;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #ffb4c8;
  border-color: #ff90a6;
}
</style>