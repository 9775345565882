import axios from "axios";
import configs from "../../../../../app/configurations/configs.json";
//import qs from "querystring";

const IP = configs.SERVER_IP;

const state = {
  rewards:[],
  customer_journeys:[],
  meta:{},
  point_value:{},
  response:{},
  error:{},
};

const getters = {
    getRewards: (state) => state.rewards,
    getJourneys: (state) => state.customer_journeys,
    getJourneysMeta: (state) => state.meta,
    getPointValue: (state) => state.point_value,
    getRewardAddedResponse: (state) => state.response,
    getRewardDeactivatedResponse: (state) => state.response,
};

const actions = {
  async fetchRewards({ commit }) {
    await axios
        .get(IP + "/rewards/business/"+localStorage.getItem("business_id"))
        .then((response) => {
          commit("setRewards", response.data);
        })
        .catch((error) => {
          commit("setErrorResponse", error.response);
        });
  },
    async fetchJourneys({ commit }) {
        await axios
            .get(IP + "/customerjourney/all?business_id="+localStorage.getItem("business_id")+"&page=1")
            .then((response) => {
                commit("setJourneys", response.data);
                commit("setJourneysMetaResponse", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },
    async searchJourneys({ commit }, text) {
        await axios
            .get(IP + "/customerjourney/search?business_id="+localStorage.getItem("business_id")+"&page=1&q="+text)
            .then((response) => {
                commit("setJourneys", response.data);
                commit("setJourneysMetaResponse", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },
    async fetchJourneysPaged({ commit }, data) {
        await axios
            .get(IP + "/customerjourney/all?business_id="+localStorage.getItem("business_id")+"&page="+data.page)
            .then((response) => {
                commit("setJourneys", response.data);
                commit("setJourneysMetaResponse", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },
    async fetchJourneysPending({ commit }) {
        await axios
            .get(IP + "/customerjourney/pending?business_id="+localStorage.getItem("business_id"))
            .then((response) => {
                commit("setJourneys", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },
    async fetchJourneysApproved({ commit }) {
        await axios
            .get(IP + "/customerjourney/approved?business_id="+localStorage.getItem("business_id"))
            .then((response) => {
                commit("setJourneys", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },
    async fetchJourneysCanceled({ commit }) {
        await axios
            .get(IP + "/customerjourney/canceled?business_id="+localStorage.getItem("business_id"))
            .then((response) => {
                commit("setJourneys", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },

  async addReward({ commit }, data) {
     await axios
         .post(IP + "/rewards", data)
         .then((response) => {
           commit("setRewardAdded", response.data);
         })
         .catch((error) => {
           commit("setErrorResponse", error.response);
         });
  },
  async deactivateReward({ commit }, data) {
     await axios
        .put(IP + "/rewards/"+data.id, data)
        .then((response) => {
           commit("setRewardDeactivated", response.data);
        })
        .catch((error) => {
           commit("setErrorResponse", error.response);
        });
  },
  async fetchPointValue({ commit }) {
     await axios
        .get(IP + "/pointvalue/"+localStorage.getItem("business_id"))
        .then((response) => {
            commit("setPointValue", response.data);
        })
        .catch((error) => {
            commit("setErrorResponse", error.response);
        });
  },
  async updatePointValue({ commit }, data) {
     await axios
        .put(IP + "/pointvalue/"+localStorage.getItem("business_id"), data)
        .then((response) => {
           commit("setPointValue", response.data);
        })
        .catch((error) => {
           commit("setErrorResponse", error.response);
        });
  },
};

const mutations = {
  setRewards: (state, response) => {
    state.rewards = response.data;
  },
  setJourneys: (state, response) => {
    state.customer_journeys = response.data;
  },
  setJourneysMetaResponse: (state, response) => {
     state.meta = response.paginator;
  },
  setRewardAdded: (state, response) => {
    state.response = response.data;
  },
  setPointValue: (state, response) => {
    state.point_value = response.data;
  },
  setRewardDeactivated: (state, response) => {
    state.response = response.data;
  },
  setErrorResponse: (state, response) => {
    state.error = response.data;
  },



};

export default {
  state,
  getters,
  actions,
  mutations,
};
