<template>
    <div class="expense">
        <section class="h-100 w-100 d-flex flex-column ">
            <div class="py-4">
                <a class="navbar-brand ml-3 text-danger font-weight-bold action-text " @click="done()">
                    Done
                </a>
            </div>
            <div class="center d-flex justify-content-md-center align-items-center flex-column flex-grow-1">
                <div class="col-md-8 col-lg-7 mx-auto mt-5 mt-md-0">
                    <div class="d-flex flex-column align-items-center mt-5 mt-md-0">
                        <img src="../../../../assets/ic_done_green.png" class="y-icon"/>
                        <p class="mt-2 d-inline-block text">Refund Complete</p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import router from "../../../../app/router/router"

    export default {
        name: "RefundComplete",
        methods:{
            done(){
                localStorage.removeItem("refund")
                router.push("/dashboard/transactions")
            },
        },
    }
</script>

<style scoped>
    @import "../../../../commons/css/main.css";

    .expense{

    }

    .y-icon{
        width: 150px;
    }

    .center{
        margin-top: 180px;
    }

    .text{
        font-size: 16px;
    }

    .action-text{
        font-size: 26px;
    }

</style>