import Vue from "vue";
import Vuex from "vuex";
import login from "../../views/auth/vuex-store/login";
import transactions from "@/views/dashboard/menu/transactions/vuex-store/transactions";
import expenses from "@/views/dashboard/menu/expenses/vuex-store/expenses";
import locations from "@/views/location/vuex-store/locations";
import customers from "@/views/dashboard/menu/customers/vuex-store/customers";
import home from "@/views/dashboard/menu/home/vuex-store/home";
import reports from "@/views/dashboard/menu/reports/vuex-store/reports";
import products from "@/views/dashboard/menu/items/vuex-store/products";
import accounts from "@/views/account/vuex-store/account"
import teams from "@/views/dashboard/menu/teams/vuex-store/teams";
import payroll from "@/views/dashboard/menu/payroll/vuex-store/payroll";
import loyalty from "@/views/dashboard/menu/loyalty_program/vuex-store/loyalty";
import industries from "@/views/account/vuex-store/industries";
import countries from "@/views/account/vuex-store/countries";
import settings from "@/views/dashboard/menu/settings/vuex-store/settings";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    login,
    accounts,
    home,
    transactions,
    expenses,
    locations,
    customers,
    reports,
    products,
    teams,
    payroll,
    loyalty,
    industries,
    countries,
    settings
  }
});
