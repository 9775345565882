<template>
  <div>
    <b-row>
      <b-col cols="12" md="12" lg="12">

        <b-row class="customer-header">
          <b-col cols="1" >
            <img src="../../../../assets/ic_cancel.png" class="cancel" @click="closeSideMenu">
          </b-col>
          <b-col cols="10" class="text-center" >
            <span class="font-weight-bold h5">{{data.name}}</span>
          </b-col>
          <b-col cols="1" ></b-col>
        </b-row>

        <b-row class="spacing">
          <b-col class="customer-card">
            <b-row>
              <b-col cols="10">
                <span class="font-weight-bold">Personal Information</span>
              </b-col>
              <b-col cols="2">
                <button class="edit-button" @click="openEditCustomer">Edit</button>
              </b-col>
            </b-row>

            <b-row style="margin-top: 20px">
              <b-col>
                <div class="customer-detail-title">NAME</div>
                <div class="customer-detail-value">{{data.name}}</div>
              </b-col>
            </b-row>

            <b-row style="margin-top: 20px">
              <b-col>
                <div class="customer-detail-title">PHONE</div>
                <div class="customer-detail-value">{{data.phone_number}}</div>
              </b-col>
            </b-row>

            <b-row style="margin-top: 20px">
              <b-col>
                <div class="customer-detail-title">EMAIL</div>
                <div class="customer-detail-value">{{data.email}}</div>
              </b-col>
            </b-row>

            <b-row style="margin-top: 20px">
              <b-col>
                <div class="customer-detail-title">ADDRESS</div>
                <div class="customer-detail-value">{{data.address}}</div>
              </b-col>
            </b-row>

            <b-row style="margin-top: 20px">
              <b-col>
                <div class="customer-detail-title">BIRTHDAY</div>
                <div class="customer-detail-value">{{data.date_of_birth}}</div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="spacing" v-if="data.customer_card_no != null">
          <b-col class="customer-card">
            <b-row>
              <b-col cols="10">
                <span class="font-weight-bold">Customer Loyalty card number</span>
              </b-col>
              <b-col cols="2">
                <button class="edit-button" v-b-modal.edit_card>Edit</button>
              </b-col>
            </b-row>
            <b-row style="margin-top: 20px">
              <b-col>
                <div class="customer-detail-title">CARD NUMBER</div>
                <div class="customer-detail-value">{{data.customer_card_no}}</div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row v-else class="spacing">
          <b-col class="customer-card">
            <b-row>
              <b-col cols="10">
                <span class="font-weight-bold">Add Customer Loyalty card number</span>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <span style="font-size: 14px;">Attach a custom card number the customer can use at your business to receive & redeem points.</span>
              </b-col>
            </b-row>

            <b-row class="spacing"></b-row>

            <b-row>
              <b-col cols="12">
                <button class="button" v-b-modal.edit_card>Add Card Number</button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>



      </b-col>
    </b-row>
  </div>

</template>

<script>
import router from "@/app/router/router";
export default {
  name: "CustomerDetails",
  props:{data:Object, type:String},
  methods:{
    closeSideMenu(){
      this.$root.$emit('close-details')
    },
    openEditCustomer(){
      localStorage.setItem("customer", JSON.stringify(this.data))
      router.push("/edit-customer")
    },
  },
}
</script>

<style scoped>

@import "../../../../commons/css/main.css";

.customer-card{
  box-shadow: 1px 2px 3px 3px #e7e7e7;
  background: white;
  border-radius: 6px;
  padding: 30px;
  width: 100%;
}

.customer-header{
  background: white;
  padding-bottom: 20px;
  padding-top: 20px;
}

.spacing{
  padding:10px;
}

.cancel{
  border: solid 1px #707070;
  border-radius: 6px;
  padding: 5px;
  width: 30px;
  height: 30px;
}

.cancel:hover{
  padding: 8px;
}

.edit-button{
  border: 0;
  font-size: 12px;
  background: white;
}
.edit-button:hover{
  background: #f7f7f7;
  color: #721c24;
}

.customer-detail-title{
  color: #bab9b9;
  font-size: 12px;
}

.customer-detail-value{
  font-size: 14px;
 }
.button{
  width: 100%;
  background: #c82333;
  font-size: 12px;
  padding: 10px;
  color: white;
  border: solid 1px #c82333;
  border-radius: 3px;
}


</style>