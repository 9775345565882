<template>
    <div>
      <b-row>
        <b-col class="text-center">
          <span class="font-weight-bold title" style="margin-bottom: 20px">Tell us about your business</span>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form>
            <div class="form-group">
              <label class="font-weight-bold form-label">What is your business name</label>
              <b-form-input id="name" type="text"
                            v-model="form.business_name"
                            class="form-control "
                            placeholder="Business name"
                            name="name" value="" required/>
            </div>
          </b-form>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <span class="detail">If you do not have an official business name, enter the name you want on your receipts.</span>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <b-form>
            <div class="form-group">
              <label class="font-weight-bold form-label">Business Address</label>
              <b-form-input id="email" type="text"
                            v-model="form.address"
                            class="form-control "
                            placeholder="Address"
                            name="email" value="" required/>
            </div>
            <div class="form-group">
              <label class="font-weight-bold form-label">Business Category</label>
              <b-form-select id="password" type="text"
                             v-model="form.industry_type_id" class="form-control" required>
                <b-select-option value="null">Category</b-select-option>
                <b-select-option
                    v-for="(industry, idx) in getIndustries"
                    :key="idx"
                    :value="industry.id" >{{industry.name}}</b-select-option>
              </b-form-select>
            </div>
          </b-form>
        </b-col>

        <b-col cols="6">
          <b-form>
            <div class="form-group">
              <label class="font-weight-bold form-label">City</label>
              <b-form-input id="city" type="text"
                            v-model="form.city"
                            class="form-control"
                            placeholder="City"
                            name="city" value="" required/>
            </div>
            <div class="form-group">
              <label class="font-weight-bold form-label">Payment Method</label>
              <b-form-select id="password" type="text"
                             v-model="form.payment_method" class="form-control" required>
                <b-select-option value="null">None</b-select-option>
                <b-select-option value="mobile_money">Mobile Money</b-select-option>
                <b-select-option value="cash">Cash</b-select-option>
                <b-select-option value="visa">Visa</b-select-option>
              </b-form-select>
            </div>
          </b-form>
        </b-col>
      </b-row>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";
    export default {
        name: "BusinessInformation",
        data(){
            return {
                form:{
                  business_name:null,
                  address:null,
                  payment_method:null,
                  industry_type_id:null,
                  city:null,
                },
            }
        },
        mounted() {
          this.fetchIndustries()
          let form = JSON.parse(localStorage.getItem("signup-form"))
          this.form.business_name = form.business_name
          this.form.address = form.address
          this.form.payment_method = form.payment_method
          this.form.industry_type_id = form.industry_type_id
          this.form.city = form.city
        },
        methods:{
          ...mapActions(["fetchIndustries"]),
        },
        computed: {
          ...mapGetters([
             "getIndustries",
          ]),
        },
        watch: {
          form:{
            handler(data){
              this.$emit("businessInfoData", data)
            },
            deep:true
          },
        }
    }
</script>

<style scoped>
    @import "../../commons/css/main.css";

    .form-label{
      font-size: 12px;
    }

    .detail{
      font-size: 12px;
    }
    .title{
      font-size: 35px;
      font-weight: bold;
    }

    @media only screen and (max-width: 570px){

        .form-label{
            font-size: 12px;
        }

    }

    @media only screen and (max-width: 380px){
      .title{
        font-size: 22px;
      }
      .form-control{
        font-size: 12px;
        padding: 5px;
      }
      .detail{
        font-size: 9px;
      }
    }
</style>