import axios from "axios";
import configs from "../../../app/configurations/configs";

const IP = configs.SERVER_IP;

const state = {
  response:{},
  error:{}
};

const getters = {
  getSignUpResponse: (state) => state.response,
  getSignUpErrorResponse: (state) => state.error,
};

const actions = {
  async accountSignUp({ commit }, data) {
    await axios
      .post(IP + "/signup", data)
      .then((response) => {
        commit("setSignUpSuccessResponse", response);
      })
      .catch((error) => {
        commit("setSignUpErrorResponse", error.response);
      });
  },
};

const mutations = {
  setSignUpSuccessResponse: (state, response) => {
    state.response = response.data;
  },
  setSignUpErrorResponse: (state, response) => {
    state.error = response.data;
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
