<template>
    <div class="settings">
      <b-row>
        <b-col>
          <span class="h5 font-weight-bold">Basic Information</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="7">
          <b-row class="setting">
            <b-col cols="11">
              <b-row>
                <span class="setting-title">Emails</span>
              </b-row>
              <b-row>
                <span class="text">Collect email address from your customers</span>
              </b-row>
            </b-col>
            <b-col cols="1">
              <div class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="emls" v-on:input="changeSetting(1)" :checked="settings[1].email">
                <label class="custom-control-label" for="emls"></label>
              </div>
            </b-col>
          </b-row>
          <b-row class="setting">
            <b-col cols="11">
              <b-row>
                <span class="setting-title">Birthdays</span>
              </b-row>
              <b-row>
                <span class="text">Collect birthday information to get to know your customers better</span>
              </b-row>
            </b-col>
            <b-col cols="1">
              <div class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="dob" v-on:input="changeSetting(2)" :checked="settings[2].date_of_birth">
                <label class="custom-control-label" for="dob"></label>
              </div>
            </b-col>
          </b-row>
          <b-row class="setting">
            <b-col cols="11">
              <b-row>
                <span class="setting-title">Number Plates</span>
              </b-row>
              <b-row>
                <span class="text">Collect number plates from your customers</span>
              </b-row>
            </b-col>
            <b-col cols="1">
              <div class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="no_plates" v-on:input="changeSetting(4)" :checked="settings[4].number_plate">
                <label class="custom-control-label" for="no_plates"></label>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="top-padding">
        <b-col>
          <span class="h5 font-weight-bold">Permissions</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="7">
          <b-row class="setting">
            <b-col cols="11">
              <b-row>
                <span class="setting-title">User Tracking</span>
              </b-row>
              <b-row>
                <b-col cols="11">
                  <b-row>
                    <span class="text">Track all active on Yamzit using permissions. Each user will use their Log In using their credentials across all platforms</span>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="1">
              <div class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="utrck" v-on:input="changeSetting(7)" :checked="settings[7].permissions">
                <label class="custom-control-label" for="utrck"></label>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "GeneralSettings",
  data(){
    return{
      settings: JSON.parse(localStorage.getItem("settings")),
    }
  },
  mounted() {

  },
  methods:{
    ...mapActions(["updateSettings", "enablePermissions"]),
    changeSetting(idx){

      if(idx == 1){
        if(this.settings[idx].email){
          this.settings[idx].email = false
        }else{
          this.settings[idx].email = true
        }
      }

      if(idx == 2){
        if(this.settings[idx].date_of_birth){
          this.settings[idx].date_of_birth = false
        }else{
          this.settings[idx].date_of_birth = true
        }
      }

      if(idx == 4){
        if(this.settings[idx].number_plate){
          this.settings[idx].number_plate = false
        }else{
          this.settings[idx].number_plate = true
        }
      }

      if(idx == 7){
        if(this.settings[idx].permissions){
          this.settings[idx].permissions = false
        }else{
          this.settings[idx].permissions = true
        }
      }

      localStorage.setItem("settings", JSON.stringify(this.settings))
      let data = {
        settings:JSON.stringify(this.settings)
      }
      this.updateSettings(data)
    }
  },
  computed:{
    ...mapGetters([
      "getSettingResponse",
      "getSettingErrorResponse"
    ]),
  },
  watch:{
    getSettingErrorResponse(){
      if(this.settings[7].permissions){
        this.settings[7].permissions = false
      }else{
        this.settings[7].permissions = true
      }
      localStorage.setItem("settings", JSON.stringify(this.settings))
    }
  }
}
</script>

<style scoped>
@import "../../../../../commons/css/main.css";

.settings{
  margin-top: 10px;
}

.setting{
  padding: 20px;
  margin-left: 10px;
}

.setting-title{
  font-weight: bold;
  font-size: 14px;
}

.text{
  font-size: 14px;
}

.top-padding{
  margin-top: 40px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #c82333;
  background-color: #c82333;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(255, 48, 3, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ff88a3;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #ffb4c8;
  border-color: #ff90a6;
}
</style>