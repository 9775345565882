import axios from "axios";
import configs from "../../../../../app/configurations/configs.json";
//import qs from "querystring";

const IP = configs.SERVER_IP;

const state = {
  categories:[],
  items:[],
  discounts:[],
  response:{},
  error:{},
};

const getters = {
    getCategories: (state) => state.categories,
    getItems: (state) => state.items,
    getDiscounts: (state) => state.discounts,
    getActionResponse: (state) => state.response,
    getActionErrorResponse: (state) => state.error,
};

const actions = {
  async fetchCategories({ commit }) {
    await axios
        .get(IP + "/category/business/"+localStorage.getItem("business_id"))
        .then((response) => {
          commit("setCategories", response.data);
        })
        .catch((error) => {
          commit("setErrorResponse", error.response);
        });
  },
  async addCategory({ commit}, data) {
    await axios
       .post(IP + "/category", data)
       .then((response) => {
           commit("setActionSuccess", response.data);
       })
       .catch((error) => {
           commit("setErrorResponse", error.response);
       });
  },
  async addItem({ commit}, data) {
        await axios
            .post(IP + "/items", data)
            .then((response) => {
                commit("setActionSuccess", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
  },
  async editItem({ commit}, data) {
      await axios
        .put(IP + "/items/"+data.id, data)
        .then((response) => {
            commit("setActionSuccess", response.data);
        })
        .catch((error) => {
            commit("setErrorResponse", error.response);
        });
  },
  async deleteItem({ commit}, id) {
      await axios
        .delete(IP + "/items/"+id,)
        .then((response) => {
           commit("setActionSuccess", response.data);
        })
        .catch((error) => {
           commit("setErrorResponse", error.response);
        });
 },
    async editCategories({ commit}, data) {
        await axios
            .put(IP + "/category/"+data.id, data)
            .then((response) => {
                commit("setActionSuccess", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },
    async disableCategories({ commit}, id) {
        await axios
            .delete(IP + "/category/"+id,)
            .then((response) => {
                commit("setActionSuccess", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },
  async addDiscount({ commit}, data) {
        await axios
            .post(IP + "/business-discounts", data)
            .then((response) => {
                commit("setActionSuccess", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
  },
  async fetchItems({ commit}) {
      await axios
          .get(IP + "/items/business/"+localStorage.getItem("business_id"))
          .then((response) => {
              commit("setItems", response.data);
          })
          .catch((error) => {
              commit("setErrorResponse", error.response);
          });
  },
  async fetchDisabledItems({ commit}) {
        await axios
            .get(IP + "/items/deactivated?business_id="+localStorage.getItem("business_id"))
            .then((response) => {
                commit("setItems", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
  },
  async fetchDiscounts({ commit}) {
    await axios
        .get(IP + "/business-discounts/business/"+localStorage.getItem("business_id"))
        .then((response) => {
          commit("setDiscounts", response.data);
        })
        .catch((error) => {
          commit("setErrorResponse", error.response);
        });
  },
  async filterDiscounts({ commit}, data) {
    await axios
       .get(IP + "/business-discounts/business/"+localStorage.getItem("business_id")+"?location="+data.location)
         .then((response) => {
            commit("setDiscounts", response.data);
         })
         .catch((error) => {
            commit("setErrorResponse", error.response);
         });
    },
    async filterItems({ commit}, data) {
        await axios
            .get(IP + "/category/items?category="+data.category+"&location="+data.location)
            .then((response) => {
                commit("setItems", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },
};

const mutations = {
  setCategories: (state, response) => {
    state.categories = response.data;
  },
  setItems: (state, response) => {
    state.items = response.data;
  },
  setActionSuccess: (state, response) => {
      state.response = response.data;
  },
  setDiscounts: (state, response) => {
      state.discounts = response.data;
  },
  setErrorResponse: (state, response) => {
    state.error = response.error;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
