<template>
  <b-col cols="12" class="menu">
    <b-row class="line">
      <b-col cols="4" >
        <b-row>
          <b-col cols="12" class="align-content-center">
            <div class="menu_option" @click="loadMenuItem('home')">
              <img src="../../../assets/menu/menu_home.svg" class="img">
            </div>
          </b-col>
        </b-row>
        <b-row class="align-content-center text-center">
          <b-col>
            <p class="label">Home</p>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="4" >
        <b-row>
          <b-col cols="12" class="align-content-center">
            <div class="menu_option" @click="loadMenuItem('reports/summary')">
              <img src="../../../assets/menu/menu_reports.svg" class="img">
            </div>
          </b-col>
        </b-row>
        <b-row class="align-content-center text-center">
          <b-col>
            <p class="label">Reports</p>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="4" >
        <b-row>
          <b-col cols="12" class="align-content-center">
            <div class="menu_option" @click="loadMenuItem('transactions')">
              <img src="../../../assets/menu/menu_transactions.svg" class="img-3">
            </div>
          </b-col>
        </b-row>
        <b-row class="align-content-center text-center">
          <b-col>
            <p class="label">Transactions</p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="line">
      <b-col cols="4" >
        <b-row>
          <b-col cols="12" class="align-content-center">
            <div class="menu_option" @click="loadMenuItem('expenses')">
              <img src="../../../assets/menu/menu_expenses.svg" class="img">
            </div>
          </b-col>
        </b-row>
        <b-row class="align-content-center text-center">
          <b-col>
            <p class="label">Expenses</p>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="4" >
        <b-row >
          <b-col cols="12" class="align-content-center">
            <div class="menu_option" @click="loadMenuItem('products/items')">
              <img src="../../../assets/menu/menu_items.svg" class="img">
            </div>
          </b-col>
        </b-row>
        <b-row class="align-content-center text-center">
          <b-col>
            <p class="label">Items</p>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="4" >
        <b-row >
          <b-col cols="12" class="align-content-center">
            <div class="menu_option" @click="loadMenuItem('customers')">
              <img src="../../../assets/menu/menu_id_card.svg" class="img-2">
            </div>
          </b-col>
        </b-row>
        <b-row class="align-content-center text-center">
          <b-col>
            <p class="label">Customers</p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="line2">
      <b-col cols="4" >
        <b-row>
          <b-col cols="12" class="align-content-center">
            <div class="menu_option" @click="loadMenuItem('team')">
              <img src="../../../assets/menu/menu_group.svg" class="img-3">
            </div>
          </b-col>
        </b-row>
        <b-row class="align-content-center text-center">
          <b-col>
            <p class="label">Team</p>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="4" >
        <b-row>
          <b-col cols="12" class="align-content-center">
            <div class="menu_option" @click="loadMenuItem('payroll')">
              <img src="../../../assets/menu/menu_pay.svg" class="img-2">
            </div>
          </b-col>
        </b-row>
        <b-row class="align-content-center text-center">
          <b-col>
            <p class="label">Payroll</p>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="4" >
        <b-row >
          <b-col cols="12" class="align-content-center">
            <div class="menu_option" @click="loadMenuItem('loyalty')">
              <img src="../../../assets/menu/menu_loyalty.svg" class="img">
            </div>
          </b-col>
        </b-row>
        <b-row class="align-content-center text-center">
          <b-col>
            <p class="label">Loyalty Program</p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="4" class="less">
        <b-row >
          <b-col cols="12" class="less">
            <div class="menu_option" @click="loadMenuItem('settings')">
              <img src="../../../assets/menu/menu_settings.svg" class="img">
            </div>
          </b-col>
        </b-row>
        <b-row class="align-content-center text-center">
          <b-col class="less">
            <p class="label">Settings</p>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="4" class="less">
        <b-row>
          <b-col cols="12" class="less">
            <div class="menu_option" @click="loadMenuItem('apps')">
              <img src="../../../assets/menu/menu_app.svg" class="img">
            </div>
          </b-col>
        </b-row>
        <b-row class="align-content-center text-center">
          <b-col>
            <p class="label">Apps</p>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="4" >

      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import router from "../../../app/router/router"
export default {
  name: "DashboardMenu",
  methods:{
    loadMenuItem(route){
      router.push("/dashboard/"+route)
    }
  }
}
</script>

<style scoped>
@import "../../../commons/css/main.css";
.menu{
  box-shadow: 5px 0 5px -2px #dedede;
  height: 100vh;
  width: 18vw;
  position: fixed;
  background: white;
  z-index: 2;
}

.menu_option{
  width: 38px;
  height: 40px;
  background: #3A3E46;
  border-radius: 3px;
  margin: auto;
}

.menu_option:hover{
  background: #52565f;
}

.img{
  width: 11px;
  margin-left: 4px;
}

.img-2{
  width: 17px;
  height: 9px;
  margin-left: 4px;
}

.img-3{
  width: 15px;
  height: 11px;
  margin-left: 2px;
}

.label{
  font-size: 10px;
  color: #707070;
  opacity: 1;
  margin: auto;
}

.line{
  margin-bottom: 30px;
  margin-top: 20px;
}

.line2{
  margin-bottom: 10px;
  margin-top: 20px;
}

.less{
  margin: 0;
  padding: 0;
}
@media only screen and (max-width: 1281px){

  .menu_option{
    width: 40px;
    height: 41px;
  }

  .img{
    width: 11px;
    margin-left: 4px;
  }

  .img-2{
    width: 17px;
    height: 9px;
    margin-left: 4px;
  }

  .img-3{
    width: 15px;
    height: 11px;
    margin-left: 2px;
  }

  .label{
    font-size: 10px;
  }

}

</style>