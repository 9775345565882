import axios from "axios";
import configs from "../../../../../app/configurations/configs";

const IP = configs.SERVER_IP;

const state = {
  customers:[],
  paginator:{},
  response:{},
  error:{}
};

const getters = {
  getCustomers: (state) => state.customers,
  getCustomerPaginator: (state) => state.paginator,
  getCustomersSuccessResponse: (state) => state.response,
  getCustomersErrorResponse: (state) => state.error,
};

const actions = {
  async fetchCustomers({ commit }) {
    await axios
      .get(IP + "/customers/business/"+localStorage.getItem("business_id"))
      .then((response) => {
          //console.log(JSON.stringify(response.data))
        commit("setCustomersResponse", response.data);
      })
      .catch((error) => {
        commit("setErrorResponse", error.response);
      });
  },
  async fetchCustomersPaged({ commit }, data) {
    await axios
      .get(IP + "/customers/business/"+localStorage.getItem("business_id")+"?page="+data.page)
      .then((response) => {
         commit("setCustomersResponse", response.data);
      })
      .catch((error) => {
         commit("setErrorResponse", error.response);
      });
  },
  async filterCustomers({ commit }, data) {
      var params = "q="+data
      await axios
          .get(IP + "/customers/search?business_id="+localStorage.getItem("business_id")+"&"+params)
          .then((response) => {
              //console.log(JSON.stringify(response.data))
              commit("setCustomersResponse", response.data);
          })
        .catch((error) => {
              commit("setErrorResponse", error.response);
      });
  },
  async editCustomer({ commit}, data) {
     await axios
       .put(IP + "/customers/"+data.id, data)
       .then((response) => {
           commit("setCustomerSuccessResponse", response.data);
        })
       .catch((error) => {
           commit("setErrorResponse", error.response);
       });
  },
  async editCard({ commit}, data) {
     await axios
        .put(IP + "/business-customers/"+localStorage.getItem("business_id"), data)
        .then((response) => {
            commit("setCustomerSuccessResponse", response.data);
        })
        .catch((error) => {
            commit("setErrorResponse", error.response);
        });
  },
};

const mutations = {
  setCustomersResponse: (state, response) => {
    state.customers = response.data;
    state.paginator = response.paginator;
  },
  setCustomerSuccessResponse: (state, response) => {
    state.response = response.data;
  },
  setErrorResponse: (state, response) => {
    state.error = response.data;
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
