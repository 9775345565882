<template>
    <div class="settings">
      <b-row>
        <b-col cols="10">
          <b-row>
            <b-col>
              <span class="font-weight-bold h5">Basic Information</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="8">
              <table class="table w-100 font-weight-bold table-bordered setup">
                <tbody>
                <tr>
                  <th class="bg-light">Business Name</th>
                  <td class="font-weight-normal">
                    <span class="text">{{business.name}}</span>
                  </td>
                  <td class="font-weight-normal">
                    <button class="action-button">Edit</button>
                  </td>
                </tr>
                <tr>
                  <th class="bg-light">Email Address</th>
                  <td class="font-weight-normal">
                    <span class="text">{{business.business_email}}</span>
                  </td>
                  <td class="font-weight-normal">
                    <button class="action-button">Change</button>
                  </td>
                </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="setting">
        <b-col cols="10">
          <b-row>
            <b-col>
              <span class="font-weight-bold h5">Logo</span>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="3">
              <b-row>
                <b-col cols="10">
                  <div class="logo-holder text-center">
                    <img src="../../../../../assets/ic_gallery.svg" class="ic">
                    <p class="font-weight-bold thumb-text">Upload Logo</p>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="setting">
        <b-col cols="10">
          <b-row>
            <b-col>
              <span class="font-weight-bold h5">Password</span>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="8">
              <p class="text">
                A strong password contains a mix of numbers, letters, and symbols. It is hard to guess, does not resemble a real word, and is only used for this account.
              </p>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="2">
              <button class="button">Update Password</button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="setting">
        <b-col cols="10">
          <b-row>
            <b-col>
              <span class="font-weight-bold h5">Owner Passcode</span>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="8">
              <p class="text">
                Your Owner Passcode can be used to access all areas of your Square account. For security, we recommend creating a separate Team Passcode at
              </p>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="8">
              <table class="table w-100 font-weight-bold table-bordered setup">
                <tbody>
                <tr>
                  <th class="bg-light">Owner Passcode</th>
                  <td class="font-weight-normal text-center">
                    <span class="code">*</span>
                  </td>
                  <td class="font-weight-normal text-center">
                    <span class="code">*</span>
                  </td>
                  <td class="font-weight-normal text-center">
                    <span class="code">*</span>
                  </td>
                  <td class="font-weight-normal text-center">
                    <span class="code">*</span>
                  </td>
                  <td class="font-weight-normal">
                    <button class="action-button">Generate</button>
                  </td>
                </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>

        </b-col>
      </b-row>
    </div>
</template>

<script>
export default {
  name: "AccountSettings",
  data(){
    return{
      search:null,
      loading:true,
      business:null,
      filter_location:null,
      date_range:{
        startDate:null,
        endDate:null
      }
    }
  },
  mounted() {
    this.business = JSON.parse(localStorage.getItem("business"))
  },
  methods:{

  },
}
</script>

<style scoped>
@import "../../../../../commons/css/main.css";

.settings{
  margin-top: 10px;
}

.text{
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.button{
  width: 100%;
  background: #c82333;
  font-size: 12px;
  padding: 10px;
  color: white;
  border: solid 1px #c82333;
  border-radius: 3px;
}

.button:hover{
  background: #ad2431;
}

.action-button{
  width: 100%;
  background: #fff;
  font-size: 12px;
  color: #c82333;
  border: 0;
}

.action-button:hover{
  color: #212529;
  border-radius: 3px;
}

.code{
  font-weight: bold;
  font-size: 20px;
}

.setup{
  font-size: 12px;
}

.setting{
  margin-top: 40px;
}

.logo-holder{
  border: solid 2px #959595;
  border-radius: 6px;
  margin-top: 10px;
  background: #f1f1f1;
}
.logo-holder:hover{
  background: #f5f5f5;
}

.thumb-text{
  font-size: 14px;
}

.ic{
  padding: 30px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #c82333;
  background-color: #c82333;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(255, 48, 3, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ff88a3;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #ffb4c8;
  border-color: #ff90a6;
}
</style>