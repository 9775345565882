<template>
  <b-row>
    <b-col cols="12" md="12" lg="12">
      <b-row>
        <b-col cols="6" class="less">
          <button @click="deleteAction" v-if="data != null && data.status" class="btn y-btn-outline-dark text-danger font-weight-bold">Delete Expense</button>
        </b-col>
      </b-row>

      <b-row class="clearfix details-body">
        <b-col cols="12" class="details-card">
          <b-row>
            <b-col v-if="data!=null">
              <span class="font-weight-bold h3" v-if="data.status">UGX {{Number(data.amount).toLocaleString()}}</span>
              <span class="font-weight-bold h3 text-danger" v-else>UGX {{Number(data.amount).toLocaleString()}}</span>
            </b-col>
          </b-row>

          <b-row>
            <b-col v-if="data!=null">
              <small style="color: #959595;">{{getDateString(data.created_at)}} {{getTime(data.created_at)}}</small>
            </b-col>
          </b-row>

          <b-row style="margin-top: 10px">
            <b-col v-if="data!=null">
              <small style="color: #959595;">Receipt: </small>
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="data!=null">
              <small style="color: #959595;">Made by: </small>
            </b-col>
          </b-row>

          <div class="bar"></div>

          <b-row>
            <b-col cols="6">
              <b-row>
                <b-col class="r-detail">
                  <span class="font-weight-bold details-text">Expense name</span><br/>
                  <span class="details-text" v-if="data!=null">{{data.name}}</span>
                </b-col>
              </b-row>

              <b-row>
                <b-col class="r-detail">
                  <span class="font-weight-bold details-text">Payment Type</span><br/>
                  <span class="details-text" v-if="data!=null">{{capitalizeFirstLetter(data.payment_type)}}</span>
                </b-col>
              </b-row>

              <b-row>
                <b-col class="r-detail">
                  <span class="font-weight-bold details-text">Description</span><br/>
                  <span class="details-text" v-if="data!=null">{{data.reason}}</span>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="6">
              <b-row>
                <b-col>
                  <span class="font-weight-bold details-text">Amount</span><br/>
                  <span class="details-text" v-if="data!=null">UGX {{Number(data.amount).toLocaleString()}}</span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <div class="bar2" v-if="data != null && data.delete_date != null"></div>

          <b-row v-if="data != null && data.delete_date != null">
            <b-col cols="12">
              <b-row>
                <b-col cols="2">
                  <img src="../../../../assets/ic_expense_deleted.png" class="y-icon-detail"/>
                </b-col>
                <b-col cols="8">
                  <b-row>
                    <span class="font-weight-normal border-0 details-text">Expense was deleted</span>
                  </b-row>
                  <b-row>
                          <span class="font-weight-normal border-0 details-text text-danger">
                            <b>Date:</b> {{getShortDateString(data.delete_date)}}
                          </span>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="padding-top-10">
                  <b-row>
                    <b-col>
                      <b-row class="less">
                        <span class="font-weight-bold details-text">Reason:</span>
                      </b-row>
                      <b-row class="less">
                        <span class="font-weight-normal details-text" v-if="data.delete_reason == null">No reason to show</span>
                        <span class="font-weight-normal details-text" v-else>{{data.delete_reason}}</span>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="clearfix details-footer">
        <b-col cols="12" class="less">
          <button @click="closeSideMenu" class="btn y-btn-footer" style="float: right;">Done</button>
        </b-col>
      </b-row>

    </b-col>
  </b-row>
</template>

<script>
import router from "@/app/router/router";

export default {
  name: "ExpenseDetails",
  props:{data:Object},
  data(){
    return{
      months:["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    }
  },
  methods:{
    closeSideMenu(){
      this.$root.$emit('close-details')
    },
    cleanText(text){
      if(text.includes("_")){
        return this.capitalizeFirstLetter(text.replaceAll("_", " "))
      }
      return this.capitalizeFirstLetter(text);
    },
    getDateString(date){
      var sections = date.split("-")
      var d = sections[2].split("T")
      var month_idx = parseInt(sections[1]) - 1
      return d[0]+" "+this.months[month_idx]+", "+sections[0];
    },
    getTime(date){
      var sections = date.split("T")
      var sections2 = sections[1].split(".")
      var sections3 = sections[1].split(":")
      var total = parseInt(sections2[0].replaceAll(":", ""))
      if(total > 120000){
        return sections3[0]+":"+sections3[1] + " pm"
      }else{
        return sections3[0]+":"+sections3[1] + " am"
      }
    },
    getShortDateString(date){
      var sections = date.split("-")
      var month_idx = parseInt(sections[1]) - 1

      return sections[2]+" "+this.months[month_idx]+", "+sections[0];
    },
    print(){
      localStorage.setItem("transaction", JSON.stringify(this.transaction))
      router.push("/print-receipt")
    },
    deleteAction(){
      router.push("/expense-reason")
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
  computed:{
    getData(){
      return this.data
    }
  },
  watch:{
    getData(data){
      console.log(JSON.stringify(data))
    }
  }
}
</script>

<style scoped>

@import "../../../../commons/css/main.css";

.details-body{
  margin-top: 20px;
}

.details-footer{
  margin-top: 10px;
}

.details-text{
  font-size: 12px;
}

.y-icon-detail{
  width: 42px;
  height: 50px;
}

.y-btn-outline-dark {
  width: 95%;
  color: #343a40;
  font-size: 12px;
  padding: 10px 25px;
  border-radius: 6px;
  background-color: transparent;
  background-image: none;
  border: solid 2px #d0d0d0;
}

.y-btn-footer {
  color: #343a40;
  font-size: 12px;
  padding: 10px 25px;
  border-radius: 6px;
  background-color: transparent;
  background-image: none;
  border: solid 2px #d0d0d0;
}

.y-btn-outline-dark:hover {
  color: #fff;
  background-color: #c6c6c6;
  border-color: #c6c6c6;
}

.details-card{
  border: solid 2px #d0d0d0;
  border-radius: 6px;
  padding: 30px;
  width: 100%;
}

.bar{
  width: 100%;
  background: #a0a0a0;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.bar2{
  width: 100%;
  background: #a0a0a0;
  height: 1px;
  margin-top: 60px;
  margin-bottom: 10px;
}

.less{
  margin:0;
  padding: 0;
}

.r-detail{
  margin-bottom: 10px;
}

.text-danger{
  color: #bd2130;
}

.padding-top-10{
  padding-top: 10px;
}

</style>