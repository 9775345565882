<template>
    <div class="items">
      <b-row>
        <b-col cols="2" sm="4" md="4" lg="6" xl="6" class="less">
          <span class="font-weight-bold h3">Items</span>
        </b-col>
        <b-col cols="10" sm="8" md="8" lg="6" xl="6"></b-col>
      </b-row>
      <b-row class="line">
        <b-col cols="3">
          <b-form>
            <b-form-select v-model="filter_category" class="select">
              <b-select-option value="null">All Categories</b-select-option>
              <b-select-option v-for="(category, idx) in getCategories" :key="idx" :value="category.id">
                {{category.name}}
              </b-select-option>
            </b-form-select>
          </b-form>
        </b-col>
        <b-col cols="3">
          <b-form>
            <b-form-select v-model="filter_location" class="select">
              <b-select-option value="null">All Locations</b-select-option>
              <b-select-option v-for="(location, idx) in getLocations" :key="idx" :value="location.id">
                {{location.location_name}} ({{location.address}})
              </b-select-option>
            </b-form-select>
          </b-form>
        </b-col>
        <b-col cols="4"></b-col>
        <b-col cols="2">
          <button @click="create" class="button">Create an Item</button>
        </b-col>
      </b-row>
      <b-row class="list-header">
        <b-col cols="4">
          <span class="font-weight-bold">Item</span>
        </b-col>
        <b-col cols="2">
          <span class="font-weight-bold">Category</span>
        </b-col>
        <b-col cols="3">
          <span class="font-weight-bold">Locations</span>
        </b-col>
        <b-col cols="2">
          <span class="font-weight-bold" style="float: right;">Price</span>
        </b-col>
        <b-col cols="1">
          <span class="font-weight-bold" style="float: right;">Action</span>
        </b-col>
      </b-row>
      <b-overlay :show ="loading" rounded="sm">
        <b-row v-for="(item, idx) in getItems" :key="idx" class="list-item">
          <b-col cols="4">
            <span class="text name">{{item.name}}</span>
          </b-col>
          <b-col cols="2">
            <span class="text">{{item.category.name}}</span>
          </b-col>
          <b-col cols="3">
            <span class="text">{{item.location.location_name}} ({{item.location.address}})</span>
          </b-col>
          <b-col cols="2">
            <span class="text" style="float: right;">UGX {{Number(item.price).toLocaleString()}}</span>
          </b-col>
          <b-col cols="1">
            <img src="../../../../assets/ic_more_menu.svg" :id="'pop_over_'+idx" class="y-more-menu-icon"/>

            <b-popover class="popover-item" :target="'pop_over_'+idx" triggers="hover focus">
              <template #title>{{item.name}}</template>
              <b-row>
                <b-col class="text-center align-content-center action" @click="toEditItem(item)">
                  <span class="font-weight-bold">Rename</span>
                </b-col>
              </b-row>

              <b-row>
                <b-col class="text-center align-content-center action" @click="deactivate(item.id)">
                  <span class="text-danger font-weight-bold">Deactivate</span>
                </b-col>
              </b-row>

            </b-popover>

          </b-col>
        </b-row>
      </b-overlay>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import router from "@/app/router/router";
export default {
  name: "Items",
  data(){
    return{
      filter_category:null,
      filter_location:null,
      loading:true
    }
  },
  mounted() {
    this.fetchLocations()
    this.fetchCategories()
    this.fetchItems()
  },
  methods:{
    ...mapActions(["fetchCategories", "fetchItems", "filterItems", "fetchLocations", "deleteItem"]),
    create(){
      router.push("/new-item")
    },
    filter(){
      let data = {
        location: this.filter_location,
        category: this.filter_category
      }
      this.loading = true
      this.filterItems(data)
    },
    deactivate(id) {
      this.loading = true
      this.deleteItem(id)
    },
    toEditItem(data){
      localStorage.setItem("item", JSON.stringify(data))
      router.push("/edit-item")
      router.go()
    }
  },
  computed:{
    ...mapGetters([
      "getCategories", "getItems", "getLocations", "getActionResponse", "getActionErrorResponse"
    ]),
    getFilterLocation(){
      return this.filter_location
    },
    getFilterCategory(){
      return this.filter_category
    }
  },
  watch:{
    getItems(){
      this.loading = false
    },
    getFilterLocation(){
      this.filter()
    },
    getFilterCategory(){
      this.filter()
    },
    getActionResponse(){
      this.fetchItems()
    },
    getActionErrorResponse(){
      this.loading = false
    }
  }
}
</script>

<style scoped>
@import "../../../../commons/css/main.css";
@import "../../../../commons/css/menu.css";

.items{
  margin-top: 10px;
}

.button{
  width: 100%;
  background: #c82333;
  font-size: 12px;
  padding: 8px;
  color: white;
  border: solid 1px #c82333;
  border-radius: 3px;
}

.line{
  margin-top: 20px;
  margin-bottom: 20px;
}

.select{
  width: 100%;
}

.list-header{
  width: 100%;
  background: #eaeaea;
  padding-bottom: 10px;
  padding-top: 10px;
  border-radius: 6px;
  margin: 0;
}

.list-header span{
  font-weight: bold;
}

.list-item{
  width: 100%;
  background: white;
  padding-bottom: 15px;
  padding-top: 15px;
  margin: 0;
  border-bottom: solid 1px #979696;
}

.action{
  width: 100%;
  margin: 5px 8px;
  padding: 5px;
  background: #f1f1f1;
  border-radius: 6px;
}

.action:hover{
  background: #e3e2e2;
}

.name{
  color: #bd2130;
}

.text{
  font-size: 14px;
}

.y-more-menu-icon{
  padding: 5px;
  width: 30px;
  float: right;
  margin: 5px 5px 5px 5px;
}

.y-more-menu-icon:hover{
  padding: 6px;
  background: #f3f3f3;
  border-radius: 6px;
}

.popover-item{
  min-width: 90px;
}
</style>