<template>
    <div class="expense">

      <b-row class="header">
        <b-col cols="1">
          <a class="navbar-brand ml-3">
            <img @click="back()" src="../../../../assets/ic_cancel.png" class="y-icon-header">
          </a>
        </b-col>

        <b-col cols="9" style="margin: auto;">
          <div class="flex-grow-1 d-md-flex align-items-center justify-content-center d-none">
            <h1 class="h5 text-center title">Create a Reward</h1>
          </div>
        </b-col>

        <b-col cols="2" style="margin: auto;">
          <button @click="save()" class="button">Save</button>
        </b-col>
      </b-row>
      <section class="content h-100 w-100 d-flex justify-content-center align-items-center flex-column mb-5">
            <b-col cols="7" class="mx-auto">
                <div class="mt-5  d-none d-md-block">
                    <b-overlay :show="loading" rounded="sm">
                      <span class="font-weight-bold h5">Details</span>
                      <table class="table w-100 font-weight-bold table-bordered">
                            <tbody>
                              <tr>
                                  <th class="bg-light">Reward name</th>
                                  <td class="font-weight-normal">
                                      <b-form-input
                                              class="input"
                                              v-model="form.name"
                                              type="text"
                                              placeholder="Name"/>
                                  </td>
                              </tr>
                              <tr>
                                <th class="bg-light">Description</th>
                                <td class="font-weight-normal">
                                  <b-textarea
                                      class="t-input"
                                      v-model="form.description"
                                      type="text"
                                      placeholder="Description"/>
                                </td>
                              </tr>
                            </tbody>
                      </table>

                      <b-row style="margin-top: 40px"></b-row>
                      <span class="font-weight-bold h5">Points</span>
                      <table class="table w-100 font-weight-bold table-bordered">
                        <tbody>
                        <tr v-for="(location, idx) in getLocations" :key="idx">
                          <th class="bg-light">{{location.location_name}} ({{location.address}})</th>
                          <td class="font-weight-normal">
                            <b-form-input
                                class="input"
                                type="text"
                                v-model="form.points[idx].point"
                                placeholder="0"/>
                          </td>
                        </tr>
                        </tbody>
                      </table>

                    </b-overlay>
                </div>
            </b-col>
        </section>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "NewMember",
        data(){
          return{
            form:{
              name:null,
              description:null,
              points:[],
            },
            loading:false,
          }
        },
        mounted() {
          this.fetchLocations()
        },
        methods:{
            ...mapActions(["fetchLocations", "addReward"]),
          onSearch(){

          },
          back(){
             window.history.back();
          },
          save(){
              let data = {
                name: this.form.name,
                description: this.form.description,
                business_id: localStorage.getItem("business_id")
              }

              this.loading = true
              for(let i = 0; i < this.form.points.length; i++){
                data.location_id = this.form.points[i].location_id
                data.points = this.form.points[i].point
                this.addReward(data)
              }
          },
        },
        computed:{
            ...mapGetters([
              "getRewardAddedResponse", "getLocations"
            ]),
        },
        watch:{
          getLocations(data){
            for(let i = 0; i < data.length; i++){
              this.form.points.push(
                  {
                    point:null,
                    location_id:data[i].id
                  }
              )
            }
          },
          getRewardAddedResponse(){
            this.loading = false
            window.history.back()
          },
        }
    }
</script>

<style scoped>
    @import "../../../../commons/css/main.css";

    .expense{

    }

    .header{
        /*margin: 0;*/
        /*width: 100%;*/
        /*box-shadow: 0px 5px 3px #dedede;*/
        padding: 0;
        margin: 0;
        width: 100%;
        height: 85px;
        box-shadow: 0px 5px 3px #dedede;
    }

    .content{
        margin-top: 20px;
    }

    .y-icon-header{
        padding: 5px;
        width: 38px;
        height: 38px;
        margin: 16px 10px 10px 10px
    }

    .y-icon-header:hover{
        padding: 6px;
        background: #f3f3f3;
        border-radius: 6px;
    }

    .title{
        font-size: 22px;
        margin-top: 15px;
        font-weight: bold;
        margin-left: 10px;
    }

    .input{
        width: 100%;
        font-size: 16px;
        justify-content: left;
        text-align: left;
    }

    .t-input{
      width: 100%;
      border: 0;
      font-size: 16px;
      justify-content: left;
      text-align: left;
      overflow-y: hidden;
    }

    .active{
        background: #db0000;
        color: white;
        padding: 5px;
        border: 0;
        border-radius: 3px;
    }

    .button{
      width: 60%;
      background: #c82333;
      font-size: 14px;
      padding: 10px;
      color: white;
      border: solid 1px #c82333;
      border-radius: 3px;
    }

    .button:hover{
      background: #721c24;
    }

</style>