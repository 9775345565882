<template>
    <div class="discounts">
      <b-row>
        <b-col cols="2" sm="4" md="4" lg="6" xl="6" class="less">
          <span class="font-weight-bold h3">All Discounts</span>
        </b-col>
        <b-col cols="10" sm="8" md="8" lg="6" xl="6"></b-col>
      </b-row>
      <b-row class="line">
        <b-col cols="7" class="mx-auto">
          <span class="desc">Categories help you arrange and organize your items, report on item sales and route items to specific printers</span>
        </b-col>
        <b-col cols="3">
          <b-form>
            <b-form-select v-model="filter_location" class="select">
              <b-select-option value="null">All Locations</b-select-option>
              <b-select-option v-for="(location, idx) in getLocations" :key="idx" :value="location.id">
                {{location.location_name}} ({{location.address}})
              </b-select-option>
            </b-form-select>
          </b-form>
        </b-col>
        <b-col cols="2">
          <button @click="create" class="button">Create a Discount</button>
        </b-col>
      </b-row>
      <b-row class="list-header">
        <b-col cols="4">
          <span class="font-weight-bold">Name</span>
        </b-col>
        <b-col cols="4">
          <span class="font-weight-bold">Locations</span>
        </b-col>
        <b-col cols="2">
          <span class="font-weight-bold" style="float: right;">Amount</span>
        </b-col>
      </b-row>
      <b-overlay :show ="loading" rounded="sm">
        <b-row v-for="(item, idx) in getDiscounts" :key="idx" class="list-item">
          <b-col cols="4">
            <span class="text name">{{item.discount_name}}</span>
          </b-col>
          <b-col cols="4">
            <span class="text">{{item.location.location_name}} ({{item.location.address}})</span>
          </b-col>
          <b-col cols="2">
            <b-row style="float: right;">
              <span class="text" v-if="item.discount_type == 'Amount'" style="margin-right: 3px;">UGX</span>
              <span class="text" >{{Number(item.amount).toLocaleString()}}</span>
              <span class="text" v-if="item.discount_type == 'Percentage'">%</span>
            </b-row>
          </b-col>
        </b-row>
      </b-overlay>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import router from "@/app/router/router";
export default {
  name: "Discounts",
  data(){
    return{
      loading:true,
      filter_location:null
    }
  },
  mounted() {
    this.fetchDiscounts()
    this.fetchLocations()
  },
  methods:{
    ...mapActions(["fetchDiscounts", "fetchLocations", "filterDiscounts"]),
    create(){
      router.push("/new-discount")
    },
    filter(){
      let data = {
        location:this.filter_location
      }
      this.filterDiscounts(data)
    }
  },
  computed:{
    ...mapGetters(["getDiscounts", "getLocations"]),
    getFilterLocation(){
      return this.filter_location
    }
  },
  watch:{
    getDiscounts(){
      this.loading = false
    },
    getFilterLocation(){
      this.filter()
    }
  }
}
</script>

<style scoped>
@import "../../../../commons/css/main.css";

.discounts{
  margin-top: 10px;
}

.button{
  width: 100%;
  background: #c82333;
  font-size: 12px;
  padding: 8px;
  color: white;
  border: solid 1px #c82333;
  border-radius: 3px;
}

.line{
  margin-top: 20px;
  margin-bottom: 20px;
}

.list-header{
  width: 100%;
  background: #eaeaea;
  padding-bottom: 10px;
  padding-top: 10px;
  border-radius: 6px;
  margin: 0;
}

.list-header span{
  font-weight: bold;
}

.list-item{
  width: 100%;
  background: white;
  padding-bottom: 15px;
  padding-top: 15px;
  margin: 0;
  border-bottom: solid 1px #979696;
}

.list-item:hover{
  background: #dddddd;
}

.text{
  font-size: 14px;
}

.desc{
  font-size: 14px;
}

</style>