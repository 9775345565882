 c<template>
    <div class="reports">

      <b-row>
        <b-col cols="2" sm="4" md="4" lg="6" xl="6" class="less">
          <span class="font-weight-bold h3">Summary Report</span>
        </b-col>
        <b-col cols="10" sm="8" md="8" lg="6" xl="6" class="less">
          <b-row>
            <b-col cols="5">
              <b-row style="width: 100%;">
                <date-range-picker
                    class="select"
                    ref="picker"
                    :locale-data="{format: 'dd-mm-yyyy' }"
                    :minDate="null" :maxDate="null"
                    :singleDatePicker="false"
                    :timePicker="false"
                    :timePicker24Hour="false"
                    :showWeekNumbers="true"
                    :showDropdowns="true"
                    :autoApply="true"
                    v-model="date_range"
                    :linkedCalendars="true">
                  <template style="width: 100%;">
                     <span style="font-size: 11px;">
                        {{ date_range.startDate }} - {{ date_range.endDate}}
                     </span>
                  </template>
                </date-range-picker>
              </b-row>
            </b-col>
            <b-col cols="4">
              <b-form>
                <b-form-select v-model="filter_location" class="select">
                  <b-select-option value="null">All Locations</b-select-option>
                  <b-select-option v-for="(location, idx) in getLocations" :key="idx" :value="location.id">
                    {{location.location_name}} ({{location.address}})
                  </b-select-option>
                </b-form-select>
              </b-form>
            </b-col>
            <b-col cols="3">
              <button @click="exportData" class="button">Export</button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row><b-col class="bar"></b-col></b-row>

      <b-row v-if="showChart">
        <b-col cols="12" sm="12" md="12" lg="12" xl="12" class="mx-auto align-content-center less">
          <GChart
              type="ColumnChart"
              :data="chartData"
              :options="chartOptions"/>
        </b-col>
      </b-row>

      <b-overlay :show="loading" rounded="sm">


        <b-row>
          <b-col cols="12" class="summary-header">
            <b-row>
              <b-col cols="8" md="6" style="margin: auto;">
                <div class="font-weight-bold">Summary Report</div>
              </b-col>
              <b-col cols="2" md="3" style="margin: auto;">
                <b-row>
                  <b-checkbox class="y-checkbox"/>
                  <span>Add Commissions</span>
                </b-row>
              </b-col>
              <b-col cols="2" md="3">
                <button @click="detailedReport" class="button-2">View Detailed Report</button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="summary-item">
          <b-col cols="3">
            <span class="">Gross Sales</span>
          </b-col>
          <b-col cols="6"></b-col>
          <b-col cols="3">
          <span class="summary-amount" v-if="getReports != null">
            UGX. {{Number(getReports.gross_sales).toLocaleString()}}
          </span>
          </b-col>
        </b-row>

        <b-row class="summary-item">
          <b-col cols="3">
            <span class="">Discounts</span>
          </b-col>
          <b-col cols="6"></b-col>
          <b-col cols="3">
          <span class="summary-amount" v-if="getReports != null">
            UGX. {{Number(getReports.discounts.total).toLocaleString()}}
          </span>
          </b-col>
        </b-row>

        <b-row class="summary-item-header">
          <b-col cols="3">
            <span class="h5 font-weight-bold">Net Sales</span>
          </b-col>
          <b-col cols="6"></b-col>
          <b-col cols="3">
          <span class="h5 font-weight-bold summary-amount" v-if="getReports != null">
            UGX. {{Number(getReports.net_sales).toLocaleString()}}
          </span>
          </b-col>
        </b-row>

        <b-row class="summary-item">
          <b-col cols="3">
            <span class="">Expenses</span>
          </b-col>
          <b-col cols="6"></b-col>
          <b-col cols="3">
          <span class="summary-amount" v-if="getReports != null">
            UGX. {{Number(getReports.expenses.total).toLocaleString()}}
          </span>
          </b-col>
        </b-row>

        <b-row class="summary-item">
          <b-col cols="3">
            <span class="">Commissions</span>
          </b-col>
          <b-col cols="6"></b-col>
          <b-col cols="3">
          <span class="summary-amount" v-if="getReports != null">
            UGX. {{Number(getReports.commissions.total).toLocaleString()}}
          </span>
          </b-col>
        </b-row>

        <b-row class="summary-item-header">
          <b-col cols="3">
            <span class="h5 font-weight-bold">Net Profit</span>
          </b-col>
          <b-col cols="6"></b-col>
          <b-col cols="3">
          <span class="h5 font-weight-bold summary-amount" v-if="getReports != null">
            UGX. {{(Number(getReports.net_profit).toLocaleString())}}
          </span>
          </b-col>
        </b-row>
      </b-overlay>

    </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import {mapActions, mapGetters} from "vuex";
import moment from "moment";
import router from "@/app/router/router";
import { GChart } from 'vue-google-charts'

export default {
  name: "SummaryReport",
  components:{DateRangePicker, GChart},
  data(){
    return{
      loading: true,
      filter_location:null,
      date_range:{
        startDate:null,
        endDate:null
      },
      showChart:false,
      chartData: [],
      chartOptions: {
        chart: {
          title: 'Business Performance',
          subtitle: 'Business Sales',
        },
        colors: ["#1d2124"],
        height:400,
      }
    }
  },
  mounted() {
    this.fetchLocations()
    this.fetchReports()
  },
  methods:{
    ...mapActions(["fetchLocations", "fetchReports", "fetchReportsFilter"]),
    formatDate(date){
      return moment(date).format("YYYY-MM-DD")
    },
    detailedReport(){
      router.push("/detailed-report")
    },
    exportData(){

    },
    filter(){
      let data = {
        date:{
          from:this.formatDate(this.date_range.startDate),
          to:this.formatDate(this.date_range.endDate)
        },
        location:this.filter_location
      }
      this.loading = true
      this.fetchReportsFilter(data)
    },
  },
  computed:{
    ...mapGetters([
        "getLocations", "getReports"
    ]),
    getDateRange(){
      return this.date_range
    },
    getFilterLocation(){
      return this.filter_location
    },
  },
  watch:{
    date_range: {
      handler(data){
        localStorage.setItem("date-range", JSON.stringify(data))
      },
      deep:true
    },
    getReports(data){
      this.loading = false
      localStorage.setItem("reports", JSON.stringify(data))

      if(data.chartData != null){
        this.showChart = true
        var chart = [
          ['Year', 'Sales'],
        ]
        for(let i = 0; i < data.chartData.length; i++){
          let entry = [data.chartData[i].date, data.chartData[i].total]
          chart.push(entry)
        }
        this.chartData = chart
      }else{
        this.showChart = false
      }
    },
    getDateRange(){
      this.filter()
    },
    getFilterLocation(){
      this.filter()
    },
  }
}
</script>

<style scoped>
@import "../../../../commons/css/main.css";

.reports{
  z-index: -5;
}

.select{
  width: 100%;
}

.button{
  width: 100%;
  background: #fff;
  font-size: 12px;
  padding: 8px;
  color: #1d2124;
  font-weight: bold;
  border: solid 2px #1d2124;
  border-radius: 6px;
}

.button-2{
  width: 100%;
  background: #fff;
  font-size: 12px;
  padding: 15px 6px;
  color: #1d2124;
  font-weight: bold;
  border: solid 2px #1d2124;
  border-radius: 6px;
}

.summary-header{
  width: 100%;
  background: #eaeaea;
  padding-bottom: 5px;
  padding-top: 5px;
  margin: 0;
}

.less{
  margin:0;
  padding: 0;
}

.line{
  margin-top: 20px;
  margin-bottom: 20px;
}

.bar{
  width: 100%;
  background: #a0a0a0;
  height: 2px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.summary-item{
  padding-top:15px;
  padding-bottom: 15px;
  border-bottom: solid 1px #1b1e21;
}

.summary-item-header{
  padding-top:15px;
  padding-bottom: 15px;
}

.summary-amount{
  float: right;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #c82333;
  background-color: #c82333;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(255, 48, 3, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ff88a3;
}
</style>