import axios from "axios";
import configs from "../../../../../app/configurations/configs.json";
//import qs from "querystring";

const IP = configs.SERVER_IP;

const state = {
  teams:[],
  response:{},
  error:{},
};

const getters = {
    getTeams: (state) => state.teams,
    getAddMemberResponse: (state) => state.response,
    getPermissionResponse: (state) => state.response,
    getAddMemberErrorResponse: (state) => state.error,
    getEditMemberResponse: (state) => state.response,
    getEditMemberErrorResponse: (state) => state.error,
};

const actions = {
  async fetchTeams({ commit }) {
    await axios
        .get(IP + "/teams/business/"+localStorage.getItem("business_id"))
        .then((response) => {
          commit("setTeams", response.data);
        })
        .catch((error) => {
          commit("setErrorResponse", error.response);
        });
  },
  async addTeamMember({ commit}, data) {
    await axios
       .post(IP + "/teams", data)
       .then((response) => {
           commit("setMember", response.data);
       })
       .catch((error) => {
           commit("setErrorResponse", error.response);
       });
  },
  async editTeamMember({ commit}, data) {
    await axios
      .put(IP + "/teams/"+data.id, data)
      .then((response) => {
          commit("setMember", response.data);
      })
      .catch((error) => {
          commit("setErrorResponse", error.response);
       });
  },
  async updatePermissions({ commit}, data) {
        await axios
            .put(IP + "/teams/"+data.id+"/update-permissions?"+data.params)
            .then((response) => {
                commit("setResponse", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
  },
};

const mutations = {
  setTeams: (state, response) => {
    state.teams = response.data;
  },
  setMember: (state, response) => {
    state.response = response.data;
  },
  setResponse: (state, response) => {
    state.response = response.data;
  },
  setErrorResponse: (state, response) => {
    state.error = response.data;
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
