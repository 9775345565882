<template>
  <div class="expenses">
    <b-row class="clearfix">
      <b-col cols="1" sm="1" md="1" lg="1"></b-col>
      <b-col cols="10" sm="10" md="10" lg="10">
        <b-row>
          <b-col cols="2" sm="4" md="4" lg="6" xl="6">
            <span class="font-weight-bold h3">Expenses</span>
          </b-col>
          <b-col cols="10" sm="8" md="8" lg="6" xl="6">
            <b-row>
              <b-col cols="5">
                <b-row style="width: 100%;">
                  <date-range-picker
                      class="select"
                      ref="picker"
                      :locale-data="{format: 'dd-mm-yyyy' }"
                      :minDate="null" :maxDate="null"
                      :singleDatePicker="false"
                      :timePicker="false"
                      :timePicker24Hour="false"
                      :showWeekNumbers="true"
                      :showDropdowns="true"
                      :autoApply="true"
                      v-model="date_range"
                      :linkedCalendars="true">
                    <template style="width: 100%;">
                                                        <span style="font-size: 11px;">
                                                            {{ date_range.startDate }} - {{ date_range.endDate}}
                                                        </span>
                    </template>
                  </date-range-picker>
                </b-row>
              </b-col>
              <b-col cols="4">
                <b-form>
                  <b-form-select v-model="filter_location" class="select">
                    <b-select-option value="null">All Locations</b-select-option>
                    <b-select-option v-for="(location, idx) in getLocations" :key="idx" :value="location.id">
                      {{location.location_name}} ({{location.address}})
                    </b-select-option>
                  </b-form-select>
                </b-form>
              </b-col>
              <b-col cols="3">
                <button @click="newExpense()" class="button">New Expenses</button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="clearfix line2">
          <b-col cols="6" >
            <b-row class="clearfix">
              <b-col>
                <b-row>
                  <b-col cols="12" class="align-content-center">
                    <span class="summary-amount">{{getExpenses.length}}</span>
                  </b-col>
                </b-row>
                <b-row >
                  <b-col>
                    <span class="summary-label">EXPENSES</span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="6" >
            <b-row class="clearfix">
              <b-col>
                <b-row>
                  <b-col cols="12" class="align-content-center">
                    <span class="summary-amount" style="float: right;">UGX. {{Number(totalExpenses).toLocaleString()}}</span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <span class="summary-label" style="float: right;">TOTAL EXPENSES</span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-overlay :show ="loading" rounded="sm">
          <b-row v-for="(expense, idx) in getExpenses" :key="idx">
            <b-col cols="12">
              <b-row class="list-header">
                <b-col cols="6" sm="4" md="4" lg="4">
                  <span class="text">{{getDateString(expense.date)}}</span>
                </b-col>
                <b-col cols="3" sm="4" md="4" lg="4" class="text-center">
                  <span class="text">Location</span>
                </b-col>
                <b-col cols="3" sm="4" md="4" lg="4" class="text-center">
                  <div class="total-money">
                    <span class="text">UGX. {{Number(expense.total).toLocaleString()}}</span>
                  </div>
                </b-col>
              </b-row>

              <b-row v-for="(data, idx) in expense.expenses" :key="idx"
                     class="list-item"
                     @click="show(data.id, data)"
                     v-b-toggle.sidebar>

                <b-col cols="6" col sm="4" md="4" lg="4" class="text-center">
                  <b-row class="text-center left-padding">
                    <b-col  cols="3" md="4">
                      <b-row>
                        <img v-if="data.status == 0" src="../../../../assets/ic_expense_deleted.png" class="y-icon"/>
                        <img v-else-if="data.payment_type == 'cash'" src="../../../../assets/ic_cash.png" class="y-icon"/>
                        <img v-else-if="data.payment_type == 'mobile money'" src="../../../../assets/ic_mobile_money.png" class="y-icon"/>
                        <img v-else-if="data.payment_type == 'card'" src="../../../../assets/ic_card.png" class="y-icon"/>

                        <div class="label">
                          <span class="text">{{getTime(data.created_at)}}</span>
                        </div>
                      </b-row>
                    </b-col>

                    <b-col  cols="8" md="8">
                      <div class="label float-left">
                        <span class="text">{{data.name}}</span>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col col cols="3" sm="4" md="4" lg="4" class="text-center">
                  <span class="text">{{data.location.location_name}} ({{data.location.address}})</span>
                </b-col>
                <b-col col cols="3" sm="4" md="4" lg="4" class="text-center">
                  <div class="total-money">
                    <span class="text">UGX. {{Number(data.amount).toLocaleString()}}</span>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row style="margin-top: 20px;">
            <b-col cols="1">
              <button class="button" @click="lazyLoadUp">Prev</button>
            </b-col>
            <b-col cols="1" class="text-center">
              <p class="pages">{{getExpensesMeta.current_page}}/{{getExpensesMeta.last_page}}</p>
            </b-col>
            <b-col cols="1">
              <button class="button" @click="lazyLoadDown">Next</button>
            </b-col>
            <b-col cols="9"></b-col>
          </b-row>
        </b-overlay>
      </b-col>
      <b-col cols="1" sm="1" md="1" lg="1"></b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from "moment"
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import router from "../../../../app/router/router";

export default {
  name: "Expenses",
  components:{DateRangePicker},
  data(){
    return{
      months:["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      months_short:["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      totalExpenses: 0,
      expense:null,
      loading: true,
      filter_location:null,
      selected_id:null,
      date_range:{
        startDate:null,
        endDate:null
      }
    }
  },
  mounted() {
    if(localStorage.getItem("token") == null){
      router.push("/")
    }else{
      this.fetchExpenses()
    }
    this.fetchLocations()
  },

  methods:{
    ...mapActions(["fetchExpenses", "fetchExpensesFilter", "fetchLocations", "fetchExpensesPaged"]),
    newExpense(){
      router.push("/new-expense")
    },
    filter(){
      var to = this.formatDate(this.date_range.endDate)
      var from = this.formatDate(this.date_range.startDate)
      let data = {
        date:{
          to: to,
          from: from,
        },
        location: this.filter_location
      }
      this.loading = true
      this.fetchExpensesFilter(data)
    },
    lazyLoadUp(){
      var to = this.formatDate(this.date_range.endDate)
      var from = this.formatDate(this.date_range.startDate)
      if(parseInt(this.getExpensesMeta.current_page) - 1 > 0){
        let data = {
          page:parseInt(this.getExpensesMeta.current_page) - 1,
          date:{
            to: to,
            from: from,
          },
          location: this.filter_location
        }
        this.loading = true
        this.fetchExpensesPaged(data)
      }
    },
    lazyLoadDown(){
      var to = this.formatDate(this.date_range.endDate)
      var from = this.formatDate(this.date_range.startDate)
      if(parseInt(this.getExpensesMeta.current_page) + 1 <= parseInt(this.getExpensesMeta.last_page)){
        let data = {
          page:parseInt(this.getExpensesMeta.current_page) + 1,
          date:{
            to: to,
            from: from,
          },
          location: this.filter_location
        }
        this.loading = true
        this.fetchExpensesPaged(data)
      }
    },
    getDateString(date){
      var sections = date.split("-")
      var d = sections[2].split("T")
      var month_idx = parseInt(sections[1]) - 1
      return d[0]+" "+this.months[month_idx]+", "+sections[0];
    },
    getTime(date){
      var sections = date.split("T")
      var sections2 = sections[1].split(".")
      var sections3 = sections[1].split(":")
      var total = parseInt(sections2[0].replaceAll(":", ""))
      if(total > 120000){
        return sections3[0]+":"+sections3[1] + " pm"
      }else{
        return sections3[0]+":"+sections3[1] + " am"
      }
    },
    getShortDateString(date){
      var sections = date.split("-")
      var month_idx = parseInt(sections[1]) - 1

      return sections[2]+" "+this.months[month_idx]+", "+sections[0];
    },
    formatDate(date){
      return moment(date).format("YYYY-MM-DD")
    },
    show(id, data){
      this.$root.$emit('open-details', data, 'expense')
      localStorage.setItem("expense", JSON.stringify(data))
    },
    closeSideMenu(){
      this.$refs.mySidebar.hide();
      this.selected_id = null
      this.expense = null
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
  computed:{
    ...mapGetters([
      "getExpenses", "getExpense", "getExpensesMeta",
      "getExpenseErrorResponse","getLocations",
    ]),
    getDateRange(){
      return this.date_range
    },
    getFilterLocation(){
      return this.filter_location
    },
  },
  watch:{
    getDateRange(){
      this.filter()
    },
    getFilterLocation(){
      this.filter()
    },
    getExpenses(data){
      this.totalExpenses = 0
      this.loading = false
      var i = 0
      for(i < 0; i < data.length; i++){
        this.totalExpenses += parseInt(data[i].total)
      }
    },
    getExpenseErrorResponse(){
      this.loading = false
    }
  }
}
</script>

<style scoped>
@import "../../../../commons/css/main.css";

.expenses{
  margin-top: 50px;
}

.select{
  width: 100%;
}

.button{
  width: 100%;
  background: #c82333;
  font-size: 12px;
  padding: 8px;
  color: white;
  border: solid 1px #c82333;
  border-radius: 3px;
}

.y-icon{
  width: 22px;
  height: 22px;
  margin-right: 5px;
}

.list-header{
  width: 100%;
  background: #eaeaea;
  padding-bottom: 15px;
  padding-top: 15px;
  margin: 0;
}

.list-header span{
  font-weight: bold;
}

.list-item{
  width: 100%;
  background: white;
  padding-bottom: 15px;
  padding-top: 15px;
  margin: 0;
  border-bottom: solid 1px #f1f1f1;
}

.list-item:hover{
  background: #dddddd;
}

.total-money{
  float: right;
}

.left-padding{
  margin-left: 5px;
}

.line2{
  margin-top: 40px;
  margin-bottom: 20px;
}

.summary-amount{
  color: #1b1e21;
  font-size: 26px;
  font-weight: bold;
}

.summary-label{
  color: #9c9c9c;
  font-size: 16px;
  font-weight: bold;
}

</style>