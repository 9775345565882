<template>
    <div class="category">
        <b-row class="header">
          <b-col cols="1">
            <a class="navbar-brand ml-3">
              <img @click="back()" src="../../../../../assets/ic_cancel.png" class="y-icon-header">
            </a>
          </b-col>

          <b-col cols="9" style="margin: auto;">
            <div class="flex-grow-1 d-md-flex align-items-center justify-content-center d-none">
              <h1 class="h5 text-center title">Create Category</h1>
            </div>
          </b-col>

          <b-col cols="2" style="margin: auto;">
            <button @click="save()" class="button">Save</button>
          </b-col>
        </b-row>
        <section class="content h-100 w-100 d-flex justify-content-center align-items-center flex-column mb-5">
            <b-col cols="5" class="mx-auto">
                <div class="mt-5  d-none d-md-block">
                    <b-overlay :show="loading" rounded="sm">
                        <table class="table w-100 font-weight-bold table-bordered">
                            <tbody>
                            <tr>
                                <th class="bg-light">Category name</th>
                                <td class="font-weight-normal">
                                    <b-form-input
                                            class="input"
                                            v-model="form.name"
                                            type="text"
                                            placeholder="Name"/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </b-overlay>
                </div>
            </b-col>
        </section>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    export default {
        name: "NewCategory",
        data(){
            return{
                form:{
                    name:null,
                    business_id: localStorage.getItem("business_id")
                },
                loading:false,
            }
        },
        methods:{
            ...mapActions(["addCategory"]),
            onSearch(){

            },
            back(){
                window.history.back();
            },
            save(){
              this.loading = true
              this.addCategory(this.form)
            },
        },
        computed:{
            ...mapGetters([
                "getActionResponse",
            ]),
        },
        watch:{
          getActionResponse(){
            this.loading = false
            window.history.back();
          }
        }
    }
</script>

<style scoped>
    @import "../../../../../commons/css/main.css";

    .category{

    }

    .header{
        /*margin: 0;*/
        /*width: 100%;*/
        /*box-shadow: 0px 5px 3px #dedede;*/
        padding: 0;
        margin: 0;
        width: 100%;
        height: 85px;
        box-shadow: 0px 5px 3px #dedede;
    }

    .content{
        margin-top: 220px;
    }

    .y-icon-header{
        padding: 5px;
        width: 38px;
        height: 38px;
        margin: 16px 10px 10px 10px
    }

    .y-icon-header:hover{
        padding: 6px;
        background: #f3f3f3;
        border-radius: 6px;
    }

    .title{
        font-size: 22px;
        margin-top: 15px;
        font-weight: bold;
        margin-left: 10px;
    }

    .input{
        width: 100%;
        font-size: 16px;
        justify-content: left;
        text-align: left;
    }

    .active{
        background: #db0000;
        color: white;
        padding: 5px;
        border: 0;
        border-radius: 3px;
    }

    .button{
      width: 60%;
      background: #c82333;
      font-size: 14px;
      padding: 10px;
      color: white;
      border: solid 1px #c82333;
      border-radius: 3px;
    }

    .button:hover{
      background: #721c24;
    }
</style>