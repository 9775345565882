<template>
  <div class="customers">
    <b-row>
      <b-modal id="edit_card" centered title="Edit Card Number" hide-footer>
        <b-container fluid>
          <b-row>
            <b-col>
              <table class="table font-weight-bold table-bordered">
                <tbody>
                <tr>
                  <th class="bg-light">Card Number</th>
                  <td class="font-weight-normal">
                    <b-form-input
                        class="m-input"
                        v-model="form.customer_card_no"
                        type="text"
                        placeholder="Number"/>
                  </td>
                </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="8"></b-col>
            <b-col cols="4">
              <button class="button" @click="updateCard">Save</button>
            </b-col>
          </b-row>
        </b-container>
      </b-modal>
    </b-row>
    <b-row class="clearfix">
      <b-col cols="1" sm="1" md="1" lg="1"></b-col>
      <b-col cols="10" sm="10" md="10" lg="10">
        <b-row>
          <b-col cols="2" sm="4" md="4" lg="6" xl="6">
            <span class="font-weight-bold h3">Customers</span>
          </b-col>
        </b-row>

        <b-row class="clearfix">
          <b-col>
            <span class="desc">The directory is where you can view & manage your customers and all their activity at your business.</span>
          </b-col>
        </b-row>

        <b-row class="clearfix line">
          <b-col cols="4">
            <div class="input-group input">
                <b-form-input type="text" class="form-control border-0" placeholder="Search here..."
                          aria-label="Recipient's username" v-model="search" aria-describedby="basic-addon2"/>
            </div>
          </b-col>
        </b-row>

        <b-row class="list-header">
          <b-col cols="3">
            <span class="font-weight-bold text">Name</span>
          </b-col>
          <b-col cols="2">
            <span class="font-weight-bold text">Phone</span>
          </b-col>
          <b-col cols="2">
            <span class="font-weight-bold text">Current Points</span>
          </b-col>
          <b-col cols="3">
            <span class="font-weight-bold text">Address</span>
          </b-col>
          <b-col cols="2">
            <span class="font-weight-bold text">Last Visited</span>
          </b-col>
        </b-row>
        <b-overlay :show ="loading" rounded="sm">
          <b-row v-for="(customer, idx) in getCustomers"
                 :key="idx" class="list-item"
                 @click="show(customer)">
            <b-col cols="3">
              <span class="text font-weight-bold">{{customer.name}}</span>
            </b-col>
            <b-col cols="2">
              <span class="text">{{customer.phone_number}}</span>
            </b-col>
            <b-col cols="2">
              <span class="text">{{customer.current_points}}</span>
            </b-col>
            <b-col cols="3">
              <span class="text">{{ customer.address}}</span>
            </b-col>
            <b-col cols="2">
              <span class="text">{{lastVisit(customer.visits)}}</span>
            </b-col>
          </b-row>
          <b-row v-if="getCustomerPaginator" style="margin-top: 20px;">
            <b-col cols="1">
              <button class="button" @click="prevPage">Prev</button>
            </b-col>
            <b-col cols="1" class="text-center">
              <p class="pages">{{getCustomerPaginator.current_page}} / {{getCustomerPaginator.last_page}}</p>
            </b-col>
            <b-col cols="1">
              <button class="button" @click="nextPage">Next</button>
            </b-col>
            <b-col cols="9"></b-col>
          </b-row>
        </b-overlay>
      </b-col>
      <b-col cols="1" sm="1" md="1" lg="1"></b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import router from "../../../../app/router/router";
import moment from "moment";

export default {
  name: "Customers",
  data(){
    return{
      months:["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      months_short:["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      loading: true,
      search:null,
      selectedData:null,
      selectedId:null,
      form:{
        customer_id: null,
        customer_card_no: null,
        business_id: null,
        current_points: null
      },
    }
  },
  mounted() {
    if(localStorage.getItem("token") == null){
      router.push("/")
    }else{
      this.fetchCustomers()
    }
    this.fetchCustomers()
  },
  methods:{
    ...mapActions(["fetchCustomers", "fetchCustomersPaged", "filterCustomers", "editCard"]),
    show(data){
      this.$root.$emit('open-details', data, 'customer')
      this.selectedData = data
      this.selectedId = data.id
      if(data.details != null){
        this.form.customer_card_no = data.details.customer_card_no
      }
    },
    lastVisit(visits){
      try{
        let dt = visits[visits.length - 1].created_at
        var today = moment()
        var event = moment(dt)
        return today.diff(event, "days") +" days ago"
      }catch (e){
        return "Not Visited"
      }
    },
    updateCard(){
      this.form.customer_id = this.selectedData.id
      this.form.business_id = localStorage.getItem("business_id"),
      this.form.current_points = this.selectedData.current_points
      this.loading = true
      this.editCard(this.form)
      this.$bvModal.hide("edit_card")
    },
    prevPage(){
      if(parseInt(this.getCustomerPaginator.current_page) - 1 > 0){
        let data = {
          page:parseInt(this.getCustomerPaginator.current_page) - 1
        }
        this.loading = true
        this.fetchCustomersPaged(data)
      }
    },
    nextPage(){
      if(parseInt(this.getCustomerPaginator.current_page) + 1 <= parseInt(this.getCustomerPaginator.last_page)){
        let data = {
          page:parseInt(this.getCustomerPaginator.current_page) + 1
        }
        this.loading = true
        this.fetchCustomersPaged(data)
      }
    }
  },
  computed:{
    ...mapGetters(["getCustomers", "getCustomerPaginator", "getCustomersSuccessResponse", "getCustomersErrorResponse"]),
    getSearchText(){
      return this.search
    }
  },
  watch:{
    getSearchText(text){
      if(text.length > 3){
        this.loading = true
        this.filterCustomers(text)
      }
    },
    getCustomers(data){
      this.loading = false
      if(this.selectedId != null){
        for (let  i = 0; i < data.length; i ++){
          if(data[i].id == this.selectedId){
            this.show(data[i])
            break;
          }
        }
      }
    },
    getExpenseErrorResponse(){
      this.loading = false
    },
    getCustomersSuccessResponse(){
      this.loading = true
      this.fetchCustomers()
    },
    getCustomersErrorResponse(){
      this.loading = false
    }
  }
}
</script>

<style scoped>
@import "../../../../commons/css/main.css";

.customers{
  margin-top: 50px;
}

.list-header{
  width: 100%;
  background: #eaeaea;
  padding-bottom: 10px;
  padding-top: 10px;
  margin: 0;
}

.list-header span{
  font-weight: bold;
}

.list-item{
  width: 100%;
  background: white;
  padding-bottom: 15px;
  padding-top: 15px;
  margin: 0;
  border-bottom: solid 1px #979696;
}

.list-item:hover{
  background: #dddddd;
}

.text{
  font-size: 14px;
}

.pages{
  font-size: 16px;
  margin-top: 5px;
}

.desc{
  font-size: 14px;
}

.line{
  margin-top: 10px;
  margin-bottom: 20px;
}

.input{
  border: solid 2px #707070;
  border-radius: 6px;
}

th{
  font-size: 14px;
}

.m-input{
  font-size: 14px;
}

.button{
  width: 100%;
  background: #c82333;
  font-size: 12px;
  padding: 5px;
  color: white;
  border: solid 1px #c82333;
  border-radius: 3px;
}

.button:hover{
  background: #a80312;
}
</style>