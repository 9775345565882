<template>
    <div>
      <b-row>
        <b-col>
          <span class="font-weight-bold title">Tell us about yourself</span>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <b-form>
            <div class="form-group">
              <label class="font-weight-bold form-label">Enter your email</label>
              <b-form-input id="email" type="text"
                            v-model="form.email"
                            class="form-control "
                            placeholder="you@example.com"
                            name="email" value="" required/>
            </div>
            <div class="form-group">
              <label class="font-weight-bold form-label">Your name</label>
              <b-form-input id="name" type="text"
                            v-model="form.firstname" class="form-control"
                            placeholder="First Name"
                            name="name" required/>
            </div>
            <div class="form-group">
              <label class="font-weight-bold form-label">Create Passcode PIN</label>
              <b-form-input id="password" type="password"
                            v-model="form.pin" class="form-control"
                            placeholder="************"
                            name="password" required/>
            </div>
          </b-form>
        </b-col>

        <b-col cols="6">
          <b-form>
            <div class="form-group">
              <label class="font-weight-bold form-label">Your phone number</label>
              <b-form-input id="phone" type="text"
                            v-model="form.phone_number"
                            class="form-control "
                            placeholder="Phone"
                            name="phone" value="" required/>
            </div>
            <div class="form-group">
              <label class="font-weight-bold form-label">Last Name</label>
              <b-form-input id="password" type="text"
                            v-model="form.lastname" class="form-control"
                            placeholder="Last Name"
                            name="password" required/>
            </div>
            <div class="form-group">
              <label class="font-weight-bold form-label">Country</label>
              <b-form-select id="password" type="text"
                             v-model="form.country_id" class="form-control" required>
                <b-select-option value="null">Country</b-select-option>
                <b-select-option
                    v-for="(country, idx) in getCountries"
                    :key="idx"
                    :value="country.id" >{{country.name}}</b-select-option>
              </b-form-select>
            </div>
          </b-form>
        </b-col>
      </b-row>

    </div>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";
    export default {
        name: "YourInformation",
        data(){
          return {
            form:{
              phone_number:null,
              firstname:null,
              lastname:null,
              country_id:null,
              email:null,
              pin:null,
            },
          }
        },
        mounted(){
          this.fetchCountries()
        },
        methods:{
          ...mapActions(["fetchCountries"]),
        },
        computed: {
          ...mapGetters([
             "getCountries",
          ]),
        },
        watch: {
          form:{
            handler(data){
              this.$emit("yourInfoData", data)
            },
            deep:true
          },
        }
    }
</script>

<style scoped>
    @import "../../commons/css/main.css";

    .form-label{
      font-size: 12px;
    }

    .title{
      font-size: 35px;
      font-weight: bold;
    }

    @media only screen and (max-width: 570px){
      .form-label{
        font-size: 12px;
      }
    }

    @media only screen and (max-width: 380px){
      .form-label{
        font-size: 11px;
      }

      .title{
        font-size: 22px;
      }
      .form-control{
        font-size: 12px;
        padding: 5px;
      }

    }
</style>