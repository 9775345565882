<template>
  <b-row class="header">
    <b-col cols="2">
      <b-row>
        <img src="../../../assets/ic_menu.svg" class="y-menu-icon" @click="openMenu"/>
        <div style="margin-top: auto; margin-bottom: auto;">
          <span class="section-title">Menu</span>
        </div>
      </b-row>
    </b-col>
    <b-col cols="6">
      <div class="text-center align-content-center flex-grow-1 justify-content-center">
        <img src="../../../assets/logo_small_size.png" class="y-logo"/>
      </div>
    </b-col>
    <b-col cols="4" style="margin-top: auto; margin-bottom: auto;">
      <b-row>
        <b-col cols="3" style="margin-top: auto; margin-bottom: auto;">
          <b-row>
            <img src="../../../assets/ic_user_guide.png" class="dp-logout">
            <div class="business-name font-weight-bold">User Guide</div>
          </b-row>
        </b-col>
        <b-col cols="3" class="account">
          <b-row @click="logout" style="margin-top: 5px; margin-left: 10px;">
            <img src="../../../assets/ic_logout.svg" class="dp-logout">
            <div class="business-name font-weight-bold">Sign In</div>
          </b-row>
        </b-col>
        <b-col cols="6" class="account">
          <b-row>
            <img src="../../../assets/ic_user.png" class="dp">
            <div class="business-name font-weight-bold">{{name}}</div>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import router from "@/app/router/router";
export default {
  name: "DashboardHeader",
  data(){
    return{
      name: JSON.parse(localStorage.getItem("business")).name,
    }
  },
  methods:{
    openMenu(){
      this.$root.$emit('open-menu')
    },
    logout(){
      localStorage.clear()
      router.push("/")
    }
  }
}
</script>

<style scoped>

@import "../../../commons/css/main.css";
.header{
  padding: 0;
  margin: 0;
  width: 100%;
  z-index: 40;
  background: white;
  position: fixed;
  box-shadow: 0px 5px 3px #dedede;
}

.y-menu-icon{
  padding: 5px;
  width: 40px;
  margin: 20px 10px 20px 20px;
}

.y-menu-icon:hover{
  padding: 6px;
  background: #f3f3f3;
  border-radius: 6px;
}

.y-logo{
  padding: 5px;
  margin-top: 20px;
}

.dp-logout{
  padding: 5px;
  width: 32px;
}

.dp{
  padding: 5px;
  width: 40px;
}

.section-title{
  font-size: 18px;
  font-weight: bold;
}

.business-name{
  font-size: 12px;
  margin-top: auto;
  margin-bottom: auto;
  color: #707070;
  opacity: 1;
}

.account{
  border-left: solid 2px #9c9c9c;
}
</style>