<template>
    <div class="transactions">
      <b-row>
        <b-col cols="1" sm="1" md="1" lg="1"></b-col>
        <b-col cols="10" sm="10" md="10" lg="10">
          <b-row class="line">
            <b-col cols="2" sm="4" md="4" lg="6" xl="6">
              <span class="font-weight-bold h3">Transactions</span>
            </b-col>
            <b-col cols="10" sm="8" md="8" lg="6" xl="6">
              <b-row>
                <b-col cols="6">
                  <b-row style="width: 100%;">
                    <date-range-picker
                        class="select"
                        ref="picker"
                        :locale-data="{format: 'dd-mm-yyyy' }"
                        :minDate="null" :maxDate="null"
                        :singleDatePicker="false"
                        :timePicker="false"
                        :timePicker24Hour="false"
                        :showWeekNumbers="true"
                        :showDropdowns="true"
                        :autoApply="true"
                        v-model="date_range"
                        :linkedCalendars="true">
                      <template style="width: 100%;">
                    <span style="font-size: 11px;">
                       {{ date_range.startDate }} - {{ date_range.endDate}}
                    </span>
                      </template>
                    </date-range-picker>
                  </b-row>
                </b-col>
                <b-col cols="6">
                  <b-form>
                    <b-form-select v-model="filter_location" class="select">
                      <b-select-option value="null">All Locations</b-select-option>
                      <b-select-option v-for="(location, idx) in getLocations" :key="idx" :value="location.id">
                        {{location.location_name}} ({{location.address}})
                      </b-select-option>
                    </b-form-select>
                  </b-form>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-overlay :show="loading" rounded="sm">
            <b-row>
              <b-col cols="12">
                <div  class="flex-grow-1">
                  <div class="list-group" v-for="(transaction, idx) in getTransactions" :key="idx">
                    <b-row class="separator">
                      <b-col cols="3">
                       <span class="list-header-label">
                          {{getDateString(transaction.date)}}
                       </span>
                      </b-col>
                      <b-col cols="3">
                        <div style="float: right;">
                      <span class="list-header-label">
                         Name
                      </span>
                        </div>
                      </b-col>
                      <b-col cols="3">
                        <div style="float: right;">
                      <span class="list-header-label">
                         Location
                      </span>
                        </div>
                      </b-col>
                      <b-col cols="3">
                        <div style="float: right;">
                          <span class="list-header-label">
                            Amount
    <!--                         UGX {{Number(transaction.total).toLocaleString()}}-->
                          </span>
                        </div>
                      </b-col>
                    </b-row>

                    <b-row :class="[txn.id == selected_id ? 'y-list-group-item-active' : 'y-list-group-item']"
                           v-for="(txn, idx) in transaction.transactions"
                           :key="idx"
                           @click="show(txn)"
                           v-b-toggle.sidebar>
                      <b-col cols="3">
                        <b-row>
                          <b-col cols="2">
                            <img v-if="txn.refund_status" class="refund-icon">
                            <img v-else-if="txn.payment_type == 'check-in'" class="checkin-icon">
                            <img v-else-if="txn.payment_type == 'cash'" class="cash-icon">
                            <img v-else-if="txn.payment_type == 'mobile_money'" class="momo-icon">
                            <img v-else-if="txn.payment_type == 'card'" class="card-icon">
                            <img v-else-if="txn.payment_type == 'redeem'" class="redeem-icon">
                          </b-col>
                          <b-col cols="4">
                            <span class="text">
                              {{getTime(txn.created_at)}}
                            </span>
                          </b-col>
                          <b-col cols="6">
                            <span class="text">
                              {{txn.details[0].item_name}}
                            </span>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col cols="3">
                        <div style="float: right;">
                          <span class="text">
                            {{txn.customer.name}}
                          </span>
                        </div>
                      </b-col>
                      <b-col cols="3">
                        <div style="float: right;">
                          <span class="text">
                            {{txn.location.location_name}} ({{txn.location.address}})
                          </span>
                        </div>
                      </b-col>
                      <b-col cols="3">
                        <div style="float: right;">
                          <span class="text">
                             <p class="mb-0" v-if="txn.payment_type == 'redeem'">{{Number(txn.total_cash).toLocaleString()}} Points</p>
                             <p class="mb-0" v-else>UGX {{Number(txn.total_cash).toLocaleString()}}</p>
                          </span>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-col>
            </b-row>

            <b-row style="margin-top: 20px;">
              <b-col cols="1">
                <button class="button" @click="lazyLoadUp">Prev</button>
              </b-col>
              <b-col cols="1" class="text-center">
                <p class="pages">{{getTransactionsMeta.current_page}}/{{getTransactionsMeta.last_page}}</p>
              </b-col>
              <b-col cols="1">
                <button class="button" @click="lazyLoadDown">Next</button>
              </b-col>
              <b-col cols="9"></b-col>
            </b-row>


          </b-overlay>
        </b-col>
        <b-col cols="1" sm="1" md="1" lg="1"></b-col>
      </b-row>
    </div>
</template>

<script>
import router from "../../../../app/router/router"
import {mapActions, mapGetters} from "vuex";
import moment from "moment"
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  name: "Transactions",
  components:{DateRangePicker},
  data(){
    return{
      search_text:null,
      months:["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      receipt: null,
      total: null,
      total_collected: 0,
      products:[],
      visible:false,
      transaction:null,
      selected_id:null,
      loading: true,
      filter_location:null,
      showMenu: false,
      date_range:{
        startDate:null,
        endDate:null
      }
    }
  },
  created() {
    if(localStorage.getItem("token") == null){
      router.push("/")
    }else{
      this.fetchTransactions()
      this.fetchLocations()
    }
  },
  methods:{
    ...mapActions(["fetchTransactions", "fetchTransactionsFilter", "fetchLocations", "fetchTransactionsPaged"]),
    filter(){
      var to = this.formatDate(this.date_range.endDate)
      var from = this.formatDate(this.date_range.startDate)
      let data = {
        date:{
          to: to,
          from: from,
        },
        location: this.filter_location
      }
      this.loading = true
      this.fetchTransactionsFilter(data)
    },
    lazyLoadUp(){
      var to = this.formatDate(this.date_range.endDate)
      var from = this.formatDate(this.date_range.startDate)
      if(parseInt(this.getTransactionsMeta.current_page) - 1 > 0){
        let data = {
          page:parseInt(this.getTransactionsMeta.current_page) - 1,
          date:{
            to: to,
            from: from,
          },
          location: this.filter_location
        }
        this.loading = true
        this.fetchTransactionsPaged(data)
      }
    },
    lazyLoadDown(){
      var to = this.formatDate(this.date_range.endDate)
      var from = this.formatDate(this.date_range.startDate)
      if(parseInt(this.getTransactionsMeta.current_page) + 1 <= parseInt(this.getTransactionsMeta.last_page)){
        let data = {
          page:parseInt(this.getTransactionsMeta.current_page) + 1,
          date:{
            to: to,
            from: from,
          },
          location: this.filter_location
        }
        this.loading = true
        this.fetchTransactionsPaged(data)
      }
    },
    getDateString(date){
      var sections = date.split("-")
      var d = sections[2].split("T")
      var month_idx = parseInt(sections[1]) - 1
      return d[0]+" "+this.months[month_idx]+", "+sections[0];
    },
    getTime(date){
      var sections = date.split("T")
      var sections2 = sections[1].split(".")
      var sections3 = sections[1].split(":")
      var total = parseInt(sections2[0].replaceAll(":", ""))
      if(total > 120000){
        return sections3[0]+":"+sections3[1] + " pm"
      }else{
        return sections3[0]+":"+sections3[1] + " am"
      }
    },
    formatDate(date){
      console.log(date)
      if(date != null){
        return moment(date).format("YYYY-MM-DD")
      }
      return null
    },
    show(data){
      this.$root.$emit('open-details', data, 'transaction')
    },
    cleanText(text){
      if(text.includes("_")){
        return this.capitalizeFirstLetter(text.replaceAll("_", " "))
      }
      return this.capitalizeFirstLetter(text);
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
  computed:{
    ...mapGetters([
      "getTransactions", "getTransactionErrorResponse", "getLocations", "getTransactionsMeta"
    ]),
    getDateRange(){
      return this.date_range
    },
    getFilterLocation(){
      return this.filter_location
    },
  },
  watch:{
    getDateRange(){
      this.filter()
    },
    getFilterLocation(){
      this.filter()
    },
    stretchText(){
      this.onSearch()
    },
    getTransactions(data){
      this.total_collected = 0
      this.visible = true
      this.loading = false
      for(let i = 0; i < data.length; i++){
        this.total_collected += parseInt(data[i].total)
      }
    }
  }
}

</script>

<style scoped>
@import "../../../../commons/css/main.css";

.transactions{

}

.select{
  width: 100%;
}

.line{
  margin-top: 50px;
  margin-bottom: 20px;
}

.line2{
  margin-top: 40px;
  margin-bottom: 20px;
}

.summary-amount{
  color: #1b1e21;
  font-size: 26px;
  font-weight: bold;
}

.summary-label{
  color: #9c9c9c;
  font-size: 16px;
  font-weight: bold;
}

.list-header-label{
  font-weight: bold;
}

.text{
  margin-top: 5px;
  font-size: 14px;
}

.text-details{
  margin-top: 5px;
  font-size: 14px;
}

.pages{
  font-size: 16px;
  margin-top: 5px;
}

.qty{
  margin-top: 5px;
  margin-left: 5px;
  font-size: 14px;
}

.details{
  margin: 120px 10px 10px 10px;
}

.details-body{
  margin-top: 20px;
}

.details-footer{
  margin-top: 10px;
}

.y-list-group-item{
  padding: 15px 10px;
  border-bottom: solid 1px #bfbfbf;
  margin: 0;
  width: 100%;
}

.y-list-group-item-active{
  padding: 15px 10px;
  background: #b42c38;
  color: white;
  margin: 0;
  width: 100%;
}

.y-list-group-item:hover{
  background: #db3644;
  color: white;
}

.separator{
  background: #ededed;
  padding: 15px 10px;
  margin: 0;
  width: 100%;
}

.y-btn-outline-dark {
  width: 95%;
  color: #343a40;
  font-size: 12px;
  padding: 10px 25px;
  border-radius: 6px;
  background-color: transparent;
  background-image: none;
  border: solid 2px #d0d0d0;
}

.y-btn-footer {
  color: #343a40;
  font-size: 12px;
  padding: 10px 25px;
  border-radius: 6px;
  background-color: transparent;
  background-image: none;
  border: solid 2px #d0d0d0;
}

.y-btn-outline-dark:hover {
  color: #fff;
  background-color: #c6c6c6;
  border-color: #c6c6c6;
}

.details-card{
  border: solid 2px #d0d0d0;
  border-radius: 6px;
  padding: 30px;
  width: 100%;
}

.bar{
  width: 100%;
  background: #a0a0a0;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.bar2{
  width: 100%;
  background: #a0a0a0;
  height: 1px;
  margin-top: 60px;
  margin-bottom: 10px;
}

.less{
  margin:0;
  padding: 0;
}

.button{
  width: 100%;
  background: #c82333;
  font-size: 12px;
  padding: 5px;
  color: white;
  border: solid 1px #c82333;
  border-radius: 3px;
}

.button:hover{
  background: #a80312;
}

.card-icon{
  width: 32px;
  height: 32px;
  content: url("../../../../assets/ic_card.png");
}

.momo-icon{
  width: 32px;
  height: 32px;
  content: url("../../../../assets/ic_mobile_money.png");
}

.cash-icon{
  width: 32px;
  height: 32px;
  content: url("../../../../assets/ic_cash.png");
}

.refund-icon{
  width: 32px;
  height: 32px;
  content: url("../../../../assets/ic_refund.png");
}

.checkin-icon{
  width: 32px;
  height: 32px;
  content: url("../../../../assets/ic_checkin_member.png");
}

.redeem-icon{
  width: 32px;
  height: 32px;
  content: url("../../../../assets/ic_redeem.svg");
}

.y-list-group-item:hover .refund-icon{
  content: url("../../../../assets/ic_refund_white.png");
}

.y-list-group-item:hover .cash-icon{
  content: url("../../../../assets/ic_cash_active.png");
}

.y-list-group-item:hover .momo-icon{
  content: url("../../../../assets/ic_mobile_money_white.png");
}

.y-list-group-item:hover .card-icon{
  content: url("../../../../assets/ic_card_white.png");
}

.y-list-group-item:hover .checkin-icon{
  content: url("../../../../assets/ic_checkin_member_white.png");
}

.y-list-group-item:hover .redeem-icon{
  content: url("../../../../assets/ic_redeem_white.svg");
}

.y-list-group-item-active .cash-icon{
  content: url("../../../../assets/ic_cash_active.png");
}

.y-list-group-item-active .card-icon{
  content: url("../../../../assets/ic_card_white.png");
}

.y-list-group-item-active .momo-icon{
  content: url("../../../../assets/ic_mobile_money_white.png");
}

.y-list-group-item-active .refund-icon{
  content: url("../../../../assets/ic_refund_white.png");
}

.y-list-group-item-active .checkin-icon{
  content: url("../../../../assets/ic_checkin_member_white.png");
}

.y-list-group-item-active .redeem-icon{
  content: url("../../../../assets/ic_redeem_white.svg");
}

</style>