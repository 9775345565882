import Vue from "vue";
import App from "./App.vue";
import router from "./app/router/router";
import store from "./app/store/store";
import { LayoutPlugin, DropdownPlugin, TablePlugin, ModalPlugin, CardPlugin, VBScrollspyPlugin} from 'bootstrap-vue'
import Bootstrap from 'bootstrap-vue'
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import axios from "axios";

Vue.use(Bootstrap)
Vue.use(DropdownPlugin)
Vue.use(TablePlugin)
Vue.use(VBScrollspyPlugin)
Vue.use(CardPlugin)
Vue.use(ModalPlugin)
Vue.use(LayoutPlugin)
Vue.use(axios);

const token = localStorage.getItem("token");
if (token != null) {
  axios.defaults.headers.common = {
    "Authorization": `Bearer ${token}`,
  };
}else{
  axios.defaults.headers.common = {
    "Authorization": `x`,
  };
}

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
