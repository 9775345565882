<template>
  <div>
    <b-row class="header">
      <b-col cols="1">
        <a class="navbar-brand ml-3">
          <img @click="back()" src="../../../../assets/ic_cancel.png" class="y-icon-header">
        </a>
      </b-col>

      <b-col cols="9" style="margin: auto;">
        <div class="flex-grow-1 d-md-flex align-items-center justify-content-center d-none">
          <h1 class="h5 text-center title">Member's Permissions</h1>
        </div>
      </b-col>

      <b-col cols="2" style="margin: auto;">
        <button @click="save()" class="button">Save</button>
      </b-col>
    </b-row>
    <section class="content h-100 w-100 d-flex justify-content-center align-items-center flex-column mb-5">
      <b-col cols="8" class="mx-auto" style="margin-top: 50px;">

        <b-row>
          <b-col cols="2" sm="4" md="4" lg="6" xl="6">
            <span class="font-weight-bold h4">Customize this set of permissions</span>
          </b-col>
        </b-row>

        <b-row class="clearfix">
          <b-col>
            <span class="desc">Customize the permissions below to tailor the way team members with this permission set use Square.</span>
          </b-col>
        </b-row>

        <b-row style="margin-top: 50px;">
          <b-col cols="3">
            <b-row v-for="(data, idx) in permissions" :key="idx"
                class="permission-pill"
                @click="select(data, idx)">
              <b-col cols="1" style="padding: 0px;" >
                <div style="width: 30px; height: 30px; border-radius: 3px; background: #1b1e21;"></div>
              </b-col>
              <b-col cols="9" style="margin: auto;">
                <span class="font-weight-bold" style="margin-left: 10px;">{{capitalize(data.permission)}}</span>
              </b-col>
              <b-col cols="2" style="padding: 0px; margin: auto;">
                <span v-if="data.status"  style="color: #459005; font-weight: bold; font-size: 11px;">On</span>
                <span v-else style="color: #939393; font-weight: bold; font-size: 11px;">Off</span>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="1"></b-col>
          <b-col cols="8">
            <b-row v-if="selectedPill != null">
              <b-col cols="8" class="permission-card">
                <b-row style="margin-top: 15px;">
                  <b-col cols="1"></b-col>
                  <b-col cols="8">
                    <span class="font-weight-bold">{{capitalize(selectedPill.permission)}}</span>
                  </b-col>
                  <b-col cols="2">
                    <span class="floater" >
                      <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input"
                               :id="selectedId"
                               :checked="selectedPill.status"
                               v-on:input="onPermissionChanged(!selectedPill.status)">
                        <label class="custom-control-label" :for="selectedId"></label>
                      </div>
                    </span>
                  </b-col>
                  <b-col cols="1"></b-col>
                </b-row>
                <b-row style="padding: 20px;">
                  <b-col cols="1"></b-col>
                  <b-col cols="10" style="background: #c4c4c4; height: 1px;"></b-col>
                  <b-col cols="1"></b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

      </b-col>
    </section>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
export default {
  name: "Permissions",
  data(){
    return{
      id:null,
      selectedPill: null,
      selectedId:0,
      permissions: JSON.parse(localStorage.getItem("permissions"))
    }
  },
  mounted(){
    this.id = window.location.href.split("/")[4]
  },
  methods:{
    ...mapActions(["updatePermissions"]),
    back(){
      window.history.back();
    },
    save(){
      let data = {
        id:this.id,
        params:""
      }
      for(let i = 0; i<this.permissions.length; i++){
        var status = "0"
        if(this.permissions[i].status){
          status = "1"
        }

        if(i < this.permissions.length-1){
          data.params += this.permissions[i].permission +"="+status+"&"
        }else{
          data.params += this.permissions[i].permission +"="+status
        }
      }

      this.updatePermissions(data)

    },
    select(data, id){
      this.selectedPill = data
      this.selectedId = id
    },
    onPermissionChanged(status){
      this.selectedPill.status = status
    },
    capitalize(string) {
      if(string != null){
        var cleanedString = string.replaceAll("_", " ")
        return cleanedString.charAt(0).toUpperCase() + cleanedString.slice(1);
      }
      return ""
    }
  },
  computed:{
    ...mapGetters([
      "getPermissionResponse"
    ]),
    // permissionChanged(){
    //   return this.selectedPill.status
    // }
  },
  watch:{
    getPermissionResponse(){
      this.back()
    },
    // permissionChanged(status){
    //   this.onPermissionChanged(status, this.id)
    // }
  }
}
</script>

<style scoped>

.header{
  padding: 0;
  margin: 0;
  width: 100%;
  height: 85px;
  box-shadow: 0px 5px 3px #dedede;
}

.content{
  margin-top: 20px;
}

.y-icon-header{
  padding: 5px;
  width: 38px;
  height: 38px;
  margin: 16px 10px 10px 10px
}

.y-icon-header:hover{
  padding: 6px;
  background: #f3f3f3;
  border-radius: 6px;
}

.title{
  font-size: 22px;
  margin-top: 15px;
  font-weight: bold;
  margin-left: 10px;
}

.input{
  width: 100%;
  font-size: 16px;
  justify-content: left;
  text-align: left;
}

.active{
  background: #db0000;
  color: white;
  padding: 5px;
  border: 0;
  border-radius: 3px;
}

.button{
  width: 60%;
  background: #c82333;
  font-size: 14px;
  padding: 10px;
  color: white;
  border: solid 1px #c82333;
  border-radius: 3px;
}

.button:hover{
  background: #721c24;
}

.desc{
  font-size: 14px;
}

.permission-pill{
  border-radius: 6px;
  padding: 4px;
  margin-left: 2px;
  margin-bottom: 5px;
}

.permission-pill:hover{
  background: #fcb5bb;
}

.permission-card{
  border-radius: 6px;
  border: solid 1px #c2c2c2;
  padding: 5px;
}

.floater{
  float: right;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #c82333;
  background-color: #c82333;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(255, 48, 3, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ff88a3;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #ffb4c8;
  border-color: #ff90a6;
}

</style>