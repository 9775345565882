<template>
    <div>
          <b-row>
            <b-col cols="12" class="text-center">
              <div class="font-weight-bold title">Let's create your account</div>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-col class="text-center">
              <div class="font-weight-bold desc">Select the Service you would like to signup for</div>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="3"></b-col>
            <b-col cols="6" class="mt-3 text-center align-content-center justify-content-center">
              <b-row class="service">
                <input type="checkbox" class="chk" v-model="services.POS">
                <img src="../../assets/products/ic_pos.png" class="product-icon">
                <span class="product-label font-weight-bold">Point of sale</span>
              </b-row>

              <b-row class="service">
                <input type="checkbox" class="chk" v-model="services.Loyalty">
                <img src="../../assets/products/ic_loyalty.png" class="product-icon">
                <span class="product-label font-weight-bold">Loyalty Program</span>
              </b-row>

              <b-row class="service">
                <input type="checkbox" class="chk" v-model="services.Subscriptions">
                <img src="../../assets/products/ic_subs.png" class="product-icon">
                <span class="product-label font-weight-bold">Subscriptions</span>
              </b-row>

              <b-row class="service">
                <input type="checkbox" class="chk" v-model="services.Appointments">
                <img src="../../assets/products/ic_appts.png" class="product-icon">
                <span class="product-label font-weight-bold">Appointments</span>
              </b-row>


            </b-col>
            <b-col cols="3"></b-col>
          </b-row>

    </div>
</template>

<script>
    export default {
      name: "SelectServices",
      data(){
         return {
            services:{
                POS:false,
                Loyalty:false,
                Subscriptions:false,
                Appointments:false,
            },
        }
      },
      mounted() {
          let products = JSON.parse(localStorage.getItem("signup-form")).active_products
          if(products != null){
            this.services.POS = products[0].POS
            this.services.Loyalty = products[1].Loyalty
            this.services.Subscriptions = products[2].Subscriptions
            this.services.Appointments = products[3].Appointments
          }
      },
      computed: {
          getLoyalty(){
              return this.services.Loyalty
          },
          getSubscription(){
            return this.services.Subscriptions;
          },
      },
      watch: {
        services:{
          handler(data){
            this.$emit("servicesData", data)
          },
          deep:true
        },
        getLoyalty(data){
          this.services.Subscriptions = data
        },
        getSubscription(data){
          this.services.Loyalty = data
        }
      }
    }
</script>

<style scoped>
    @import "../../commons/css/main.css";

    .service{
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .product-icon{
      width: 25px;
      height: 25px;
    }

    .product-label{
      color: #212529;
      font-size: 14px;
      margin-left: 5px;
      margin-top: 2px;
    }

    .chk{
      padding: 10px;
      margin-right: 5px;
      margin-top: 6px;
    }

    .title{
      font-size: 35px;
      font-weight: bold;
    }

    @media only screen and (max-width: 570px){
      .product-label{
        font-size: 12px;
      }
    }

    @media only screen and (max-width: 380px){
      .title{
        font-size: 22px;
      }

      .desc{
        font-size: 11px;
      }

      .product-icon{
        width: 22px;
        height: 22px;
      }

      .chk{
        margin-right: 6px;
        margin-top: 4px;
      }

    }
</style>