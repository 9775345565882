<template>
    <div class="expense">

      <b-row class="header">
        <b-col cols="1">
          <a class="navbar-brand ml-3">
            <img @click="back()" src="../../../../../assets/ic_cancel.png" class="y-icon-header">
          </a>
        </b-col>

        <b-col cols="9" style="margin: auto;">
          <div class="flex-grow-1 d-md-flex align-items-center justify-content-center d-none">
            <h1 class="h5 text-center title">Edit Item</h1>
          </div>
        </b-col>

        <b-col cols="2" style="margin: auto;">
          <button @click="save()" class="button">Save</button>
        </b-col>
      </b-row>
        <section class="content h-100 w-100 d-flex justify-content-center align-items-center flex-column mb-5">
            <b-col cols="7" class="mx-auto">
                <div class="mt-5  d-none d-md-block">
                    <b-overlay :show="loading" rounded="sm">
                      <span class="font-weight-bold h5">Details</span>
                      <table class="table w-100 font-weight-bold table-bordered">
                            <tbody>
                            <tr>
                                <th class="bg-light">Item name</th>
                                <td class="font-weight-normal">
                                    <b-form-input
                                            class="input"
                                            v-model="form.name"
                                            type="text"
                                            placeholder="Name"/>
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light">Category</th>
                                <td class="font-weight-normal">
                                  <b-form-select v-model="form.category_id" class="select">
                                    <b-select-option value="null">All Categories</b-select-option>
                                    <b-select-option v-for="(category, idx) in getCategories" :key="idx" :value="category.id">
                                      {{category.name}}
                                    </b-select-option>
                                  </b-form-select>
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light">Description</th>
                                <td class="font-weight-normal">
                                <textarea v-model="form.description"
                                          class="border-0 form-control"
                                          placeholder="Description"
                                          cols="30" rows="5"></textarea>
                                </td>
                            </tr>
                            </tbody>
                      </table>

                      <b-row style="margin-top: 40px"></b-row>
                      <span class="font-weight-bold h5">Price of the Item</span>
                      <table class="table w-100 font-weight-bold table-bordered">
                        <tbody>
                        <tr>
                          <th class="bg-light">{{location.location_name}} ({{location.address}})</th>
                          <td class="font-weight-normal">
                            <b-form-input
                                class="input"
                                type="text"
                                v-model="form.price"
                                placeholder="0"/>
                          </td>
                        </tr>
                        </tbody>
                      </table>

                      <b-row style="margin-top: 40px"></b-row>
                      <span class="font-weight-bold h5">Commissions</span>
                      <table class="table w-100 font-weight-bold table-bordered">
                        <tbody>
                        <tr>
                          <th class="bg-light" style="margin-top: auto; margin-bottom: auto;">
                            <span>{{location.location_name}} ({{location.address}})</span>
                          </th>
                          <td class="font-weight-normal" style="border: 0">
                            <table style="width: 100%; border: 0; margin: 0; padding: 0;">
                              <tbody>
                              <tr>
                                <td style="border: 0; margin: 0; padding: 0;">
                                  <b-form-input
                                      class="input"
                                      v-model="form.commission_value"
                                      type="text"
                                      placeholder="0"/>
                                </td>
                                <td style="border: 0; margin: 0; padding: 0;">
                                  <div class="toggle-btn text-center">
                                    <span :class="(showP) ? 'toggle-piece-active' : 'toggle-piece'" @click="percentageType()">%</span>
                                    <span :class="(showA) ? 'toggle-piece-active' : 'toggle-piece'" @click="amountType()">UGX</span>
                                  </div>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </b-overlay>
                </div>
            </b-col>
        </section>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "EditItem",
        data(){
          return{
            form:{
              id:null,
              name:null,
              description:null,
              category_id:null,
              price:[],
              commission_value:null,
              commission_type:null
            },
            id:null,
            loading:false,
            locationList:[],
            raw_date:null,
            raw_amount:null,
            refresh: false,
            location:null,
            showP:false,
            showA:false
          }
        },
      created() {
        this.fetchCategories()
      },
      mounted() {
          var item = JSON.parse(localStorage.getItem("item"))
          this.form.id = item.id
          this.form.name = item.name
          this.form.description = item.description
          this.form.price = item.price
          this.form.commission_value = item.commission_value
          this.form.commission_type = item.commission_type
          this.form.category_id = item.category.id

        if(item.commission_type == 'percentage'){
          this.showP = true
        }

        if(item.commission_type == 'amount'){
          this.showA = true
        }

        this.location = item.location

      },
      methods:{
            ...mapActions(["fetchCategories", "editItem", "fetchLocations"]),
          back(){
             localStorage.removeItem("item")
             window.history.back();
          },
          save(){
            this.form.business_id = localStorage.getItem("business_id")
            this.loading = true
            this.editItem(this.form)
          },
          percentageType(){
            this.form.commission_type = "percentage"
            this.showP = true
            this.showA = false
          },
          amountType(){
            this.form.commission_type = "amount"
            this.showA = true
            this.showP = false
          },
      },
      computed:{
            ...mapGetters([
              "getCategories", "getActionResponse"
            ]),
      },
      watch:{
          getActionResponse(){
            this.loading = false
            window.history.back()
          },
      }
    }
</script>

<style scoped>
    @import "../../../../../commons/css/main.css";

    .expense{

    }

    .header{
        /*margin: 0;*/
        /*width: 100%;*/
        /*box-shadow: 0px 5px 3px #dedede;*/
        padding: 0;
        margin: 0;
        width: 100%;
        height: 85px;
        box-shadow: 0px 5px 3px #dedede;
    }

    .content{
        margin-top: 20px;
    }

    .y-icon-header{
        padding: 5px;
        width: 38px;
        height: 38px;
        margin: 16px 10px 10px 10px
    }

    .y-icon-header:hover{
        padding: 6px;
        background: #f3f3f3;
        border-radius: 6px;
    }

    .title{
        font-size: 22px;
        margin-top: 15px;
        font-weight: bold;
        margin-left: 10px;
    }

    .input{
        width: 100%;
        font-size: 16px;
        justify-content: left;
        text-align: left;
    }

    .active{
        background: #db0000;
        color: white;
        padding: 5px;
        border: 0;
        border-radius: 3px;
    }

    .button{
      width: 60%;
      background: #c82333;
      font-size: 14px;
      padding: 10px;
      color: white;
      border: solid 1px #c82333;
      border-radius: 3px;
    }

    .button:hover{
      background: #721c24;
    }

    .toggle-btn{
      font-size: 11px;
    }

    .toggle-piece{
      padding-top: 5px;
      padding-bottom: 5px;
      width: 100px;
      border: solid 1px #dedede;
    }

    .toggle-piece-active{
      color: #fff;
      padding-top: 5px;
      padding-bottom: 5px;
      width: 100px;
      background: #c82333;
      border: solid 1px #c82333;
    }

    .separate{
      margin-top: 40px;
    }

</style>