<template>
  <div class="apps">
    <b-row>
      <b-modal id="user_guide"
          size="lg"
          centered
          hide-footer
          header-bg-variant="light"
          body-bg-variant="light">

        <b-col cols="12">
          <b-row>
            <b-col cols="12" >
              <b-row>
                <b-col cols="4"></b-col>
                <b-col cols="1">
                  <img src="../../../../assets/ic_user_guide.png" class="modal-header-icon">
                </b-col>
                <b-col cols="3">
                  <p class="font-weight-bold h3">User Guide</p>
                </b-col>
                <b-col cols="4"></b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row style="margin-top: 15px;">
            <b-col cols="12" class="justify-content-center align-content-center text-center">
              <p class="font-weight-bold">Here is how you can get started on Yamzit</p>
            </b-col>
          </b-row>

          <b-row class="line">
            <b-col cols="4"></b-col>
            <b-col cols="4" class="justify-content-center align-content-center text-center">
              <button class="button" >Get Started</button>
            </b-col>
            <b-col cols="4"></b-col>
          </b-row>

          <b-row class="line-spacing">
            <b-col cols="4"></b-col>
            <b-col cols="4" class="justify-content-center align-content-center text-center">
              <a style="font-size: 12px; text-decoration: underline;" @click="$bvModal.hide('user_guide')">Skip Guide</a>
            </b-col>
            <b-col cols="4"></b-col>
          </b-row>
        </b-col>

      </b-modal>
    </b-row>
    <b-row>
      <b-col cols="1" sm="1" md="1" lg="1"></b-col>
      <b-col cols="10" sm="10" md="10" lg="10">
        <b-row class="lineTitle">
          <b-col cols="12">
            <span class="font-weight-bold h3">Active apps</span>
          </b-col>
          <b-col cols="12">
            <span style="font-size: 14px;">Handpicked solutions for businesses like yours</span>
          </b-col>
        </b-row>
        <b-row style="margin-top: 30px;">
          <b-col cols="4" md="4" lg="4"  @click="goHome" class="align-content-center justify-content-center app-spacing">
            <b-row class="default-app">
              <b-col cols="3" class="mx-auto text-center align-content-center justify-content-center">
                <img src="../../../../assets/apps/ic_dashboard.png" class="app-icon">
              </b-col>
              <b-col cols="9">
                <b-row>
                  <b-col>
                    <span class="font-weight-bold">Dashboard</span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <p class="label">Build a customer loyalty program straight from your point of sale or your Square Online website</p>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="4" md="4" lg="4"  @click="openPOS" v-if="products[0].POS" class="align-content-center justify-content-center app-spacing">
            <b-row class="top-apps">
              <b-col cols="3" class="mx-auto text-center align-content-center justify-content-center">
                <img src="../../../../assets/apps/ic_pos.png" class="app-icon">
              </b-col>
              <b-col cols="9">
                <b-row>
                  <b-col>
                    <span class="font-weight-bold">Point of sale</span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <p class="label">
                      Point of sale solutions to fit your business. Because every business is different, Yamzit has a variety of POS options that you can use
                    </p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="8"></b-col>
                  <b-col cols="4">
                    <b-row class="d-flex align-content-center justify-content-center" v-if="products[0].POS">
                      <img src="../../../../assets/ic_check.svg" class="app-indicator-icon">
                      <span class="app-indicator-label-active">Active</span>
                    </b-row>
                    <b-row class="d-flex align-content-center justify-content-center" v-else>
                      <img src="../../../../assets/ic_signal.svg" class="app-indicator-icon">
                      <span class="app-indicator-label-inactive">Inactive</span>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="4" md="4" lg="4" @click="openLoyalty" v-if="products[1].Loyalty" class="align-content-center justify-content-center app-spacing">
            <b-row class="top-apps">
              <b-col cols="3" class="mx-auto text-center align-content-center justify-content-center">
                <img src="../../../../assets/apps/ic_loyalty.png" class="app-icon">
              </b-col>
              <b-col cols="9">
                <b-row>
                  <b-col>
                    <span class="font-weight-bold">Loyalty Program</span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <p class="label">
                      The all-in-one point of sale for booking, payments, and more. We handle the admin while you do more of what you love.
                    </p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="8"></b-col>
                  <b-col cols="4">
                    <b-row class="d-flex align-content-center justify-content-center" v-if="products[1].Loyalty">
                      <img src="../../../../assets/ic_check.svg" class="app-indicator-icon">
                      <span class="app-indicator-label-active">Active</span>
                    </b-row>
                    <b-row class="d-flex align-content-center justify-content-center" v-else>
                      <img src="../../../../assets/ic_signal.svg" class="app-indicator-icon">
                      <span class="app-indicator-label-inactive">Inactive</span>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="4" md="4" lg="4" v-if="products[2].Subscriptions" class="align-content-center justify-content-center app-spacing">
            <b-row class="top-apps">
              <b-col cols="3" class="mx-auto text-center align-content-center justify-content-center">
                <img src="../../../../assets/apps/ic_subs.png" class="app-icon">
              </b-col>
              <b-col cols="9">
                <b-row>
                  <b-col>
                    <span class="font-weight-bold">Subscriptions</span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <p class="label">
                      Track all kinds of memberships at your business. Once activated, you will be able to use this feature in your POS.
                    </p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="8"></b-col>
                  <b-col cols="4">
                    <b-row class="d-flex align-content-center justify-content-center" v-if="products[2].Subscriptions">
                      <img src="../../../../assets/ic_check.svg" class="app-indicator-icon">
                      <span class="app-indicator-label-active">Active</span>
                    </b-row>
                    <b-row class="d-flex align-content-center justify-content-center" v-else>
                      <img src="../../../../assets/ic_signal.svg" class="app-indicator-icon">
                      <span class="app-indicator-label-inactive">Inactive</span>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="4" md="4" lg="4" v-if="products[3].Appointments" class="align-content-center justify-content-center app-spacing">
            <b-row class="top-apps">
              <b-col cols="3" class="mx-auto text-center align-content-center justify-content-center">
                <img src="../../../../assets/apps/ic_appts.png" class="app-icon">
              </b-col>
              <b-col cols="9">
                <b-row>
                  <b-col>
                    <span class="font-weight-bold">Appointments</span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <p class="label">
                      The all-in-one point of sale for booking, payments, and more. We handle the admin while you do more of what you love.
                    </p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="8"></b-col>
                  <b-col cols="4">
                    <b-row class="d-flex align-content-center justify-content-center" v-if="products[3].Appointments">
                      <img src="../../../../assets/ic_check.svg" class="app-indicator-icon">
                      <span class="app-indicator-label-active">Active</span>
                    </b-row>
                    <b-row class="d-flex align-content-center justify-content-center" v-else>
                      <img src="../../../../assets/ic_signal.svg" class="app-indicator-icon">
                      <span class="app-indicator-label-inactive">Inactive</span>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="1" sm="1" md="1" lg="1"></b-col>
    </b-row>
    <b-row class="lower-apps">
      <b-col cols="12">
        <b-row>
          <b-col cols="1" sm="1" md="1" lg="1"></b-col>
          <b-col cols="10" sm="10" md="10" lg="10">
            <b-row class="line">
              <b-col cols="12">
                <span class="font-weight-bold h3">Most popular apps</span>
              </b-col>
              <b-col cols="12">
                <span style="font-size: 14px;">Explore the apps that a wide range of merchants rely on.</span>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="4" md="4" lg="4" class="l-top-apps" v-if="!products[1].Loyalty">
                <b-row>
                  <b-col cols="3" class="mx-auto text-center align-content-center justify-content-center">
                    <img src="../../../../assets/apps/ic_loyalty.png" class="app-icon">
                  </b-col>
                  <b-col cols="9">
                    <b-row>
                      <b-col>
                        <span class="font-weight-bold">Loyalty Program</span>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <p class="label">
                          Get your customers to spend more at your business on each visit
                        </p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="4" md="4" lg="4" class="l-top-apps" v-if="!products[2].Subscriptions">
                <b-row>
                  <b-col cols="3" class="mx-auto text-center align-content-center justify-content-center">
                    <img src="../../../../assets/apps/ic_subs.png" class="app-icon">
                  </b-col>
                  <b-col cols="9">
                    <b-row>
                      <b-col>
                        <span class="font-weight-bold">Subscriptions</span>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <p class="label">
                          Track all monthly membership subscriptions at your business
                        </p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="4" md="4" lg="4" class="l-top-apps">
                <b-row>
                  <b-col cols="3" class="mx-auto text-center align-content-center justify-content-center">
                    <img src="../../../../assets/apps/ic_payment.png" class="app-icon">
                  </b-col>
                  <b-col cols="9">
                    <b-row>
                      <b-col>
                        <span class="font-weight-bold">Payments</span>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <p class="label">
                          Collect digital payments in real time using mobile money & Cards
                        </p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="4" md="4" lg="4" class="l-top-apps">
                <b-row>
                  <b-col cols="3" class="mx-auto text-center align-content-center justify-content-center">
                    <img src="../../../../assets/apps/ic_store_mgr.png" class="app-icon">
                  </b-col>
                  <b-col cols="9">
                    <b-row>
                      <b-col>
                        <span class="font-weight-bold">Store Manager</span>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <p class="label">Coming soon ...</p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="4" md="4" lg="4" class="l-top-apps">
                <b-row>
                  <b-col cols="3" class="mx-auto text-center align-content-center justify-content-center">
                    <img src="../../../../assets/apps/ic_marketplace.png" class="app-icon">
                  </b-col>
                  <b-col cols="9">
                    <b-row>
                      <b-col>
                        <span class="font-weight-bold">Martketplace</span>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <p class="label">Coming soon ...</p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="4" md="4" lg="4" class="l-top-apps">
                <b-row>
                  <b-col cols="3" class="mx-auto text-center align-content-center justify-content-center">
                    <img src="../../../../assets/apps/ic_marketing.png" class="app-icon">
                  </b-col>
                  <b-col cols="9">
                    <b-row>
                      <b-col>
                        <span class="font-weight-bold">Marketing</span>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <p class="label">Coming soon ...</p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

          </b-col>
          <b-col cols="1" sm="1" md="1" lg="1"></b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import router from "@/app/router/router";
export default {
  name: "Apps",
  data(){
    return{
      products:JSON.parse(localStorage.getItem("business")).active_products
    }
  },
  mounted() {
    if(localStorage.getItem("guide") != "done"){
      this.$bvModal.show("user_guide")
    }
  },
  methods:{
    goHome(){
      localStorage.setItem("guide", "done")
      router.push("/dashboard/home")
    },
    openPOS(){
      window.open("https://pos.yamzit.com").focus()
    },
    openLoyalty(){
      window.open("https://loyalty.yamzit.com").focus()
    }
  }
}
</script>

<style scoped>
@import "../../../../commons/css/main.css";

  .apps{

  }

  .line{
    margin-top: 50px;
    margin-bottom: 20px;
  }

.lineTitle{
  margin-top: 50px;
}

  .line-spacing{
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .default-app{
    border-radius: 6px;
    background: #f1f1f1;
    padding: 10px;
    width: 99%;
    height: 100%;

  }

  .default-app:hover{
    background: #dedede;
  }

  .top-apps{
    border-radius: 6px;
    background: #f1f1f1;
    padding: 10px;
    width: 99%;
    height: 100%;
  }

  .app-spacing{
    margin-bottom: 20px;
  }

  .l-top-apps{
    border-radius: 6px;
    background: #f1f1f1;
    padding: 10px;
  }

  .top-apps:hover{
    background: #dedede;
  }

.label{
  font-size: 12px;
}

.app-icon{
  width: 65px;
  height: 65px;
  margin-top: 5px;
  }

  .lower-apps{
    background: #f1f1f1;
    margin-top: 100px;
    padding-bottom: 50px;
  }

  .app-indicator-icon{
    width: 10px;
    height: 10px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .app-indicator-label-active{
    font-size: 9px;
    font-weight: bold;
    color: #1e7e34;
    margin-left: 3px;
  }

  .app-indicator-label-inactive{
    font-size: 9px;
    font-weight: bold;
    color: #bd2130;
    margin-left: 3px;
  }

.button{
  width: 100%;
  background: #c82333;
  font-size: 12px;
  padding: 10px;
  color: white;
  border: solid 1px #c82333;
  border-radius: 3px;
}

.button:hover{
  background: #9e1b28;
}

.modal-header-icon{
  width: 55px;
  height: 40px;
}

.mdl{
  margin-top: 50px;
  margin-bottom: 50px;
}

@media only screen and (max-width: 1281px){
  .label{
    font-size: 11px;
  }
}

</style>