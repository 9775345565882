<template>
    <div class="items">
      <b-row>
        <b-col cols="2" sm="4" md="4" lg="6" xl="6" class="less">
          <span class="font-weight-bold h3">History</span>
        </b-col>
        <b-col cols="10" sm="8" md="8" lg="6" xl="6"></b-col>
      </b-row>
      <b-row class="line">
        <b-col cols="8">
        </b-col>
        <b-col cols="3">
          <date-range-picker
              class="select"
              ref="picker"
              :locale-data="{format: 'dd-mm-yyyy' }"
              :minDate="null" :maxDate="null"
              :singleDatePicker="false"
              :timePicker="false"
              :timePicker24Hour="false"
              :showWeekNumbers="true"
              :showDropdowns="true"
              :autoApply="true"
              v-model="date_range"
              :linkedCalendars="true">
            <template style="width: 100%;">
                                                        <span style="font-size: 11px;">
                                                            {{ date_range.startDate }} - {{ date_range.endDate}}
                                                        </span>
            </template>
          </date-range-picker>
        </b-col>
        <b-col cols="1">
          <button @click="create" class="button">Export</button>
        </b-col>
      </b-row>
      <b-row class="list-header">
        <b-col cols="6">
          <span class="font-weight-bold">Name</span>
        </b-col>
        <b-col cols="3">
          <span class="font-weight-bold">Notes</span>
        </b-col>
        <b-col cols="3">
          <span class="font-weight-bold floater">Amount</span>
        </b-col>
      </b-row>
<!--      <b-overlay :show ="loading" rounded="sm">-->
<!--        <b-row v-for="(item, idx) in getPayroll.items" :key="idx" class="list-item">-->
<!--          <b-col cols="6">-->
<!--            <span class="text name">{{item.name}}</span>-->
<!--          </b-col>-->
<!--          <b-col cols="3">-->
<!--            <span class="text">{{item.notes}}</span>-->
<!--          </b-col>-->
<!--          <b-col cols="3">-->
<!--            <span class="text floater" >UGX {{ Number(item.amount).toLocaleString() }}</span>-->
<!--          </b-col>-->
<!--        </b-row>-->
<!--      </b-overlay>-->
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import DateRangePicker from 'vue2-daterange-picker'
export default {
  name: "PayrollHistory",
  components:{DateRangePicker},
  data(){
    return{
      search:null,
      loading:true,
      date_range:{
        startDate:null,
        endDate:null
      }
    }
  },
  mounted() {
    this.fetchTeams()
  },
  methods:{
    ...mapActions(["fetchPayroll"]),
    create(){
      //router.push("/new-member")
    },
  },
  computed:{
    ...mapGetters([
      "getPayroll"
    ]),
  },
  watch:{
    getPayroll(){
      this.loading = false
    },
  }
}
</script>

<style scoped>
@import "../../../../commons/css/main.css";

.items{
  margin-top: 10px;
}

.button{
  width: 100%;
  background: #fff;
  font-size: 12px;
  padding: 10px;
  font-weight: bold;
  color: #1d2124;
  border: solid 2px #1d2124;
  border-radius: 3px;
}

.line{
  margin-top: 20px;
  margin-bottom: 20px;
}

.select{
  width: 100%;
}

.list-header{
  width: 100%;
  background: #eaeaea;
  padding-bottom: 10px;
  padding-top: 10px;
  border-radius: 6px;
  margin: 0;
}

.list-header span{
  font-weight: bold;
}

.list-item{
  width: 100%;
  background: white;
  padding-bottom: 15px;
  padding-top: 15px;
  margin: 0;
  border-bottom: solid 1px #979696;
}

.list-item:hover{
  background: #dddddd;
}

.text{
  font-size: 14px;
}

.floater{
  float: right;
}

.input{
  border: solid 2px #707070;
  border-radius: 6px;
}
</style>