<template>
  <div class="reports">

    <b-col>
      <b-overlay :show="loading"></b-overlay>

      <b-row>
        <b-col cols="5" >
          <b-row><span class="font-weight-bold h3">This Months Report</span><br/></b-row>
          <b-row><span class="desc">This is how your business is performing this month compared to last month</span></b-row>
        </b-col>
        <b-col cols="7">
          <b-row class="less">
            <b-col cols="8">
            </b-col>
            <b-col cols="4">
              <b-form>
                <b-form-select v-model="filter_location" class="select" v-on:change="setLocation()">
                  <b-select-option value="null">All Locations</b-select-option>
                  <b-select-option v-for="(location, idx) in getLocations" :key="idx" :value="location">
                    {{location.location_name}} ({{location.address}})
                  </b-select-option>
                </b-form-select>
              </b-form>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="mt-4 mb-4">
        <b-col cols="3">
          <b-row>
            <b-col class="details-card">
              <b-row>
                <span class="font-weight-bold detail-label">LOCATION</span>
              </b-row>

              <b-row style="margin-top: 4px">
                <span class="font-weight-bold detail-label-title text-danger">{{ location }}</span>
              </b-row>

              <b-row class="mt-4 pb-2">
                <span class="font-weight-bold detail-currency-3"></span>
                <span class="detail-amount-tiny"></span>

                <span class="detail-currency-2 ml-2"></span>
              </b-row>

            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <b-row>
            <b-col class="details-card">
              <b-row>
                <span class="font-weight-bold detail-label">CUSTOMERS</span>
              </b-row>

              <b-row>
                <span class="font-weight-bold detail-amount">{{getLoyaltyReports.customers}}</span>
              </b-row>

              <b-row class="mt-2">
                <span :class="[(customers_difference > 0) ? 'detail-amount-tiny':'detail-amount-tiny-low']">
                  {{Number(Math.abs(customers_difference)).toLocaleString()}}
                </span>
                <span class="detail-currency-2 ml-2" v-if="customers_difference > 0">
                  more than last month
                </span>
                <span class="detail-currency-2 ml-2" v-else>less than last month</span>
              </b-row>

            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <b-row>
            <b-col class="details-card">
              <b-row>
                <span class="font-weight-bold detail-label">VISITS</span>
              </b-row>

              <b-row>
                <span class="font-weight-bold detail-amount">{{ getLoyaltyReports.visits }}</span>
              </b-row>

              <b-row class="mt-2">
                <span :class="[(visits_difference > 0) ? 'detail-amount-tiny':'detail-amount-tiny-low']">
                  {{ Number(Math.abs(visits_difference)).toLocaleString() }}
                </span>

                <span class="detail-currency-2 ml-2" v-if="visits_difference > 0">
                  more than last month
                </span>
                <span class="detail-currency-2 ml-2" v-else>less than last month</span>
              </b-row>

            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <b-row>
            <b-col class="details-card">
              <b-row>
                <span class="font-weight-bold detail-label">CASH</span>
              </b-row>

              <b-row>
                <span class="font-weight-bold detail-currency-2">UGX</span>
                <span class="font-weight-bold detail-amount">{{ Number(getLoyaltyReports.cash).toLocaleString() }}</span>
              </b-row>

              <b-row class="mt-2">
                <span class="font-weight-bold detail-currency-3">UGX</span>
                <span :class="[(cash_difference > 0) ? 'detail-amount-tiny':'detail-amount-tiny-low']">
                  {{ Number(Math.abs(cash_difference)).toLocaleString() }}
                </span>

                <span class="detail-currency-2 ml-2" v-if="cash_difference > 0">
                  more than last month
                </span>
                <span class="detail-currency-2 ml-2" v-else>less than last month</span>
              </b-row>

            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="5" >
          <b-row><span class="font-weight-bold">{{business_name}} Customers</span><br/></b-row>
          <b-row><span class="desc">This is will help you view how many customers do visit your business</span></b-row>
        </b-col>
        <b-col cols="7">
          <b-row class="less">
            <b-col cols="4"></b-col>
            <b-col cols="5">
              <b-row style="width: 100%;">
                <date-range-picker
                    class="select"
                    ref="picker"
                    :locale-data="{format: 'dd-mm-yyyy' }"
                    :minDate="null" :maxDate="null"
                    :singleDatePicker="false"
                    :timePicker="false"
                    :timePicker24Hour="false"
                    :showWeekNumbers="true"
                    :showDropdowns="true"
                    :autoApply="true"
                    v-model="date_range"
                    :linkedCalendars="true">
                  <template style="width: 100%;">
                     <span style="font-size: 11px;">
                        {{ date_range.startDate }} - {{ date_range.endDate}}
                     </span>
                  </template>
                </date-range-picker>
              </b-row>
            </b-col>
            <b-col cols="3">
              <button @click="exportData" class="button">Export</button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <span class="font-weight-bold">CUSTOMERS</span>
      </b-row>

      <b-row>
        <b-col cols="6">
          <b-row >
            <b-col cols="12" class="details-card">
              <b-row>
                <b-col cols="5">
                  <div @click="loadDetails('/new-customers')" class="clickable">
                    <b-row>
                      <span class="font-weight-bold detail-label">NO. OF NEW CUSTOMERS</span>
                    </b-row>
                    <b-row>
                      <p class="font-weight-bold detail-amount mt-1">{{Number(getNewCustomers.count).toLocaleString()}}</p>
                    </b-row>
                  </div>

                  <b-row class="bar"></b-row>
                  <div @click="loadDetails('/return-customers')" class="clickable">
                    <b-row>
                      <span class="detail-label font-weight-bold">NO. OF RETURN CUSTOMERS</span>
                    </b-row>
                    <b-row>
                      <p class="font-weight-bold detail-amount mt-1">{{Number(getCustomersReturned.count).toLocaleString()}}</p>
                    </b-row>
                  </div>
                </b-col>

                <b-col cols="6">
                  <div @click="loadDetails('/all-customers')" class="clickable">
                    <b-row>
                      <span class="font-weight-bold detail-label">NO. OF CUSTOMERS</span>
                    </b-row>
                    <b-row>
                      <p class="font-weight-bold detail-amount mt-10">{{Number(getTotalCustomers.count).toLocaleString()}}</p>
                    </b-row>
                  </div>

                  <b-row class="bar"></b-row>

                  <div @click="loadDetails('/customer-visits')" class="clickable">
                    <b-row>
                      <span class="detail-label font-weight-bold">NO. OF CUSTOMER VISITS</span>
                    </b-row>
                    <b-row>
                      <p class="font-weight-bold detail-amount mtop-10">{{Number(getCustomersVisits.count).toLocaleString()}}</p>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <b-row class="details-card-2">
            <b-col>
              <b-row>
                <span class="font-weight-bold detail-label">CUSTOMER RETENTION RATE</span>
              </b-row>
              <b-row>
                <p class="font-weight-bold detail-amount mt-1 text-success" v-if="getCustomersRetentionRate.rate >= 20">{{getCustomersRetentionRate.rate}}%</p>
                <p class="font-weight-bold detail-amount mt-1 text-danger" v-else>{{getCustomersRetentionRate.rate}}%</p>
              </b-row>

              <b-row class="bar"></b-row>

              <b-row>
                <span class="font-weight-bold detail-label">REPEAT PURCHASE RATE</span>
              </b-row>
              <b-row>
                <p class="font-weight-bold detail-amount mt-1 text-success" v-if="getCustomersRepeatRate.rate >= 20">{{getCustomersRepeatRate.rate}}%</p>
                <p class="font-weight-bold detail-amount mt-1 text-danger" v-else>{{getCustomersRepeatRate.rate}}%</p>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <b-row class="details-card-2">
            <b-col>
              <b-row>
                <span class="font-weight-bold detail-label">LIFE TIME CUSTOMERS</span>
              </b-row>
              <b-row>
                <p class="font-weight-bold detail-amount mt-1">{{Number(getLifetimeCustomers.count).toLocaleString()}}</p>
              </b-row>

              <b-row class="bar"></b-row>

              <b-row>
                <span class="font-weight-bold detail-label">LIFETIME CUSTOMER VISITS</span>
              </b-row>
              <b-row>
                <p class="font-weight-bold detail-amount mt-1">{{Number(getLifetimeCustomersVisits.count).toLocaleString()}}</p>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col cols="3">
          <b-row >
            <b-col cols="12" class="details-card-2">
              <b-row>
                <span class="font-weight-bold detail-label">CONVERTED CUSTOMERS</span>
              </b-row>
              <b-row>
                <p class="font-weight-bold detail-amount mt-1 text-success">{{getCustomersConverted.count}}</p>
              </b-row>

              <b-row class="bar"></b-row>

              <div @click="loadDetails('/customers-at-risk')" class="clickable">
                <b-row>
                  <span class="font-weight-bold detail-label">CUSTOMERS AT RISK</span>
                </b-row>
                <b-row>
                  <p class="font-weight-bold detail-amount mt-1 text-danger">{{getCustomersAtRisk.count}}</p>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="9">
          <b-row class="details-card-2" style="height: 97%;">

          </b-row>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <span class="font-weight-bold">REDEEMS</span>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-row >
            <b-col cols="12" class="details-card">
              <b-row>
                <b-col cols="5">
                  <b-row>
                    <span class="font-weight-bold detail-label">NO. OF POINTS ISSUED</span>
                  </b-row>
                  <b-row>
                    <p class="font-weight-bold detail-amount mt-1">{{Number(getPointsIssued.count).toLocaleString()}}</p>
                  </b-row>

                  <b-row class="bar"></b-row>

                  <b-row>
                    <span class="detail-label font-weight-bold">NO. OF POINTS REDEEMED</span>
                  </b-row>
                  <b-row>
                    <p class="font-weight-bold detail-amount mt-1">{{Number(getPointsRedeemed.data).toLocaleString()}}</p>
                  </b-row>
                </b-col>

                <b-col cols="6">
                  <div @click="loadDetails('/all-redeems')" class="clickable">
                    <b-row>
                      <span class="font-weight-bold detail-label">NO. OF REDEEMS</span>
                    </b-row>
                    <b-row>
                      <p class="font-weight-bold detail-amount mt-1">{{Number(getTotalPointsRedeemed.count).toLocaleString()}}</p>
                    </b-row>
                  </div>

                  <b-row class="bar"></b-row>

                  <b-row>
                    <span class="detail-label font-weight-bold"></span>
                  </b-row>
                  <b-row>
                    <p class="font-weight-bold detail-amount mt-1"></p>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>

          </b-row>
        </b-col>
        <b-col cols="3">
          <b-row class="details-card-2" style="height: 97%;">
            <b-col>
              <b-row>
                <span class="font-weight-bold detail-label">REDEMPTION RATE (RR)</span>
              </b-row>
              <b-row>
                <p class="font-weight-bold detail-amount mt-1">{{getCustomersRedemptionRate.rate}}%</p>
              </b-row>

              <b-row class="bar"></b-row>

              <b-row>
                <span class="font-weight-bold detail-label"></span>
              </b-row>
              <b-row>
                <p class="font-weight-bold detail-amount mt-1 text-danger"></p>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3" >
          <b-row class="details-card-2" style="height: 97%;">
            <b-col cols="12" class="mt-5">
              <b-row>
                <span class="font-weight-bold detail-label">LIFETIME POINTS</span>
              </b-row>
              <b-row>
                <span class="font-weight-bold detail-amount">{{Number(getLifetimePoints.count).toLocaleString()}}</span>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col cols="9" >
          <b-row>
            <b-col cols="12" class="details-card pb-3">
              <b-row class="mt-4">
                <b-col cols="4" class="ml-2">
                  <b-row>
                    <span class="font-weight-bold">TOTAL CASH</span>
                  </b-row>
                  <b-row>
                    <span class="desc-2">For your loyal customers</span>
                  </b-row>
                </b-col>
                <b-col cols="7">
                  <b-row>
                    <span class="font-weight-bold detail-label">UGX</span>
                    <span class="font-weight-bold detail-amount-large">{{Number( getTotalCash.amount ).toLocaleString()}}</span>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3" >
          <b-row class="details-card-2 pb-4">
            <b-col cols="12" class="mt-4">
              <b-row>
                <span class="font-weight-bold detail-label">LIFETIME CASH</span>
              </b-row>
            </b-col>
            <b-col cols="12" class="mt-1">
              <b-row>
                <span class="font-weight-bold  detail-currency-2">UGX</span>
                <span class="font-weight-bold detail-amount">{{Number( getLifetimeCash.amount ).toLocaleString()}}</span>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <span class="font-weight-bold h5">Gender</span>
      </b-row>
      <b-row>
        <p class="desc">This will help you view how many customers do visit your business.</p>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-row>
            <b-col cols="3" class="details-card">
              <b-row >
                <b-col>
                  <b-row>
                    <span class="font-weight-bold detail-label">FEMALE</span>
                  </b-row>
                  <b-row>
                    <p class="font-weight-bold detail-amount">{{Number(getGenderDistribution.female_count).toLocaleString()}}</p>
                  </b-row>
                  <b-row>
                    <img src="../../../../assets/ic_female.svg" class="glyph">
                    <img src="../../../../assets/ic_female.svg" class="glyph">
                    <img src="../../../../assets/ic_female.svg" class="glyph">
                    <img src="../../../../assets/ic_female.svg" class="glyph">
                    <img src="../../../../assets/ic_female.svg" class="glyph">
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="3" class="details-card">
              <b-row >
                <b-col>
                  <b-row>
                    <span class="font-weight-bold detail-label">MALE</span>
                  </b-row>
                  <b-row>
                    <p class="font-weight-bold detail-amount">{{Number(getGenderDistribution.male_count).toLocaleString()}}</p>
                  </b-row>
                  <b-row>
                    <img src="../../../../assets/ic_male.svg" class="glyph">
                    <img src="../../../../assets/ic_male.svg" class="glyph">
                    <img src="../../../../assets/ic_male.svg" class="glyph">
                    <img src="../../../../assets/ic_male.svg" class="glyph">
                    <img src="../../../../assets/ic_male.svg" class="glyph">
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="3" class="details-card">
              <b-row >
                <b-col>
                  <b-row>
                    <span class="font-weight-bold detail-label">OTHERS</span>
                  </b-row>
                  <b-row>
                    <p class="font-weight-bold detail-amount">{{Number(getGenderDistribution.others_count).toLocaleString()}}</p>
                  </b-row>
                  <b-row>
                    <img src="../../../../assets/ic_male.svg" class="glyph">
                    <img src="../../../../assets/ic_female.svg" class="glyph">
                    <img src="../../../../assets/ic_male.svg" class="glyph">
                    <img src="../../../../assets/ic_female.svg" class="glyph">
                    <img src="../../../../assets/ic_male.svg" class="glyph">
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
<!--            <b-col cols="3" class="details-card"></b-col>-->
          </b-row>
        </b-col>

      </b-row>

    </b-col>

  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import {mapActions, mapGetters} from "vuex";
import moment from "moment";
import router from "@/app/router/router";

export default {
  name: "LoyaltyReport",
  components:{DateRangePicker},
  data(){
    return{
      loading: true,
      customers_difference: 0,
      visits_difference: 0,
      cash_difference: 0,
      business_name: JSON.parse(localStorage.getItem("business")).name,
      location: "All Locations",
      filter_location:null,
      date_range:{
        startDate:null,
        endDate:null
      },
    }
  },
  created() {
    this.date_range.startDate = moment().format('YYYY-MM-DD'),
    this.date_range.endDate = moment().format('YYYY-MM-DD')
  },
  mounted() {
    this.fetchLocations()
    this.fetchLoyaltyMonthlyReport()
    this.filter()
  },
  methods:{
    ...mapActions([
      "fetchLocations",
      "fetchLoyaltyMonthlyReport",
      "fetchSubscriptionReports",
      "fetchCustomerAtRisk",
      "fetchCustomerConverted",
      "fetchLifetimeCustomers",
      "fetchNewCustomers",
      "fetchTotalCustomers",
      "fetchCustomerVisits",
      "fetchCustomerLifetimeVisits",
      "fetchCustomerRepeatPurchaseRate",
      "fetchCustomerRetentionRate",
      "fetchCustomerRedemptionRate",
      "fetchPointsIssued",
      "fetchPointsRedeemed",
      "fetchLifetimePoints",
      "fetchTotalCash",
      "fetchLifetimeCash",
      "fetchCustomersReturned",
      "fetchGenderDistribution",
      "fetchTotalPointsRedeemed","filterLoyaltyMonthlyReport"
    ]),
    formatDate(date){
      return moment(date).format("YYYY-MM-DD")
    },
    detailedReport(){
      router.push("/detailed-report")
    },
    loadDetails(page){
      localStorage.setItem("d-from", this.date_range.startDate)
      localStorage.setItem("d-to", this.date_range.endDate)
      let route = null;
      if(this.filter_location != null){
        route = router.resolve({path: page+"/"+this.filter_location.id})
      }else{
        route = router.resolve({path:page+"/all"})
      }
      window.open(route.href, '_blank');
    },
    exportData(){

    },
    setLocation(){
      if(this.filter_location.location_name != null){
        this.location = this.filter_location.location_name+" "+this.filter_location.address
      }else{
        this.location = "All Locations"
      }
    },

    filter(){
      let loc = null
      if(this.filter_location != null){
        loc = this.filter_location.id
      }
      let  data = {
        date:{
          from:this.formatDate(this.date_range.startDate),
          to:this.formatDate(this.date_range.endDate)
        },
        location:loc
      }

      this.loading = true
      this.fetchSubscriptionReports(data)
      this.fetchCustomerAtRisk(data)
      this.fetchCustomerConverted(data)
      this.fetchNewCustomers(data)
      this.fetchLifetimeCustomers(data)
      this.fetchTotalCustomers(data)
      this.fetchCustomerVisits(data)
      this.fetchCustomerLifetimeVisits(data)
      this.fetchCustomerRepeatPurchaseRate(data)
      this.fetchCustomerRetentionRate(data)
      this.fetchCustomerRedemptionRate(data)
      this.filterLoyaltyMonthlyReport(data)
      this.fetchPointsIssued(data)
      this.fetchLifetimePoints(data)
      this.fetchTotalCash(data)
      this.fetchLifetimeCash(data)
      this.fetchGenderDistribution(data)
      this.fetchCustomersReturned(data)
      this.fetchPointsRedeemed(data)
      this.fetchTotalPointsRedeemed(data)
    },
  },
  computed:{
    ...mapGetters([
      "getLocations",
      "getLoyaltyReports",
      "getSubscriptionReports",
      "getCustomersAtRisk",
      "getCustomersConverted",
      "getNewCustomers",
      "getLifetimeCustomers",
      "getTotalCustomers",
      "getCustomersVisits",
      "getLifetimeCustomersVisits",
      "getCustomersRepeatRate",
      "getCustomersRetentionRate",
      "getCustomersRedemptionRate",
      "getPointsIssued",
      "getPointsRedeemed",
      "getLifetimePoints",
      "getTotalCash", "getLifetimeCash", "getGenderDistribution",
      "getCustomersReturned", "getTotalPointsRedeemed"
    ]),
    getDateRange(){
      return this.date_range
    },
    getFilterLocation(){
      return this.filter_location
    },
  },
  watch:{
    getLoyaltyReports(data){
      this.customers_difference = data.customers - data.last_month_customers
      this.visits_difference = data.visits - data.last_month_visits
      this.cash_difference = data.cash - data.last_month_cash
      this.loading = false
    },
    getSubscriptionReports(){
      this.loading = false
    },
    getTotalCustomers(){
      this.loading = false
    },
    getDateRange(){
      this.filter()
    },
    getFilterLocation(){
      this.filter()
    },
  }
}
</script>

<style scoped>
@import "../../../../commons/css/main.css";

.reports{
  z-index: -5;
  margin-bottom: 50px;
}

.select{
  width: 100%;
}

.desc{
  font-size: 12px;
  color: #a0a0a0;
}

.desc-2{
  font-size: 12px;
}

.button{
  width: 100%;
  background: #fff;
  font-size: 12px;
  padding: 8px;
  color: #1d2124;
  font-weight: bold;
  border: solid 2px #1d2124;
  border-radius: 6px;
}

.less{
  margin:0;
  padding: 0;
}

.details-card{
  border: solid 2px #d0d0d0;
  border-radius: 6px;
  padding-left: 25px;
  padding-right: 15px;
  padding-top: 10px;
  margin: 4px;
}

.details-card-2{
  border: solid 2px #d0d0d0;
  border-radius: 6px;
  padding-left: 25px;
  padding-right: 10px;
  padding-top: 10px;
  margin: 4px;
}

.details-card-3{
  border: solid 2px #d0d0d0;
  border-radius: 6px;
  margin: 4px;
}

.line{
  margin-top: 20px;
  margin-bottom: 20px;
}

.detail-label-title{
  font-size: 14px;
}

.detail-label{
  font-size: 12px;
}

.detail-amount{
  font-size: 22px;
}

.detail-amount-tiny{
  font-size: 11px;
  color: #1e7e34;
  font-weight: bold;
}

.detail-amount-tiny-low{
  font-size: 11px;
  color: #bd2130;
  font-weight: bold;
}

.clickable:hover {
  color: #005cbf;
  font-weight: bold;
}
.green{
  color: #1e7e34;
}

.clickable:hover .green{
  color: #005cbf;
  font-weight: bold;
}

.detail-currency{
  font-size: 12px;
}

.detail-currency-2{
  font-size: 9px;
  margin-top: 1px;
}

.detail-currency-3{
  font-size: 6px;
  margin-right: 1px;
}


.mtop-10{
  margin-top: 10px;
}

.detail-amount-large{
  font-size: 42px;
}

.glyph{
  padding: 3px;
}

.bar{
  width: 100%;
  background: #a0a0a0;
  height: 1px;
  margin-bottom: 6px;
}

.arrow-up{
  background: #4CC35E 0% 0% no-repeat padding-box;
  opacity: 1;
}

.arrow-down{
  background: #BD2532 0% 0% no-repeat padding-box;
  opacity: 1;
}

</style>