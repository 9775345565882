<template>
    <div class="items">
      <b-row>
        <b-modal id="point_value"
                 size="lg"
                 centered
                 title="New Point Value"
                 hide-footer
                 header-bg-variant="light"
                 body-bg-variant="light">

          <b-col cols="12" style="width: 100%;">
            <b-row class="line">
              <b-col cols="3"></b-col>
              <b-col cols="6">
                <b-row>
                  <b-col cols="6">
                    <div class="left-title">
                      1 Point is =
                    </div>
                  </b-col>
                  <b-col cols="6">
                    <b-row style="width: 100%;">
                      <div class="input-group input-holder">
                        <b-form-input type="text" class="form-control border-0 input font-weight-bold"
                                      placeholder="Amount"
                                      v-model="form.amount" />
                      </div>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="3"></b-col>
            </b-row>
            <b-row>
              <b-col cols="12" class="text-center justify-content-center align-content-center">
                <div class="text">
                  This will be set as the value of each point at your business.
                </div>
              </b-col>
            </b-row>
            <b-row class="line">
              <b-col cols="10"></b-col>
              <b-col cols="2">
                <button class="button" @click="create">Save</button>
              </b-col>
            </b-row>
          </b-col>
        </b-modal>
      </b-row>

      <b-overlay :show="loading" rounded="sm">
        <b-row>
          <b-col cols="2" sm="4" md="4" lg="6" xl="6" class="less">
            <span class="font-weight-bold h3">Point Value</span>
          </b-col>
          <b-col cols="10" sm="8" md="8" lg="6" xl="6"></b-col>
        </b-row>
        <b-row class="line2">
          <b-col cols="6">
            <p class="text">
              <b>Current point value</b>
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <p class="content">
              1 Point = UGX {{Number(amount).toLocaleString()}}
            </p>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="6">
            <b-alert variant="success" show>
              This can be change the value for each point whenever you want to.
            </b-alert>
          </b-col>
        </b-row>

        <b-row class="line">
          <b-col cols="2">
            <button v-b-modal.point_value class="button">Update</button>
          </b-col>
        </b-row>
      </b-overlay>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
export default {
  name: "PointValue",
  data(){
    return{
      search:null,
      loading:true,
      amount: "0",
      form:{
        amount:""
      },
      date_range:{
        startDate:null,
        endDate:null
      }
    }
  },
  mounted() {
    this.fetchPointValue()
  },
  methods:{
    ...mapActions(["fetchPointValue", "updatePointValue"]),
    create(){
      this.loading = true
      this.updatePointValue(this.form)
      this.$bvModal.hide("point_value")
    },
  },
  computed:{
    ...mapGetters([
      "getPointValue"
    ]),
  },
  watch:{
    getPointValue(data){
      this.loading = false
      this.amount = data.amount
      this.form.amount = data.amount
    }
  }
}
</script>

<style scoped>
@import "../../../../commons/css/main.css";

.items{
  margin-top: 10px;
}

.button{
  width: 100%;
  background: #c82333;
  font-size: 12px;
  padding: 10px;
  color: white;
  border: solid 1px #c82333;
  border-radius: 3px;
}

.line{
  margin-top: 20px;
  margin-bottom: 20px;
}

.line2{
  margin-top: 20px;
}

.content{
  font-size: 28px;
  font-weight: bold;
}


.text{
  font-size: 14px;
}

.input{
  font-size: 32px;
}

.input-holder{
  border-bottom: solid 2px #707070;
}

.left-title{
  font-size: 28px;
  color: #bfbebe;
  font-weight: bold;
  margin-top: 14px;
}

</style>