<template>
    <div class="expense">
        <b-row class="h-100 w-100 d-flex flex-column ">
            <div class="center d-flex justify-content-md-center align-items-center flex-column flex-grow-1">
                <div class="col-md-8 col-lg-7 mx-auto mt-5 mt-md-0">
                    <div class="d-flex flex-column align-items-center mt-5 mt-md-0">
                        <img src="../../assets/ic_done_green.png" class="y-icon"/>
                        <p class="mt-2 d-inline-block text">Congratulations you are all set</p>
                    </div>
                </div>
            </div>
        </b-row>
      <b-row>
        <div class="center d-flex justify-content-md-center align-items-center flex-column flex-grow-1">
          <div class="col-md-4 col-lg-4 mx-auto mt-5 mt-md-0">
            <button class="button btn btn-danger w-100 py-3" @click="go">CONTINUE</button>
          </div>
        </div>
      </b-row>
    </div>
</template>

<script>
    import router from "../../app/router/router"

    export default {
        name: "CompleteSignUp",
        props:{
            action:String
        },
        methods:{
            go(){
                router.push("/dashboard/apps")
            },
        },
    }
</script>

<style scoped>
    @import "../../commons/css/main.css";

    .expense{

    }

    .y-icon{
        width: 150px;
    }

    .center{
        margin-top: 180px;
    }

    .text{
        font-size: 26px;
    }

</style>