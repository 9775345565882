<template>
    <div class="items">
      <b-row>
        <b-modal id="add_location" centered title="Create Location" hide-footer>
          <b-container fluid>
            <b-row>
              <b-col>
                <table class="table font-weight-bold table-bordered">
                  <tbody>
                  <tr>
                    <th class="bg-light">Location Name</th>
                    <td class="font-weight-normal">
                      <b-form-input
                          class="m-input"
                          v-model="form.location_name"
                          type="text"
                          placeholder="Location Name"/>
                    </td>
                  </tr>
                  <tr>
                    <th class="bg-light">Address</th>
                    <td class="font-weight-normal">
                      <b-form-input
                          class="m-input"
                          v-model="form.address"
                          type="text"
                          placeholder="Address"/>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="8"></b-col>
              <b-col cols="4">
                <button class="button" @click="create">Save</button>
              </b-col>
            </b-row>
          </b-container>
        </b-modal>
      </b-row>

      <b-row>
        <b-col cols="2" sm="4" md="4" lg="6" xl="6" class="less">
          <span class="font-weight-bold h3">Location</span>
        </b-col>
        <b-col cols="10" sm="8" md="8" lg="6" xl="6"></b-col>
      </b-row>
      <b-row class="line">
        <b-col cols="3">
          <div class="input-group input">
            <b-form-input type="text" class="form-control border-0" placeholder="Search here..."
                          aria-label="Recipient's username" v-model="search" aria-describedby="basic-addon2"/>
          </div>
        </b-col>
        <b-col cols="7">
        </b-col>
        <b-col cols="2">
          <button v-b-modal.add_location @click="clearForm" class="button">Create a Location</button>
        </b-col>
      </b-row>
      <b-row class="list-header">
        <b-col cols="11">
          <span class="font-weight-bold">Location Name</span>
        </b-col>
        <b-col cols="1">
          <span class="font-weight-bold floater">Status</span>
        </b-col>
      </b-row>
      <b-overlay :show="loading" rounded="sm">
        <b-row v-for="(location, idx) in getLocations"
               :key="idx"
               class="list-item"
        @click="selectLocation(location)">
          <b-col cols="11">
            <span class="text name">{{location.location_name}} ({{location.address}})</span>
          </b-col>
          <b-col cols="1">
            <span class="text floater" >
              <div class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" :id="idx" :checked="location.status">
                <label class="custom-control-label" :for="idx"></label>
              </div>
            </span>
          </b-col>
        </b-row>
      </b-overlay>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
export default {
  name: "BusinessLocationSettings",
  data(){
    return{
      search:null,
      loading:true,
      filter_location:null,
      edit:false,
      date_range:{
        startDate:null,
        endDate:null
      },
      form:{
        location_name:null,
        address:null,
        business_id: localStorage.getItem("business_id")
      },
      selectedId:null
    }
  },
  mounted() {
    this.loading = true
    this.fetchLocations()
  },
  methods:{
    ...mapActions(["fetchRewards", "fetchLocations", "createLocation", "editLocation"]),
    create(){
      if(this.edit){
        let data = {
          form: this.form,
          id:this.selectedId
        }
        this.editLocation(data)
      }else{
        this.createLocation(this.form)
      }
      this.$bvModal.hide("add_location")
      this.clearForm()
    },
    selectLocation(data){
      this.edit =  true
      this.form.location_name = data.location_name
      this.form.address = data.address
      this.selectedId = data.id
      this.$bvModal.show("add_location")
    },
    clearForm(){
      this.form.location_name = null
      this.form.address = null
    }
  },
  computed:{
    ...mapGetters([ "getLocations", "getLocationSuccessResponse", "getLocationErrorResponse"
    ]),
  },
  watch:{
    getLocations(){
      this.loading = false
    },
    getLocationSuccessResponse(){
      this.loading = true
      this.fetchLocations()
    },
    getLocationErrorResponse(data){
      this.loading = false
      alert(JSON.stringify(data))
    }
  }
}
</script>

<style scoped>
@import "../../../../../commons/css/main.css";

.items{
  margin-top: 10px;
}

.button{
  width: 100%;
  background: #c82333;
  font-size: 12px;
  padding: 10px;
  color: white;
  border: solid 1px #c82333;
  border-radius: 3px;
}

.line{
  margin-top: 20px;
  margin-bottom: 20px;
}

.select{
  width: 100%;
}

.list-header{
  width: 100%;
  background: #eaeaea;
  padding-bottom: 10px;
  padding-top: 10px;
  border-radius: 6px;
  margin: 0;
}

.list-header span{
  font-weight: bold;
}

.list-item{
  width: 100%;
  background: white;
  padding-bottom: 15px;
  padding-top: 15px;
  margin: 0;
  border-bottom: solid 1px #979696;
}

.list-item:hover{
  background: #dddddd;
}

.text{
  font-size: 14px;
}

.floater{
  float: right;
}


.input{
  border: solid 2px #707070;
  border-radius: 6px;
}

th{
  font-size: 14px;
}

.m-input{
  font-size: 14px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #c82333;
  background-color: #c82333;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(255, 48, 3, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ff88a3;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #ffb4c8;
  border-color: #ff90a6;
}
</style>