<template>
    <div class="signup">
      <b-row class="h-100 align-items-center">
        <b-col cols="12">
          <b-row>
            <b-col cols="1" sm="1" md="3" lg="4"></b-col>
            <b-col cols="10" sm="10" md="6" lg="4" class="mx-auto mt-4">
              <b-alert show variant="danger" v-if="hasError">
                 <span class="error-message">
                    {{errorMessage}}
                 </span>
              </b-alert>
            </b-col>
            <b-col cols="1" sm="1" md="3" lg="4"></b-col>
          </b-row>

          <b-row>
            <b-col cols="1" sm="1" md="3" lg="4"></b-col>
            <b-col cols="10" sm="10" md="6" lg="4" class="mx-auto mt-4">
              <b-overlay :show="loading" rounded="sm">
                <SelectServices ref="services" v-on:servicesData="servicesData" v-if="signUpOptions.currentPage == 1"/>
                <BusinessInformation ref="b_info" v-on:businessInfoData="businessInfoData" v-if="signUpOptions.currentPage == 2"/>
                <YourInformation ref="y_info" v-on:yourInfoData="yourInfoData" v-if="signUpOptions.currentPage == 3"/>
              </b-overlay>
            </b-col>
            <b-col cols="1" sm="1" md="3" lg="4"></b-col>
          </b-row>

          <b-row>
            <b-col cols="1" sm="1" md="2" lg="4"></b-col>
            <b-col cols="10" sm="10" md="8" lg="4" class="mx-auto mt-4">
              <b-row v-if="signUpOptions.currentPage == 1">
                <b-col>
                  <div class="form-group text-center">
                    <button type="button"
                            @click="onNext()"
                            class="button btn btn-danger w-100 py-lg-3 py-md-3">
                      Continue
                    </button>
                  </div>
                </b-col>
              </b-row>
              <b-row v-if="signUpOptions.currentPage > 1">
                <b-col cols="6">
                  <div class="form-group text-center">
                    <button type="button"
                            @click="onBack()"
                            class="button btn btn-outline-secondary font-weight-bold w-100 py-lg-3 py-md-3">
                      Back
                    </button>
                  </div>
                </b-col>
                <b-col cols="6">
                  <div class="form-group text-center">
                    <button type="button"
                            @click="onNext()"
                            class="button btn btn-danger w-100 py-lg-3 py-md-3">
                      {{btnLabel}}
                    </button>
                  </div>
                </b-col>

              </b-row>
              <b-row v-if="signUpOptions.currentPage == 1">
                <b-col cols="12" class="p-2">
                  <p class="label text-center">Already have a Yamzit Account?
                    <router-link to="/login" style="font-weight: bold; color: #bd2130;">Log In</router-link>
                  </p>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="1" sm="1" md="2" lg="4"></b-col>
          </b-row>
        </b-col>
      </b-row>

    </div>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";
    import router from "../../app/router/router";
    import SelectServices from "@/views/account/SelectServices";
    import YourInformation from "@/views/account/YourInformation";
    import BusinessInformation from "@/views/account/BusinessInformation";
    export default {
      name: "SignUp",
      components: {BusinessInformation, YourInformation, SelectServices},
      data(){
        return {
          form:{
            active_products:[],
            phone_number:null,
            firstname:null,
            lastname:null,
            country_id:null,
            email:null,
            pin:null,
            business_name:null,
            address:null,
            payment_method:null,
            industry_type_id:null,
            city:null,
          },
          btnLabel:"Next",
          signUpOptions:{
            maxPages:3,
            currentPage:1,
          },
          hasError: false,
          errorMessage:null,
          loading: false,
        }
      },
      mounted() {
        localStorage.removeItem("signup-form")
      },
      methods:{
        ...mapActions(["accountSignUp", "accountLogin"]),
        onSubmit() {
            this.loading = true
            this.accountSignUp(this.form)
          },
        onBack(){
              if(this.signUpOptions.currentPage > 1){
                this.signUpOptions.currentPage--
              }
          },
        onNext(){
          this.hasError = false
          if(this.signUpOptions.currentPage == 1){
              if(this.form.active_products.length > 0){
                this.nextPage()
              }else{
                this.hasError = true
                this.errorMessage = "Select a service"
              }
          }

          if(this.signUpOptions.currentPage == 3){
              if(this.form.phone_number != null
                  && this.form.firstname != null && this.form.lastname != null
                  && this.form.country_id != null && this.form.email != null
                  && this.form.pin != null){
                  this.onSubmit()
              }else{
                this.hasError = true
                this.errorMessage = "Complete all form fields"
              }
          }

          if(this.signUpOptions.currentPage == 2){
              if(this.form.business_name != null
                  && this.form.address != null && this.form.payment_method != null
                  && this.form.industry_type_id != null && this.form.city != null){
                  this.nextPage()
              }else{
                this.hasError = true
                this.errorMessage = "Complete all form fields"
              }
          }
        },
        nextPage(){
            this.hasError = false
          if(this.signUpOptions.currentPage < this.signUpOptions.maxPages){
            this.signUpOptions.currentPage++
          }
        },
        servicesData(data){
          this.form.active_products = []
          if(data.POS || data.Loyalty || data.Subscriptions || data.Appointments){
            this.form.active_products.push({POS: data.POS})
            this.form.active_products.push({Loyalty: data.Loyalty})
            this.form.active_products.push({Subscriptions: data.Subscriptions})
            this.form.active_products.push({Appointments: data.Appointments})
          }
          localStorage.setItem("signup-form", JSON.stringify(this.form))
        },
        yourInfoData(data){
          this.form.phone_number = data.phone_number
          this.form.firstname = data.firstname
          this.form.lastname = data.lastname
          this.form.country_id = data.country_id
          this.form.email = data.email
          this.form.pin = data.pin
          localStorage.setItem("signup-form", JSON.stringify(this.form))
        },
        businessInfoData(data){
          this.form.business_name = data.business_name
          this.form.address = data.address
          this.form.payment_method = data.payment_method
          this.form.industry_type_id = data.industry_type_id
          this.form.city = data.city
          localStorage.setItem("signup-form", JSON.stringify(this.form))
        }
      },
      computed: {
          ...mapGetters([
             "getSignUpResponse",
             "getLoginResponse",
             "getSignUpErrorResponse",
          ]),
          getLoyalty(){
              return this.Loyalty
          },
          getSubscription(){
            return this.Subscriptions;
          }
      },
      watch: {
        getSignUpResponse(){
          localStorage.removeItem("signup-form")
          this.loading = true
          let data = {
            email_or_phone: this.form.phone_number,
            pin: this.form.pin
          }
          this.accountLogin(data)
        },
        getLoginResponse(){
          this.loading = false
          router.push("/complete-signup")
        },
        getSignUpErrorResponse(data){
          this.loading = false
          this.hasError = true
          this.errorMessage = data.error
        },
      }
    }
</script>

<style scoped>
    @import "../../commons/css/main.css";

    .signup{
      height: 100vh;
    }

    .label{
      font-size: 14px;
    }

    .btn-outline-secondary{
      border: solid 3px #1b1e21;
      color: #1b1e21;
    }

    @media only screen and (max-width: 570px){

        .label{
            font-size: 12px;
        }

        .button{
            font-size: 12px;
        }
    }

    @media only screen and (max-width: 380px){

        .label{
            font-size: 10px;
        }

        .button{
            font-size: 11px;
        }
    }
</style>