<template>
  <div id="app">
    <b-row class="app-holder">
      <b-col cols="12">
        <router-view :key="$route.fullPath"/>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  name: 'App',
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Yamzit Dashboard';
      }
    },
  }
};
</script>
<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    height: 100%;
  }
  html,  body{
    height: 100%;
  }

  .app-holder{
    height: 100%;
  }
</style>