import axios from "axios";
import configs from "../../../../../app/configurations/configs.json";
//import qs from "querystring";

const IP = configs.SERVER_IP;

const state = {
  payroll:[],
  response:{},
  error:{},
};

const getters = {
    getPayroll: (state) => state.payroll,
};

const actions = {
  async fetchPayroll({ commit }) {
    await axios
        .get(IP + "/payroll/"+localStorage.getItem("business_id"))
        .then((response) => {
          commit("setPayroll", response.data);
        })
        .catch((error) => {
          commit("setErrorResponse", error.response);
        });
  },
  async fetchFilteredPayroll({ commit }, data) {
     await axios
        .get(IP + "/payroll/"+localStorage.getItem("business_id")+"?from="+data.from+"&to="+data.to)
        .then((response) => {
           commit("setPayroll", response.data);
        })
        .catch((error) => {
           commit("setErrorResponse", error.response);
        });
  },
};

const mutations = {
  setPayroll: (state, response) => {
    state.payroll = response.data;
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
