import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../../views/auth/Login";
import Dashboard from "@/views/dashboard/Dashboard";
import DashboardHome from "@/views/dashboard/menu/home/DashboardHome";
import Expenses from "@/views/dashboard/menu/expenses/Expenses";
import Customers from "@/views/dashboard/menu/customers/Customers";
import Transactions from "@/views/dashboard/menu/transactions/Transactions";
import Items from "@/views/dashboard/menu/items/Items";
import Reports from "@/views/dashboard/menu/reports/Reports";
import DummyView from "@/commons/components/DummyView";
import NewExpense from "@/views/dashboard/menu/expenses/NewExpense";
import RefundReason from "@/views/dashboard/menu/transactions/RefundReason";
import RefundComplete from "@/views/dashboard/menu/transactions/RefundComplete";
import Receipt from "@/views/dashboard/menu/transactions/Receipt";
import Products from "@/views/dashboard/menu/items/Products";
import Categories from "@/views/dashboard/menu/items/Categories";
import Discounts from "@/views/dashboard/menu/items/Discounts";
import DisabledDiscounts from "@/views/dashboard/menu/items/DisabledDiscounts";
import ExpenseReason from "@/views/dashboard/menu/expenses/ExpenseReason";
import ConfirmExpenseDelete from "@/views/dashboard/menu/expenses/ConfirmExpenseDelete";
import ConfirmExpense from "@/views/dashboard/menu/expenses/ConfirmExpense";
import DetailedReport from "@/views/dashboard/menu/reports/DetailedReport";
import NewDiscount from "@/views/dashboard/menu/items/create/NewDiscount";
import NewItem from "@/views/dashboard/menu/items/create/NewItem";
import NewCategory from "@/views/dashboard/menu/items/create/NewCategory";
import SummaryReport from "@/views/dashboard/menu/reports/SummaryReport";
import SubscriptionReport from "@/views/dashboard/menu/reports/SubscriptionReport";
import SignUp from "@/views/account/SignUp";
import Teams from "@/views/dashboard/menu/teams/Teams";
import Members from "@/views/dashboard/menu/teams/Members";
import NewMember from "@/views/dashboard/menu/teams/NewMember";
import Payroll from "@/views/dashboard/menu/payroll/Payroll";
import StaffList from "@/views/dashboard/menu/payroll/StaffList";
import Rewards from "@/views/dashboard/menu/loyalty_program/Rewards";
import PointValue from "@/views/dashboard/menu/loyalty_program/PointValue";
import PayrollHistory from "@/views/dashboard/menu/payroll/PayrollHistory";
import LoyaltyProgram from "@/views/dashboard/menu/loyalty_program/LoyaltyProgram";
import NewReward from "@/views/dashboard/menu/loyalty_program/NewReward";
import Settings from "@/views/dashboard/menu/settings/Settings";
import BusinessLocationSettings from "@/views/dashboard/menu/settings/business/BusinessLocationSettings";
import BusinessSettings from "@/views/dashboard/menu/settings/business/BusinessSettings";
import BusinessSubscriptionSettings from "@/views/dashboard/menu/settings/business/BusinessSubscriptionSettings";
import GeneralSettings from "@/views/dashboard/menu/settings/general/GeneralSettings";
import AppSettings from "@/views/dashboard/menu/settings/app/AppSettings";
import AccountSettings from "@/views/dashboard/menu/settings/account/AccountSettings";
import LoyaltySettings from "@/views/dashboard/menu/settings/app/LoyaltySettings";
import EditCustomer from "@/views/dashboard/menu/customers/EditCustomer";
import CompleteSignUp from "@/views/account/CompleteSignUp";
import Apps from "@/views/dashboard/menu/apps/Apps";
import EditMember from "@/views/dashboard/menu/teams/EditMember";
import DisabledItems from "@/views/dashboard/menu/items/DisabledItems";
import EditItem from "@/views/dashboard/menu/items/create/EditItem";
import LoyaltyReport from "@/views/dashboard/menu/reports/LoyaltyReport";
import Permissions from "@/views/dashboard/menu/teams/Permissions";
import CustomerJourneys from "@/views/dashboard/menu/loyalty_program/CustomerJourneys";
import JourneyDetails from "@/views/dashboard/menu/loyalty_program/JourneyDetails";
import AllCustomers from "@/views/dashboard/menu/reports/details/AllCustomers";
import NewCustomers from "@/views/dashboard/menu/reports/details/NewCustomers";
import CustomersAtRisk from "@/views/dashboard/menu/reports/details/CustomersAtRisk";
import AllRedeems from "@/views/dashboard/menu/reports/details/AllRedeems";
import ReturnCustomers from "@/views/dashboard/menu/reports/details/ReturnCustomers";
import CustomerVisits from "@/views/dashboard/menu/reports/details/CustomerVisits";
import SalesReportDetails from "@/views/dashboard/menu/reports/SalesReportDetails";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    redirect:"/login",
    component: DummyView,
    children:[
      {
        path: '/login',
        component: Login
      }
    ]
  },
  {
    path: "/signup",
    name: "SignUp",
    component: SignUp
  },
  {
    path: "/complete-signup",
    name: "ConfirmSignUp",
    component: CompleteSignUp
  },
  {
    path: "/category/:id",
    name: "sales-report-details",
    component: SalesReportDetails
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    children:[
      {path:"home", component: DashboardHome},
      {path:"reports", component: Reports, children:[
          {path:"summary", component:SummaryReport},
          {path:"subscription", component:SubscriptionReport},
          {path:"loyalty", component:LoyaltyReport}
        ]},
      {path:"products", component: Products, children:[
          {path:"items", component:Items},
          {path:"categories", component:Categories},
          {path:"disabled-items", component:DisabledItems},
          {path:"discounts", component:Discounts, children:[
              {path:"all", component:Discounts},
              {path:"disabled", component:DisabledDiscounts}
            ]}
        ]},
      {path:"transactions", component: Transactions},
      {path:"customers", component: Customers},
      {path:"expenses", component: Expenses},
      {path:"team", component: Teams, redirect:"team/members", children:[
          {path:"members", component:Members},
        ]},
      {path:"payroll", component: Payroll, redirect:"payroll/list",children:[
          {path:"list", component: StaffList},
          {path:"history", component: PayrollHistory},
        ]},
      {path:"loyalty", component: LoyaltyProgram, redirect:"loyalty/rewards",children:[
          {path:"rewards", component: Rewards},
          {path:"points", component: PointValue},
          {path:"journeys", component: CustomerJourneys},
        ]},
      {path:"settings", component: Settings, redirect:"settings/account", children:[
          {path:"account", component: AccountSettings},
          {path:"business", component: BusinessSettings, redirect:"business/location", children:[
              {path:"location", component: BusinessLocationSettings},
              {path:"subscription", component: BusinessSubscriptionSettings},
            ]},
          {path:"general", component: GeneralSettings},
          {path:"apps", component: AppSettings, redirect:"apps/loyalty", children: [
              {path:"loyalty", component: LoyaltySettings},
            ]},
        ]},
      {path:"apps", component: Apps}
    ]
  },
  {
    path: "/new-category",
    name: "NewCategory",
    component: NewCategory
  },
  {
    path: "/new-item",
    name: "NewItem",
    component: NewItem
  },
  {
    path: "/edit-item",
    name: "EditItem",
    component: EditItem
  },
  {
    path: "/new-member",
    name: "NewMember",
    component: NewMember
  },
  {
    path: "/new-reward",
    name: "NewReward",
    component: NewReward
  },
  {
    path: "/new-discount",
    name: "NewDiscount",
    component: NewDiscount
  },
  {
    path: "/new-expense",
    name: "NewExpense",
    component: NewExpense
  },
  {
    path: "/edit-customer",
    name: "EditCustomer",
    component: EditCustomer
  },
  {
    path: "/edit-member",
    name: "EditMember",
    component: EditMember
  },
  {
    path: "/permissions/:id",
    name: "Permissions",
    component: Permissions
  },
  {
    path: "/expense-reason",
    name: "Expense",
    component: ExpenseReason
  },
  {
    path: "/expense-confirm-delete",
    name: "ExpenseDelete",
    component: ConfirmExpenseDelete
  },

  {
    path: "/confirm-expense",
    name: "ConfirmExpense",
    component: ConfirmExpense
  },
  {
    path: "/refund-reason",
    name: "RefundReason",
    component: RefundReason
  },

  {
    path: "/refund-complete",
    name: "RefundComplete",
    component: RefundComplete
  },
  {
    path: "/print-receipt",
    name: "Receipt",
    component: Receipt
  },
  {
    path: "/detailed-report",
    name: "DetailedReport",
    component: DetailedReport
  },
  {
    path: "/journey-details",
    name: "JourneyDetails",
    component: JourneyDetails
  },
  {
    path: "/all-customers/:id",
    name: "AllCustomers",
    component: AllCustomers,
    meta: { title: 'Report - All Customers' }
  },
  {
    path: "/new-customers/:id",
    name: "NewCustomers",
    component: NewCustomers,
    meta: { title: 'Report - New Customers' }
  },
  {
    path: "/customer-visits/:id",
    name: "CustomerVisits",
    component: CustomerVisits,
    meta: { title: 'Report - Customer Visits' }
  },
  {
    path: "/return-customers/:id",
    name: "ReturnCustomers",
    component: ReturnCustomers,
    meta: { title: 'Report - Returning Customers' }
  },

  {
    path: "/customers-at-risk/:id",
    name: "CustomersAtRisk",
    component: CustomersAtRisk,
    meta: { title: 'Report - Customers at Risk' }
  },
  {
    path: "/all-redeems/:id",
    name: "AllRedeems",
    component: AllRedeems,
    meta: { title: 'Report - All Redeems' }
  },

];

export default new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
