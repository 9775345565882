import axios from "axios";
import configs from "../../../app/configurations/configs";

const IP = configs.SERVER_IP;

const state = {
  industries:[],
  error:{}
};

const getters = {
  getIndustries: (state) => state.industries,
};

const actions = {
  async fetchIndustries({ commit }) {
    await axios
        .get(IP + "/industry_types")
        .then((response) => {
          commit("setIndustries", response.data);
        })
  },
};

const mutations = {
  setIndustries: (state, response) => {
    state.industries = response.data;
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
