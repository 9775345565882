import axios from "axios";
import configs from "../../../../../app/configurations/configs.json"

const IP = configs.SERVER_IP;

const state = {
    reports: {},
    sub_reports: null,
    loyalty_reports: {},
    response: {},
    error: {},
    sales_report_details: [],
    new_customers: {count: 0},
    lifetime_customers: {
        count: 0
    },
    meta: {},
    total_customers: {count: 0},
    customers_at_risk: {count: 0},
    customers_converted: {count: 0},
    customers_returned: {count: 0},
    customers_visits: {count: 0},
    lifetime_customers_visits: {count: 0},
    customers_repeat_rate: {rate: 0},
    customers_retention_rate: {rate: 0},
    customers_redemption_rate: {rate: 0},
    points_issued: {count: 0},
    points_redeemed: {data: 0},
    total_redeems: {count: 0},
    lifetime_points: {count: 0},
    total_cash: {amount: 0},
    lifetime_cash: {amount: 0},
    gender: {others_count: 0, female_count: 0, male_count: 0}

};

const getters = {
    getReports: (state) => state.reports,
    getReportDetailsMeta: (state) => state.meta,
    getLoyaltyReports: (state) => state.loyalty_reports,
    getSubscriptionReports: (state) => state.sub_reports,
    getCustomersAtRisk: (state) => state.customers_at_risk,
    getCustomersConverted: (state) => state.customers_converted,
    getCustomersReturned: (state) => state.customers_returned,
    getNewCustomers: (state) => state.new_customers,
    getTotalCustomers: (state) => state.total_customers,
    getLifetimeCustomers: (state) => state.lifetime_customers,
    getCustomersVisits: (state) => state.customers_visits,
    getLifetimeCustomersVisits: (state) => state.lifetime_customers_visits,
    getCustomersRepeatRate: (state) => state.customers_repeat_rate,
    getCustomersRetentionRate: (state) => state.customers_retention_rate,
    getCustomersRedemptionRate: (state) => state.customers_redemption_rate,
    getPointsIssued: (state) => state.points_issued,
    getPointsRedeemed: (state) => state.points_redeemed,
    getTotalPointsRedeemed: (state) => state.total_redeems,
    getLifetimePoints: (state) => state.lifetime_points,
    getTotalCash: (state) => state.total_cash,
    getLifetimeCash: (state) => state.lifetime_cash,
    getGenderDistribution: (state) => state.gender,
    getSalesReportDetails: (state) => state.sales_report_details,
};

const actions = {
    async fetchReports({commit}) {
        await axios
            .get(IP + "/reports/business/" + localStorage.getItem("business_id"))
            .then((response) => {
                commit("setReportsResponse", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },
    async fetchSalesReportDetails({commit}, data) {
        await axios
            .get(IP + "/reports/business/" + localStorage.getItem("business_id") + "/category-details?to="+data.to+"&from="+data.from+"&category_id=" + data.id)
            .then((response) => {
                commit("setSalesReportsResponse", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },
    async fetchReportsFilter({commit}, data) {
        await axios
            .get(IP + "/reports/business/" + localStorage.getItem("business_id") + "?location=" + data.location + "&to=" + data.date.to + "&from=" + data.date.from)
            .then((response) => {
                commit("setReportsResponse", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },

    async fetchSubscriptionReports({commit}, data) {
        await axios
            .get(IP + "/reports/business/" + localStorage.getItem("business_id") + "/subscriptions?location=" + data.location + "&to=" + data.date.to + "&from=" + data.date.from)
            .then((response) => {
                commit("setSubscriptionReportsResponse", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },

    async fetchNewCustomers({commit}, data) {
        await axios
            .get(IP + "/reports/new-customers?business_id=" + localStorage.getItem("business_id") + "&location=" + data.location + "&to=" + data.date.to + "&from=" + data.date.from)
            .then((response) => {
                commit("setNewCustomers", response.data);
                commit("setReportDetailsMetaResponse", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },
    async fetchNewCustomersPaged({commit}, data) {
        await axios
            .get(IP + "/reports/new-customers?business_id=" + localStorage.getItem("business_id") + "&location=" + data.location + "&to=" + data.date.to + "&from=" + data.date.from + "&page=" + data.page)
            .then((response) => {
                commit("setNewCustomers", response.data);
                commit("setReportDetailsMetaResponse", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },

    async fetchLifetimeCustomers({commit}, data) {
        await axios
            .get(IP + "/reports/life-time-customers?business_id=" + localStorage.getItem("business_id") + "&location=" + data.location + "&to=" + data.date.to + "&from=" + data.date.from)
            .then((response) => {
                commit("setLifeTimeCustomers", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },

    async fetchTotalCustomers({commit}, data) {
        await axios
            .get(IP + "/reports/total-customers?business_id=" + localStorage.getItem("business_id") + "&location=" + data.location + "&to=" + data.date.to + "&from=" + data.date.from)
            .then((response) => {
                commit("setTotalCustomers", response.data);
                commit("setReportDetailsMetaResponse", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },

    async fetchTotalCustomersPaged({commit}, data) {
        await axios
            .get(IP + "/reports/total-customers?business_id=" + localStorage.getItem("business_id") + "&location=" + data.location + "&to=" + data.date.to + "&from=" + data.date.from + "&page=" + data.page)
            .then((response) => {
                commit("setTotalCustomers", response.data);
                commit("setReportDetailsMetaResponse", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },

    async fetchCustomerRepeatPurchaseRate({commit}, data) {
        await axios
            .get(IP + "/reports/repeat-purchase-rate?business_id=" + localStorage.getItem("business_id") + "&location=" + data.location + "&to=" + data.date.to + "&from=" + data.date.from)
            .then((response) => {
                commit("setCustomersRepeatRate", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },

    async fetchCustomerRetentionRate({commit}, data) {
        await axios
            .get(IP + "/reports/customer-retention-rate?business_id=" + localStorage.getItem("business_id") + "&location=" + data.location + "&to=" + data.date.to + "&from=" + data.date.from)
            .then((response) => {
                commit("setCustomersRetentionRate", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },

    async fetchCustomerRedemptionRate({commit}, data) {
        await axios
            .get(IP + "/reports/redemption-rate?business_id=" + localStorage.getItem("business_id") + "&location=" + data.location + "&to=" + data.date.to + "&from=" + data.date.from)
            .then((response) => {
                commit("setCustomersRedemptionRate", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },

    async fetchCustomerVisits({commit}, data) {
        await axios
            .get(IP + "/reports/customer-visits?business_id=" + localStorage.getItem("business_id") + "&location=" + data.location + "&to=" + data.date.to + "&from=" + data.date.from)
            .then((response) => {
                commit("setCustomersVisits", response.data);
                commit("setReportDetailsMetaResponse", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },

    async fetchCustomerVisitsPaged({commit}, data) {
        await axios
            .get(IP + "/reports/customer-visits?business_id=" + localStorage.getItem("business_id") + "&location=" + data.location + "&to=" + data.date.to + "&from=" + data.date.from + "&page=" + data.page)
            .then((response) => {
                commit("setCustomersVisits", response.data);
                commit("setReportDetailsMetaResponse", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },

    async fetchCustomerLifetimeVisits({commit}, data) {
        await axios
            .get(IP + "/reports/life-time-customer-visits?business_id=" + localStorage.getItem("business_id") + "&location=" + data.location + "&to=" + data.date.to + "&from=" + data.date.from)
            .then((response) => {
                commit("setLifeTimeCustomersVisits", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },

    async fetchCustomerConverted({commit}, data) {
        await axios
            .get(IP + "/reports/converted-customers?business_id=" + localStorage.getItem("business_id") + "&location=" + data.location + "&to=" + data.date.to + "&from=" + data.date.from)
            .then((response) => {
                commit("setCustomersConverted", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },

    async fetchCustomersReturned({commit}, data) {
        await axios
            .get(IP + "/reports/return-customers?business_id=" + localStorage.getItem("business_id") + "&location=" + data.location + "&to=" + data.date.to + "&from=" + data.date.from)
            .then((response) => {
                commit("setCustomersReturned", response.data);
                commit("setReportDetailsMetaResponse", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },
    async fetchCustomersReturnedPaged({commit}, data) {
        await axios
            .get(IP + "/reports/return-customers?business_id=" + localStorage.getItem("business_id") + "&location=" + data.location + "&to=" + data.date.to + "&from=" + data.date.from + "&page=" + data.page)
            .then((response) => {
                commit("setCustomersReturned", response.data);
                commit("setReportDetailsMetaResponse", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },

    async fetchCustomerAtRisk({commit}, data) {
        await axios
            .get(IP + "/reports/customers-at-risk?business_id=" + localStorage.getItem("business_id") + "&location=" + data.location + "&to=" + data.date.to + "&from=" + data.date.from)
            .then((response) => {
                commit("setCustomersAtRisk", response.data);
                commit("setReportDetailsMetaResponse", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },

    async fetchCustomerAtRiskPaged({commit}, data) {
        await axios
            .get(IP + "/reports/customers-at-risk?business_id=" + localStorage.getItem("business_id") + "&location=" + data.location + "&to=" + data.date.to + "&from=" + data.date.from + "&page=" + data.page)
            .then((response) => {
                commit("setCustomersAtRisk", response.data);
                commit("setReportDetailsMetaResponse", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },

    async fetchPointsIssued({commit}, data) {
        await axios
            .get(IP + "/reports/points-issued?business_id=" + localStorage.getItem("business_id") + "&location=" + data.location + "&to=" + data.date.to + "&from=" + data.date.from)
            .then((response) => {
                commit("setPointsIssued", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },

    async fetchTotalPointsRedeemed({commit}, data) {
        await axios
            .get(IP + "/reports/total-redeems?business_id=" + localStorage.getItem("business_id") + "&location=" + data.location + "&to=" + data.date.to + "&from=" + data.date.from)
            .then((response) => {
                commit("setTotalPointsRedeemed", response.data);
                commit("setReportDetailsMetaResponse", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },

    async fetchTotalPointsRedeemedPaged({commit}, data) {
        await axios
            .get(IP + "/reports/total-redeems?business_id=" + localStorage.getItem("business_id") + "&location=" + data.location + "&to=" + data.date.to + "&from=" + data.date.from + "&page=" + data.page)
            .then((response) => {
                commit("setTotalPointsRedeemed", response.data);
                commit("setReportDetailsMetaResponse", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },

    async fetchPointsRedeemed({commit}, data) {
        await axios
            .get(IP + "/reports/points-redeemed?business_id=" + localStorage.getItem("business_id") + "&location=" + data.location + "&to=" + data.date.to + "&from=" + data.date.from)
            .then((response) => {
                console.log(JSON.stringify(response.data))
                commit("setPointsRedeemed", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },

    async fetchLifetimePoints({commit}, data) {
        await axios
            .get(IP + "/reports/lifetime-points?business_id=" + localStorage.getItem("business_id") + "&location=" + data.location + "&to=" + data.date.to + "&from=" + data.date.from)
            .then((response) => {
                commit("setLifetimePoints", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },

    async fetchTotalCash({commit}, data) {
        await axios
            .get(IP + "/reports/total-cash?business_id=" + localStorage.getItem("business_id") + "&location=" + data.location + "&to=" + data.date.to + "&from=" + data.date.from)
            .then((response) => {
                commit("setTotalCash", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },

    async fetchLifetimeCash({commit}, data) {
        await axios
            .get(IP + "/reports/lifetime-cash?business_id=" + localStorage.getItem("business_id") + "&location=" + data.location + "&to=" + data.date.to + "&from=" + data.date.from)
            .then((response) => {
                commit("setLifetimeCash", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },

    async fetchGenderDistribution({commit}, data) {
        await axios
            .get(IP + "/reports/gender-stats?business_id=" + localStorage.getItem("business_id") + "&location=" + data.location + "&to=" + data.date.to + "&from=" + data.date.from)
            .then((response) => {
                commit("setGenderStats", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },

    async fetchLoyaltyMonthlyReport({commit}) {
        await axios
            .get(IP + "/reports/business/" + localStorage.getItem("business_id") + "/monthly-loyalty")
            .then((response) => {
                console.log(JSON.stringify(response.data))
                commit("setLoyaltyReportsResponse", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },

    async filterLoyaltyMonthlyReport({commit}, data) {
        await axios
            .get(IP + "/reports/business/" + localStorage.getItem("business_id") + "/monthly-loyalty?location=" + data.location)
            .then((response) => {
                console.log(JSON.stringify(response.data))
                commit("setLoyaltyReportsResponse", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
    },
};

const mutations = {
    setReportsResponse: (state, response) => {
        state.reports = response.data;
    },
    setSalesReportsResponse: (state, response) => {
        state.sales_report_details = response.data;
    },
    setLoyaltyReportsResponse: (state, response) => {
        state.loyalty_reports = response.data;
    },
    setSubscriptionReportsResponse: (state, response) => {
        state.sub_reports = response.data;
    },
    setCustomersAtRisk: (state, response) => {
        state.customers_at_risk = response;
    },
    setCustomersConverted: (state, response) => {
        state.customers_converted = response.data;
    },
    setCustomersReturned: (state, response) => {
        state.customers_returned = response;
    },
    setNewCustomers: (state, response) => {
        state.new_customers = response;
    },
    setLifeTimeCustomers: (state, response) => {
        state.lifetime_customers = response.data;
    },
    setTotalCustomers: (state, response) => {
        state.total_customers = response;
    },
    setLifeTimeCustomersVisits: (state, response) => {
        state.lifetime_customers_visits = response.data;
    },
    setCustomersVisits: (state, response) => {
        state.customers_visits = response;
    },
    setCustomersRepeatRate: (state, response) => {
        state.customers_repeat_rate = response.data;
    },
    setCustomersRetentionRate: (state, response) => {
        state.customers_retention_rate = response.data;
    },
    setCustomersRedemptionRate: (state, response) => {
        state.customers_redemption_rate = response.data;
    },
    setPointsIssued: (state, response) => {
        state.points_issued = response;
    },
    setTotalPointsRedeemed: (state, response) => {
        state.total_redeems = response;
    },
    setPointsRedeemed: (state, response) => {
        state.points_redeemed = response;
    },
    setLifetimePoints: (state, response) => {
        state.lifetime_points = response.data;
    },
    setTotalCash: (state, response) => {
        state.total_cash = response.data;
    },
    setLifetimeCash: (state, response) => {
        state.lifetime_cash = response.data;
    },
    setGenderStats: (state, response) => {
        state.gender = response.data;
    },
    setReportDetailsMetaResponse: (state, response) => {
        state.meta = response.paginator;
    },
    setErrorResponse: (state, response) => {
        state.error = response.data;
    },

};

export default {
    state,
    getters,
    actions,
    mutations,
};
