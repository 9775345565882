<template>
  <div :class="[type == 'customer' ? 'menu' : type == 'team-member' ? 'menu' : 'menu2']">
    <b-row class="content d-flex justify-content-center align-items-center">
      <b-col class="details" v-if="type == 'transaction' && data != null" cols="11" md="11" lg="11">
       <TransactionDetails :data="data"/>
      </b-col>
      <b-col class="details" v-if="type == 'expense'" cols="11" md="11" lg="11">
        <ExpenseDetails :data="data"/>
      </b-col>
      <b-col class="customer-details" v-if="type == 'customer'" cols="12" md="12" lg="12">
        <CustomerDetails :data="data"/>
      </b-col>
      <b-col class="team-member" v-if="type == 'team-member'" cols="12" md="12" lg="12">
        <TeamMemberDetails :data="data"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import router from "@/app/router/router";
import TransactionDetails from "@/views/dashboard/components/menus/TransactionDetails";
import ExpenseDetails from "@/views/dashboard/components/menus/ExpenseDetails";
import CustomerDetails from "@/views/dashboard/components/menus/CustomerDetails";
import TeamMemberDetails from "@/views/dashboard/components/menus/TeamMemberDetails";

export default {
  name: "DetailsMenu",
  components: {TeamMemberDetails, CustomerDetails, ExpenseDetails, TransactionDetails},
  props:{data:Object, type:String},
  data(){
    return{
      months:["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    }
  },
  methods:{
    closeSideMenu(){
      this.$root.$emit('close-details')
    },
    cleanText(text){
      if(text.includes("_")){
        return this.capitalizeFirstLetter(text.replaceAll("_", " "))
      }
      return this.capitalizeFirstLetter(text);
    },
    refundAction(){
      let data = {
        id:this.data.id
      }
      localStorage.setItem("refund", JSON.stringify(data))
      router.push("/refund-reason")
    },
    openEditCustomer(){
      localStorage.setItem("customer", JSON.stringify(this.data))
      router.push("/edit-customer")
    },
    getDateString(date){
      var sections = date.split("-")
      var d = sections[2].split("T")
      var month_idx = parseInt(sections[1]) - 1
      return d[0]+" "+this.months[month_idx]+", "+sections[0];
    },
    getTime(date){
      var sections = date.split("T")
      var sections2 = sections[1].split(".")
      var sections3 = sections[1].split(":")
      var total = parseInt(sections2[0].replaceAll(":", ""))
      if(total > 120000){
        return sections3[0]+":"+sections3[1] + " pm"
      }else{
        return sections3[0]+":"+sections3[1] + " am"
      }
    },
    getShortDateString(date){
      var sections = date.split("-")
      var month_idx = parseInt(sections[1]) - 1

      return sections[2]+" "+this.months[month_idx]+", "+sections[0];
    },
    print(){
      localStorage.setItem("transaction", JSON.stringify(this.transaction))
      router.push("/print-receipt")
    },
    deleteAction(){
      router.push("/expense-reason")
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    calculateDiscount(product){
      var amount = product.discount.amount
      var type = product.discount.discount_type
      var price = parseInt(product.item_subtotal)/parseInt(product.item_quantity)

      if(type == "Amount"){
        return amount;
      }

      if(type == "Percentage"){
        return  parseInt(price) * amount/100
      }
    },
  },
  computed:{
    getData(){
      return this.data
    }
  },
  watch:{
    getData(data){
      console.log(JSON.stringify(data))
    }
  }
}
</script>

<style scoped>

@import "../../../commons/css/main.css";

.menu{
  width: 30vw;
  height: calc(100% - 90px);
  background: #f1f1f1;
  box-shadow: 1px 2px 3px 3px #e7e7e7;
  margin-top: 80px;
  overflow-y:scroll
}

.menu2{
  width: 30vw;
  height: 100%;
  background: #fff;
  box-shadow: 1px 2px 3px 3px #e7e7e7;
}

.content{
  margin: 0;
  padding: 0;
}

.details{
  margin: 100px 30px 30px;
}

.customer-details{
  background: #f1f1f1;
}

.team-member{
  background: #f1f1f1;
}

</style>