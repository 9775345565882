import { render, staticRenderFns } from "./CustomerVisits.vue?vue&type=template&id=228918b2&scoped=true&"
import script from "./CustomerVisits.vue?vue&type=script&lang=js&"
export * from "./CustomerVisits.vue?vue&type=script&lang=js&"
import style0 from "./CustomerVisits.vue?vue&type=style&index=0&id=228918b2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "228918b2",
  null
  
)

export default component.exports