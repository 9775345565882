<template>
    <div class="reports">
      <b-row>
        <b-col cols="2" class="menu">
          <b-row v-for="(item, idx) in menu" :key="idx" class="less">
            <b-col cols="11" :class="[($route.fullPath.includes(item.route))?'menu-item-active':'menu-item']" @click="select(item)">
              <span class="font-weight-bold">{{item.name}}</span>
            </b-col>
            <b-col cols="11" v-if="item.children != null && $route.fullPath.includes(item.route)">
              <b-row>
                <b-col class="menu-item-child-holder">
                  <b-row v-for="(child, cidx) in item.children" :key="cidx" @click="select(child)">
                    <b-col cols="12" :class="[($route.fullPath == child.route)?'menu-item-child-active':'menu-item-child']" @click="select(item)">
                      <span>{{child.name}}</span>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="9">
          <router-view></router-view>
        </b-col>
        <b-col cols="1"></b-col>
      </b-row>
    </div>
</template>

<script>
import router from "../../../../app/router/router"
export default {
  name: "Reports",
  data(){
    return{
      menu:[
        {
          name:"Summary Report",
          route:"/dashboard/reports/summary",
          children:null
        },
        {
          name:"Subscription Report",
          route:"/dashboard/reports/subscription",
          children:null
        },
        {
          name:"Loyalty Report",
          route:"/dashboard/reports/loyalty",
          children:null
        },
      ],
      selected:"",
    }
  },
  mounted() {

  },
  methods:{
    select(item){
      this.selected = item.route
      if(item.children != null){
        router.push(item.children[0].route)
      }else{
        router.push(this.selected)
      }
    }
  },
}
</script>

<style scoped>
@import "../../../../commons/css/main.css";

.reports{
  margin-top: 10px;
}

.menu{
  margin-top: 50px;
}

.menu-item{
  padding: 10px 7px;
  color: #1d2124;
  font-size: 14px;
}

.menu-item:hover{
  background: #f6f6f6;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.menu-item-active{
  padding: 10px 7px;
  background: #fce0e1;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  color: #bd2130;
  font-size: 14px;
}

.menu-item-child-holder{
  margin-left: 20px;
}

.menu-item-child{
  padding: 5px;
  color: #1d2124;
  font-size: 14px;
}

.menu-item-child:hover{
  pbackground: #f6f6f6;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.menu-item-child-active{
  padding: 5px;
  color: #bd2130;
  font-size: 14px;
}

.less{
  margin: 0;
  padding: 0;
}

</style>