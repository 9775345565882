import axios from "axios";
import configs from "../../../../../app/configurations/configs.json"

const IP = configs.SERVER_IP;

const state = {
  transactions:[],
  report:{},
  response:{},
  error:{}
};

const getters = {
    getLandingReport: (state) => state.report,
};

const actions = {
  async fetchLandingReports({ commit }, date) {
        await axios
            .get(IP + "/reports/landing/"+localStorage.getItem("business_id")+"?from="+date+"&to="+date)
            .then((response) => {
                console.log(JSON.stringify(response.data))
                commit("setLandingReportsResponse", response.data);
            })
            .catch((error) => {
                commit("setErrorResponse", error.response);
            });
  },
  async fetchLandingReportsFilter({ commit }, data) {
      await axios
          .get(IP + "/reports/landing/"+localStorage.getItem("business_id")+"?from="+data.date+"&to="+data.date+"&location="+data.location)
          .then((response) => {
              console.log(JSON.stringify(response.data))
              commit("setLandingReportsResponse", response.data);
          })
          .catch((error) => {
              commit("setErrorResponse", error.response);
          });
  },
  async fetchAppointments({ commit }) {
    await axios
      .get(IP + "/transactions/business/"+localStorage.getItem("business_id"))
      .then((response) => {
        //console.log(JSON.stringify(response.data))
        commit("setTransactionsResponse", response.data);
      })
      .catch((error) => {
        commit("setErrorResponse", error.response);
      });
  },
  async fetchTopExpenses({ commit }) {
    await axios
        .get(IP + "/transactions/business/"+localStorage.getItem("business_id"))
        .then((response) => {
          //console.log(JSON.stringify(response.data))
          commit("setTransactionsResponse", response.data);
        })
        .catch((error) => {
          commit("setErrorResponse", error.response);
        });
  },
  async fetchMonthSummary({ commit }) {
    await axios
        .get(IP + "/transactions/business/"+localStorage.getItem("business_id"))
        .then((response) => {
          //console.log(JSON.stringify(response.data))
          commit("setTransactionsResponse", response.data);
        })
        .catch((error) => {
          commit("setErrorResponse", error.response);
        });
  },
  async fetchSubscriptionAllocations({ commit }) {
    await axios
        .get(IP + "/transactions/business/"+localStorage.getItem("business_id"))
        .then((response) => {
          //console.log(JSON.stringify(response.data))
          commit("setTransactionsResponse", response.data);
        })
        .catch((error) => {
          commit("setErrorResponse", error.response);
        });
  },
};

const mutations = {
  setLandingReportsResponse: (state, response) => {
    state.report = response.data;
  },
  setRefundResponse: (state, response) => {
    state.response = response.data;
  },
  setErrorResponse: (state, response) => {
    state.error = response.data;
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
