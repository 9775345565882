<template>
    <div class="member">

      <b-row class="header">
        <b-col cols="1">
          <a class="navbar-brand ml-3">
            <img @click="back()" src="../../../../assets/ic_cancel.png" class="y-icon-header">
          </a>
        </b-col>

        <b-col cols="9" style="margin: auto;">
          <div class="flex-grow-1 d-md-flex align-items-center justify-content-center d-none">
            <h1 class="h5 text-center title">Edit Team Member</h1>
          </div>
        </b-col>

        <b-col cols="2" style="margin: auto;">
          <button @click="save()" class="button">Save</button>
        </b-col>
      </b-row>
        <section class="content h-100 w-100 d-flex justify-content-center align-items-center flex-column mb-5">
            <b-col cols="7" class="mx-auto">
                <div class="mt-5  d-none d-md-block">
                    <b-overlay :show="loading" rounded="sm">
                      <span class="font-weight-bold h5">Details</span>
                      <table class="table w-100 font-weight-bold table-bordered">
                            <tbody>
                              <tr>
                                  <th class="bg-light">First name</th>
                                  <td class="font-weight-normal">
                                      <b-form-input
                                              class="input"
                                              v-model="form.firstname"
                                              type="text"
                                              placeholder="Name"/>
                                  </td>
                              </tr>
                              <tr>
                                <th class="bg-light">Last name</th>
                                <td class="font-weight-normal">
                                  <b-form-input
                                      class="input"
                                      v-model="form.lastname"
                                      type="text"
                                      placeholder="Name"/>
                                </td>
                              </tr>
                            </tbody>
                      </table>

                      <b-row style="margin-top: 40px"></b-row>
                      <table class="table w-100 font-weight-bold table-bordered">
                        <tbody>
                          <tr>
                            <th class="bg-light">Primary Job Title</th>
                            <td class="font-weight-normal">
                              <b-form-input
                                  class="input"
                                  v-model="form.job_title"
                                  type="text"/>
                            </td>
                          </tr>
                          <tr>
                            <th class="bg-light">Phone Number</th>
                            <td class="font-weight-normal">
                              <b-form-input
                                  class="input"
                                  v-model="form.phone_number"
                                  type="text"/>
                            </td>
                          </tr>
                          <tr>
                            <th class="bg-light">Email Address</th>
                            <td class="font-weight-normal">
                              <b-form-input
                                  class="input"
                                  v-model="form.email"
                                  type="text"/>
                            </td>
                          </tr>
                          <tr>
                            <th class="bg-light">Team Member ID</th>
                            <td class="font-weight-normal">
                              <b-form-input
                                  class="input"
                                  v-model="form.team_id"
                                  type="text"/>
                            </td>
                          </tr>
                          <tr>
                            <th class="bg-light">Pay Type</th>
                            <td class="font-weight-normal">
                              <b-form-select
                                  class="input"
                                  v-model="form.pay_type">
                                <b-select-option value="null">Payment Type</b-select-option>
                                <b-select-option value="Percentage">Percentage</b-select-option>
                                <b-select-option value="Salary">Salary</b-select-option>
                              </b-form-select>
                            </td>
                          </tr>
                          <tr>
                            <th class="bg-light">Amount</th>
                            <td class="font-weight-normal">
                              <b-form-input
                                  class="input"
                                  v-model="form.amount"
                                  type="text"/>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                    </b-overlay>
                </div>
            </b-col>
        </section>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "EditMember",
        data(){
          return{
            form:{
              id:null,
              firstname:null,
              lastname:null,
              name:null,
              business_id: localStorage.getItem("business_id"),
              email:null,
              phone_number: null,
              job_title:null,
              team_id:null,
              pay_type: null,
              amount:null,
            },
            loading:false,
          }
        },
        mounted() {
          this.fetchCategories()
          this.fetchLocations()
          var member = JSON.parse(localStorage.getItem("member"))
          this.form.id = member.id
          this.form.firstname = member.name.split(" ")[0]
          this.form.lastname = member.name.split(" ")[1]
          this.form.job_title = member.job_title
          this.form.payment_type = member.pay_type
          this.form.phone_number = member.phone_number
          this.form.email = member.email
          this.form.member_id = member.team_id
          this.form.amount = member.amount

        },
        methods:{
            ...mapActions(["fetchCategories", "editTeamMember", "fetchLocations"]),
          onSearch(){

          },
          back(){
             localStorage.removeItem("member")
             window.history.back();
          },
          save(){
            this.loading = true
            this.form.name = this.form.firstname +" "+this.form.lastname
            this.editTeamMember(this.form)
          },
        },
        computed:{
            ...mapGetters([
              "getEditMemberResponse", "getEditMemberErrorResponse",
            ]),
        },
        watch:{
          getEditMemberResponse(){
            this.loading = false
            localStorage.removeItem("member")
            window.history.back()
          },
          getEditMemberErrorResponse(data){
            this.loading = false
            alert(JSON.stringify(data))
          }
        }
    }
</script>

<style scoped>
    @import "../../../../commons/css/main.css";

    .member{

    }

    .header{
        /*margin: 0;*/
        /*width: 100%;*/
        /*box-shadow: 0px 5px 3px #dedede;*/
        padding: 0;
        margin: 0;
        width: 100%;
        height: 85px;
        box-shadow: 0px 5px 3px #dedede;
    }

    .content{
        margin-top: 20px;
    }

    .y-icon-header{
        padding: 5px;
        width: 38px;
        height: 38px;
        margin: 16px 10px 10px 10px
    }

    .y-icon-header:hover{
        padding: 6px;
        background: #f3f3f3;
        border-radius: 6px;
    }

    .title{
        font-size: 22px;
        margin-top: 15px;
        font-weight: bold;
        margin-left: 10px;
    }

    .input{
        width: 100%;
        font-size: 16px;
        justify-content: left;
        text-align: left;
    }

    .active{
        background: #db0000;
        color: white;
        padding: 5px;
        border: 0;
        border-radius: 3px;
    }

    .button{
      width: 60%;
      background: #c82333;
      font-size: 14px;
      padding: 10px;
      color: white;
      border: solid 1px #c82333;
      border-radius: 3px;
    }

    .button:hover{
      background: #721c24;
    }

    .toggle-btn{
      font-size: 11px;
    }

    .toggle-piece{
      padding-top: 5px;
      padding-bottom: 5px;
      width: 100px;
      border: solid 1px #dedede;
    }

    .toggle-piece-active{
      color: #fff;
      padding-top: 5px;
      padding-bottom: 5px;
      width: 100px;
      background: #c82333;
      border: solid 1px #c82333;
    }

    .separate{
      margin-top: 40px;
    }

</style>