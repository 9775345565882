<template>
    <div class="categories">

      <b-row>
        <b-modal id="disable" centered title="Disable Category" hide-footer>
          <b-container fluid>
            <b-row class="line2">
              <b-col cols="12" class="text-center justify-content-center align-content-center">
                <div class="text">
                  Are you sure you would like to disabled this category ?
                </div>
              </b-col>
            </b-row>
            <b-row class="line">
              <b-col cols="8"></b-col>
              <b-col cols="4">
                <button class="button" @click="disableCategory">Disable</button>
              </b-col>
            </b-row>
          </b-container>
        </b-modal>
        <b-modal id="rename" centered title="Rename Category" hide-footer>
          <b-container fluid>
            <b-row class="line2">
              <b-col>
                <table class="table font-weight-bold table-bordered">
                  <tbody>
                  <tr>
                    <th class="bg-light">Name</th>
                    <td class="font-weight-normal">
                      <b-form-input
                          class="m-input"
                          v-model="form.name"
                          type="text"
                          placeholder="Number"/>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
            <b-row class="line">
              <b-col cols="8"></b-col>
              <b-col cols="4">
                <button class="button" @click.prevent="renameCategory">Save Changes</button>
              </b-col>
            </b-row>
          </b-container>
        </b-modal>
      </b-row>

      <b-row>
        <b-col cols="2" sm="4" md="4" lg="6" xl="6" class="less">
          <span class="font-weight-bold h3">Categories</span>
        </b-col>
        <b-col cols="10" sm="8" md="8" lg="6" xl="6"></b-col>
      </b-row>

      <b-row class="line">
        <b-col cols="7" class="mx-auto">
          <span class="title">Categories help you arrange and organize your items, report on item sales and route items to specific printers</span>
        </b-col>
        <b-col cols="3"></b-col>
        <b-col cols="2">
          <button @click="create" class="button">Create a Category</button>
        </b-col>
      </b-row>

      <b-row class="line">
        <b-col cols="12">
          <b-col class="cats-header">
            <div class="header-label">Item</div>
          </b-col>
        </b-col>
        <b-col cols="12">
          <b-row v-for="(category, idx) in getCategories" :key="idx" class="category less">
            <b-col cols="11">
              <span class="text">{{category.name}}</span>
            </b-col>
            <b-col cols="1">
              <img src="../../../../assets/ic_more.svg" style="float: right;"
                   class="y-icon"
                   :id="category.id"/>
              <b-popover :target="category.id" triggers="hover" placement="bottom">
                <b-row>
                  <b-col cols="12" class="popover-listing text-center align-content-center" @click="disablePrompt(category)">
                    <div class="popover-item">Disable</div>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="12" class="popover-listing text-center align-content-center" @click="renamePrompt(category)">
                    <div class="popover-item">Rename</div>
                  </b-col>
                </b-row>
              </b-popover>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import router from "@/app/router/router";
export default {
  name: "Categories",
  data(){
    return{
      loading:false,
      selected:{},
      form:{
        name:null
      }
    }
  },
  mounted() {
    this.loading = true
    this.fetchCategories()
  },
  methods:{
    ...mapActions(["fetchCategories", "editCategories", "disableCategories"]),
    create(){
      router.push("/new-category")
    },
    disablePrompt(data){
      this.selected = data
      this.form.id = data.id
      this.$bvModal.show("disable")
    },
    disableCategory(){
      //this.loading = true
      this.$bvModal.hide("disable")
      //this.disableCategories(this.form.id)

    },
    renamePrompt(data){
      this.selected = data
      this.form.name = data.name
      this.form.id = data.id
      this.$bvModal.show("rename")
    },
    renameCategory(){
      this.loading = true
      this.editCategories(this.form)
      this.$bvModal.hide("rename")

    },
  },
  computed:{
    ...mapGetters([
      "getCategories", "getActionResponse"
    ]),
  },
  watch:{
    getCategories(){
      this.loading = false
    },
    getActionResponse(){
      this.loading = false
      this.fetchCategories()
    }
  }
}
</script>

<style scoped>
@import "../../../../commons/css/main.css";

.categories{
  margin-top: 10px;
}
.button{
  width: 100%;
  background: #c82333;
  font-size: 12px;
  padding: 8px;
  color: white;
  border: solid 1px #c82333;
  border-radius: 3px;
}

.line{
  margin-top: 20px;
  margin-bottom: 20px;
}

.line2{
  margin-top: 20px;
  margin-bottom: 60px;
}

.title{
  font-size: 14px;
}
.category{
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: solid 1px #b4b4b4;
}

.less{
  margin:0;
}

.cats-header{
  background: #eaeaea;
  border-radius: 6px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.header-label{
  color: #1d2124;
  font-weight: bold;
  font-size: 16px;
}

.y-icon{
  width: 22px;
  height: 22px;
  margin-right: 5px;
}

.y-icon:hover{
  background: #f1f1f1;
  border-radius: 6px;
  padding: 2px;
}

.text{
  font-size: 14px;
}

.popover-item{
  font-size: 16px;
}
.popover-listing{

}
.popover-listing:hover{
  color: #bd2130;
}

th{
  font-size: 14px;
}

.m-input{
  font-size: 14px;
}
</style>