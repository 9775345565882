<template>
    <div class="items">

      <b-row>
        <b-col cols="2" sm="4" md="4" lg="6" xl="6" class="less">
          <span class="font-weight-bold h3">View Rewards</span>
        </b-col>
        <b-col cols="10" sm="8" md="8" lg="6" xl="6"></b-col>
      </b-row>
      <b-row class="line">
        <b-col cols="3">
          <b-form>
            <b-form-select v-model="filter_location" class="select">
              <b-select-option value="null">All Locations</b-select-option>
              <b-select-option v-for="(location, idx) in getLocations" :key="idx" :value="location.id">
                {{location.location_name}} ({{location.address}})
              </b-select-option>
            </b-form-select>
          </b-form>
        </b-col>
        <b-col cols="3">
          <div class="input-group input">
            <b-form-input type="text" class="form-control border-0" placeholder="Search here..."
                          aria-label="Recipient's username" v-model="search" aria-describedby="basic-addon2"/>
          </div>
        </b-col>
        <b-col cols="4">
        </b-col>
        <b-col cols="2">
          <button @click="create" class="button">Create Reward</button>
        </b-col>
      </b-row>
      <b-row class="list-header">
        <b-col cols="6">
          <span class="font-weight-bold">Item</span>
        </b-col>
        <b-col cols="4">
          <span class="font-weight-bold">Location</span>
        </b-col>
        <b-col cols="1">
          <span class="font-weight-bold">Points</span>
        </b-col>
        <b-col cols="1">
          <span class="font-weight-bold floater">Status</span>
        </b-col>
      </b-row>
      <b-overlay :show ="loading" rounded="sm">
        <b-row v-for="(item, idx) in getRewards" :key="idx" class="list-item">
          <b-col cols="6">
            <span class="text name">{{item.name}}</span>
          </b-col>
          <b-col cols="4">
            <span class="text"></span>
          </b-col>
          <b-col cols="1">
            <span class="text">{{item.points}}</span>
          </b-col>
          <b-col cols="1">
            <span class="text floater" >
              <div class="custom-control custom-switch" v-on:change="deactivate(item.id)">
                <input type="checkbox" class="custom-control-input" :id="idx" :checked="item.status">
                <label class="custom-control-label" :for="idx"></label>
              </div>
            </span>
          </b-col>
        </b-row>
      </b-overlay>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import router from "../../../../app/router/router"
export default {
  name: "Rewards",
  data(){
    return{
      search:null,
      loading:true,
      filter_location:null,
      form:{},
      date_range:{
        startDate:null,
        endDate:null
      }
    }
  },
  mounted() {
    this.loading = true
    this.fetchRewards()
    this.fetchLocations()
  },
  methods:{
    ...mapActions(["fetchRewards", "fetchLocations", "deactivateReward"]),
    create(){
      router.push("/new-reward")
    },
    deactivate(id){
      let data = {
        id: id,
        status: 0
      }
      this.deactivateReward(data)
    }
  },
  computed:{
    ...mapGetters([
      "getRewards", "getLocations", "getRewardDeactivatedResponse"
    ]),
  },
  watch:{
    getRewards(){
      this.loading = false
    },
    getRewardDeactivatedResponse(){
      this.loading = true
      this.fetchRewards()
    }
  }
}
</script>

<style scoped>
@import "../../../../commons/css/main.css";

.items{
  margin-top: 10px;
}

.button{
  width: 100%;
  background: #c82333;
  font-size: 12px;
  padding: 10px;
  color: white;
  border: solid 1px #c82333;
  border-radius: 3px;
}

.line{
  margin-top: 20px;
  margin-bottom: 20px;
}

.select{
  width: 100%;
}

.list-header{
  width: 100%;
  background: #eaeaea;
  padding-bottom: 10px;
  padding-top: 10px;
  border-radius: 6px;
  margin: 0;
}

.list-header span{
  font-weight: bold;
}

.list-item{
  width: 100%;
  background: white;
  padding-bottom: 15px;
  padding-top: 15px;
  margin: 0;
  border-bottom: solid 1px #979696;
}

.list-item:hover{
  background: #dddddd;
}

.text{
  font-size: 14px;
}

.floater{
  float: right;
}

.name{
  color: #bd2130;
}

.input{
  border: solid 2px #707070;
  border-radius: 6px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #c82333;
  background-color: #c82333;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(255, 48, 3, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ff88a3;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #ffb4c8;
  border-color: #ff90a6;
}
</style>