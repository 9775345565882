<template>
    <div class="expense">

      <b-row class="header">
        <b-col cols="1">
          <a class="navbar-brand ml-3">
            <img @click="back()" src="../../../../../assets/ic_cancel.png" class="y-icon-header">
          </a>
        </b-col>

        <b-col cols="9" style="margin: auto;">
          <div class="flex-grow-1 d-md-flex align-items-center justify-content-center d-none">
            <h1 class="h5 text-center title">Create Item</h1>
          </div>
        </b-col>

        <b-col cols="2" style="margin: auto;">
          <button @click="save()" class="button">Save</button>
        </b-col>
      </b-row>
        <section class="content h-100 w-100 d-flex justify-content-center align-items-center flex-column mb-5">
            <b-col cols="7" class="mx-auto">
                <div class="mt-5  d-none d-md-block">
                    <b-overlay :show="loading" rounded="sm">
                      <span class="font-weight-bold h5">Details</span>
                      <table class="table w-100 font-weight-bold table-bordered">
                            <tbody>
                            <tr>
                                <th class="bg-light">Item name</th>
                                <td class="font-weight-normal">
                                    <b-form-input
                                            class="input"
                                            v-model="form.name"
                                            type="text"
                                            placeholder="Name"/>
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light">Category</th>
                                <td class="font-weight-normal">
                                  <b-form-select v-model="form.category_id" class="select">
                                    <b-select-option value="null">All Categories</b-select-option>
                                    <b-select-option v-for="(category, idx) in getCategories" :key="idx" :value="category.id">
                                      {{category.name}}
                                    </b-select-option>
                                  </b-form-select>
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light">Description</th>
                                <td class="font-weight-normal">
                                <textarea v-model="form.description"
                                          class="border-0 form-control"
                                          placeholder="Description"
                                          cols="30" rows="5"></textarea>
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light">Location</th>
                                <td class="font-weight-normal">
                                  <b-form-input
                                      class="input"
                                      disabled
                                      type="text"
                                      :value="getLocations.length+' Locations'"/>
                                </td>
                            </tr>
                            </tbody>
                      </table>

                      <b-row style="margin-top: 40px"></b-row>
                      <span class="font-weight-bold h5">Price of the Item</span>
                      <table class="table w-100 font-weight-bold table-bordered">
                        <tbody>
                        <tr v-for="(location, idx) in getLocations" :key="idx">
                          <th class="bg-light">{{location.location_name}} ({{location.address}})</th>
                          <td class="font-weight-normal">
                            <b-form-input
                                class="input"
                                type="text"
                                v-model="form.prices[idx].amount"
                                placeholder="0"/>
                          </td>
                        </tr>
                        </tbody>
                      </table>

                      <b-row style="margin-top: 40px"></b-row>
                      <span class="font-weight-bold h5">Commissions</span>
                      <table class="table w-100 font-weight-bold table-bordered">
                        <tbody>
                        <tr v-for="(location, idx) in getLocations" :key="idx">
                          <th class="bg-light" style="margin-top: auto; margin-bottom: auto;">
                            <span>{{location.location_name}} ({{location.address}})</span>
                          </th>
                          <td class="font-weight-normal" style="border: 0">
                            <table style="width: 100%; border: 0; margin: 0; padding: 0;">
                              <tbody>
                              <tr>
                                <td style="border: 0; margin: 0; padding: 0;">
                                  <b-form-input
                                      class="input"
                                      v-model="form.commissions[idx].amount"
                                      type="text"
                                      placeholder="0"/>
                                </td>
                                <td style="border: 0; margin: 0; padding: 0;">
                                  <div class="toggle-btn text-center">
                                    <span :class="(locationList[idx].showP) ? 'toggle-piece-active' : 'toggle-piece'" @click="percentageType(idx)">%</span>
                                    <span :class="(locationList[idx].showA) ? 'toggle-piece-active' : 'toggle-piece'" @click="amountType(idx)">UGX</span>
                                  </div>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </b-overlay>
                </div>
            </b-col>
        </section>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "NewItem",
        data(){
          return{
            form:{
              name:null,
              description:null,
              category_id:null,
              prices:[],
              commissions:[],
            },
            prices:[],
            commissions:[],
            locationList:[],
            id:null,
            loading:false,
            raw_date:null,
            raw_amount:null,
            refresh: false
          }
        },
        mounted() {
          this.fetchCategories()
          this.fetchLocations()
        },
        methods:{
            ...mapActions(["fetchCategories", "addItem", "fetchLocations"]),
          back(){
             window.history.back();
          },
          save(){
            this.loading = true
            for(let i=0; i<this.form.prices.length; i++){
              if(parseInt(this.form.prices[i].amount) > 0){
                let data = {
                  name:this.form.name,
                  description:this.form.description,
                  category_id:this.form.category_id,
                  price: this.form.prices[i].amount,
                  commission_value:this.form.commissions[i].amount,
                  commission_type:this.form.commissions[i].type,
                  location_id: this.form.prices[i].location,
                  business_id: localStorage.getItem("business_id")
                }
                this.loading = true
                this.addItem(data)
              }
            }
          },
          percentageType(idx){
            this.form.commissions[idx].type = "percentage"
            this.locationList[idx].showP = true
            this.locationList[idx].showA = false
          },
          amountType(idx){
            this.form.commissions[idx].type = "amount"
            this.locationList[idx].showA = true
            this.locationList[idx].showP = false
          },
        },
        computed:{
            ...mapGetters([
              "getCategories", "getActionResponse", "getLocations"
            ]),
        },
        watch:{
          getActionResponse(){
            this.loading = false
            window.history.back()
          },
          getLocations(data) {
            for(let  i = 0; i<data.length; i++){
              let data1 = {
                location: data[i].id,
                amount: null,
                type: "amount"
              }
              this.form.commissions[i] = data1

              let data2 = {
                location: data[i].id,
                amount: null,
              }

              this.form.prices[i] = data2

              let data3 = {
                location: data[i].id,
                showP:false,
                showA:true
              }
              this.locationList.push(data3)
            }
          },
        }
    }
</script>

<style scoped>
    @import "../../../../../commons/css/main.css";

    .expense{

    }

    .header{
        /*margin: 0;*/
        /*width: 100%;*/
        /*box-shadow: 0px 5px 3px #dedede;*/
        padding: 0;
        margin: 0;
        width: 100%;
        height: 85px;
        box-shadow: 0px 5px 3px #dedede;
    }

    .content{
        margin-top: 20px;
    }

    .y-icon-header{
        padding: 5px;
        width: 38px;
        height: 38px;
        margin: 16px 10px 10px 10px
    }

    .y-icon-header:hover{
        padding: 6px;
        background: #f3f3f3;
        border-radius: 6px;
    }

    .title{
        font-size: 22px;
        margin-top: 15px;
        font-weight: bold;
        margin-left: 10px;
    }

    .input{
        width: 100%;
        font-size: 16px;
        justify-content: left;
        text-align: left;
    }

    .active{
        background: #db0000;
        color: white;
        padding: 5px;
        border: 0;
        border-radius: 3px;
    }

    .button{
      width: 60%;
      background: #c82333;
      font-size: 14px;
      padding: 10px;
      color: white;
      border: solid 1px #c82333;
      border-radius: 3px;
    }

    .button:hover{
      background: #721c24;
    }

    .toggle-btn{
      font-size: 11px;
    }

    .toggle-piece{
      padding-top: 5px;
      padding-bottom: 5px;
      width: 100px;
      border: solid 1px #dedede;
    }

    .toggle-piece-active{
      color: #fff;
      padding-top: 5px;
      padding-bottom: 5px;
      width: 100px;
      background: #c82333;
      border: solid 1px #c82333;
    }

    .separate{
      margin-top: 40px;
    }

</style>