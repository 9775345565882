<template>
    <div class="settings">
      <b-row>
        <b-col cols="2" sm="4" md="4" lg="6" xl="6" class="less">
          <span class="font-weight-bold h3">Subscription</span>
        </b-col>
        <b-col cols="10" sm="8" md="8" lg="6" xl="6"></b-col>
      </b-row>
      <b-row class="line">
        <b-col cols="10">
          <p class="text">
            You are billed monthly for all active paid services (excluding free trials). Additional charges will be made per usage. You may also be charged sales tax if applicable.
          </p>
        </b-col>
      </b-row>

      <table class="table w-100 font-weight-bold table-bordered">
        <tbody>
        <tr v-for="(location, idx) in getLocations" :key="idx">
          <th class="bg-light">
            Point of Sale
          </th>
          <td class="font-weight-normal">
            {{location.location_name}} ({{location.address}})
          </td>
          <td class="font-weight-normal">
            <button class="button">Renew</button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import router from "../../../../../app/router/router"
export default {
  name: "BusinessLocationSettings",
  data(){
    return{
      search:null,
      loading:true,
      filter_location:null,
      date_range:{
        startDate:null,
        endDate:null
      }
    }
  },
  mounted() {
    this.fetchRewards()
    this.fetchLocations()
  },
  methods:{
    ...mapActions(["fetchRewards", "fetchLocations"]),
    create(){
      router.push("/new-reward")
    },
  },
  computed:{
    ...mapGetters([
      "getRewards", "getLocations"
    ]),
  },
  watch:{
    getRewards(){
      this.loading = false
    },
  }
}
</script>

<style scoped>
@import "../../../../../commons/css/main.css";

.settings{
  margin-top: 10px;
}

.button{
  width: 100%;
  background: #fff;
  font-size: 14px;
  padding: 10px;
  color: #c82333;
  border: 0;
}

.line{
  margin-top: 20px;
  margin-bottom: 20px;
}

.list-header span{
  font-weight: bold;
}

.text{
  font-size: 14px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #c82333;
  background-color: #c82333;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(255, 48, 3, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ff88a3;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #ffb4c8;
  border-color: #ff90a6;
}
</style>