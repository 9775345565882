<template>
    <div class="items">
      <b-row>
        <b-col cols="2" sm="4" md="4" lg="6" xl="6" class="less">
          <span class="font-weight-bold h3">Team Members</span>
        </b-col>
        <b-col cols="10" sm="8" md="8" lg="6" xl="6"></b-col>
      </b-row>
      <b-row class="line">
        <b-col cols="7">
        </b-col>
        <b-col cols="3">
          <div class="input-group input">
            <b-form-input type="text" class="form-control border-0" placeholder="Search here..."
                          aria-label="Recipient's username" v-model="search" aria-describedby="basic-addon2"/>
          </div>
        </b-col>
        <b-col cols="2">
          <button @click="create" class="button">Create a Member</button>
        </b-col>
      </b-row>
      <b-row class="list-header">
        <b-col cols="6">
          <span class="font-weight-bold">Name</span>
        </b-col>
        <b-col cols="3">
          <span class="font-weight-bold">Job Title</span>
        </b-col>
        <b-col cols="3">
          <span class="font-weight-bold floater">Status</span>
        </b-col>
      </b-row>
      <b-overlay :show ="loading" rounded="sm">
        <b-row v-for="(item, idx) in getTeams"
               :key="idx"
               @click="show(item)"
               class="list-item">
          <b-col cols="6">
            <span class="text name">{{item.name}}</span>
          </b-col>
          <b-col cols="3">
            <span class="text">{{item.job_title}}</span>
          </b-col>
          <b-col cols="3">
            <span v-if="item.status" class="text floater" >Active</span>
            <span v-else class="text floater" >In-Active</span>
          </b-col>
        </b-row>
      </b-overlay>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import router from "@/app/router/router";
export default {
  name: "Members",
  data(){
    return{
      search:null,
      loading:true
    }
  },
  mounted() {
    this.fetchTeams()
  },
  methods:{
    ...mapActions(["fetchTeams"]),
    create(){
      router.push("/new-member")
    },
    show(data){
      this.$root.$emit('open-details', data, 'team-member')
    }
  },
  computed:{
    ...mapGetters([
      "getTeams"
    ]),
  },
  watch:{
    getTeams(){
      this.loading = false
    },
  }
}
</script>

<style scoped>
@import "../../../../commons/css/main.css";

.items{
  margin-top: 10px;
}

.button{
  width: 100%;
  background: #c82333;
  font-size: 12px;
  padding: 10px;
  color: white;
  border: solid 1px #c82333;
  border-radius: 3px;
}

.line{
  margin-top: 20px;
  margin-bottom: 20px;
}

.select{
  width: 100%;
}

.list-header{
  width: 100%;
  background: #eaeaea;
  padding-bottom: 10px;
  padding-top: 10px;
  border-radius: 6px;
  margin: 0;
}

.list-header span{
  font-weight: bold;
}

.list-item{
  width: 100%;
  background: white;
  padding-bottom: 15px;
  padding-top: 15px;
  margin: 0;
  border-bottom: solid 1px #979696;
}

.list-item:hover{
  background: #dddddd;
}

.text{
  font-size: 14px;
}

.floater{
  float: right;
}

.input{
  border: solid 2px #707070;
  border-radius: 6px;
}
</style>