<template>
  <div class="dashboard">
    <DashboardHeader/>
    <b-row class="less">
      <b-col cols="8"></b-col>
      <b-col cols="4">
        <b-row>
          <DetailsMenu
              class="right-menu"
              v-if="showDetails"
              :data="right_data"
              :type="right_type"/>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="content">
      <b-col :cols="width_left" class="left-menu">
        <b-row v-if="showMenu" >
          <DashboardMenu/>
        </b-row>
      </b-col>
      <b-col :cols="width_right">
        <router-view></router-view>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DashboardHeader from "@/views/dashboard/components/DashboardHeader";
import DashboardMenu from "@/views/dashboard/components/DashboardMenu";
import DetailsMenu from "@/views/dashboard/components/DetailsMenu";
export default {
  name: "Dashboard",
  components: {DetailsMenu, DashboardMenu, DashboardHeader},
  data(){
    return{
      showMenu:false,
      showDetails:false,
      width_left:"0",
      width_right:"12",
      right_data:null,
      right_type:null
    }
  },
  mounted() {
    this.$root.$on("open-menu", this.openMenu)
    this.$root.$on("open-details", this.openDetails)
    this.$root.$on("close-details", this.closeDetails)
  },
  methods:{
    openMenu(){
      this.showMenu = !this.showMenu
      if(this.showMenu){
        this.width_left = "2"
        this.width_right = "10"
      }else{
        this.width_left = "0"
        this.width_right = "12"
      }
    },
    openDetails(data, type){
      this.closeDetails()
      this.right_data = data
      this.right_type = type
      this.showDetails = true
    },
    closeDetails(){
      this.showDetails = false
    }
  },
};
</script>

<style scoped>

@import "../../commons/css/main.css";

.dashboard{
  width: 100%;
  background: white;
}

.content{
  min-height: 100vh;
  padding-top: 82px;
  background: white;
}

.left-menu{
  margin-right: 0;
  padding-right: 0;
}

.right-menu{
  position: fixed;
  z-index: 20;
  right: 0;
}

.less{
  margin: 0;
  padding: 0;
}

</style>

